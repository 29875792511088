import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Button, Divider, FlatList, Heading, HStack, Pressable, Stack } from 'native-base';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { PastOrderCard } from './PastOrderCard';

import { Order } from '@/api/types/accountServices';
import { useIsMobile } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import { MAX_PAST_ORDERS } from '@/utils';
import { addQueryParams } from '@/utils/updateQueryParams';

interface PastOrdersListProps {
  pastOrders: Order[];
}

export const PastOrdersList = ({ pastOrders }: PastOrdersListProps) => {
  const ordersCount = pastOrders.length;
  const sectionRef: React.RefObject<HTMLDivElement> = useRef(null);
  const anchorName = 'PastOrders';
  const isMobile = useIsMobile();
  const navigation = useNavigation<NativeStackNavigationProp<ProtectedStackParamList>>();
  const [storageChecked, setStorageChecked] = useState(false);

  // Scroll into view if the URL anchor is PastOrders
  useFocusEffect(
    useCallback(() => {
      const params = new URL(window.location.href);
      const anchor = params.searchParams.get('anchor');
      if (anchor && anchor === anchorName && sectionRef.current) {
        const headerIsMultiLine = anchor.split(' ').length > 1;
        const mobileScrollMargin = headerIsMultiLine ? '180px' : '150px';
        sectionRef.current.style.scrollMargin = isMobile ? mobileScrollMargin : '0';

        sectionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, [storageChecked])
  );

  useLayoutEffect(() => {
    const requestedUrl = localStorage.getItem('requestedUrl');
    const currentUrl = new URL(window.location.href);
    if (requestedUrl) {
      const url = new URL(requestedUrl);
      if (
        !currentUrl.searchParams?.has('anchor') &&
        url.searchParams.get('anchor') === anchorName
      ) {
        navigation.setParams({ anchor: anchorName });
      }
      localStorage.removeItem('requestedUrl');
    }
    setStorageChecked(true);
  }, []);

  const handlePress = (headerRef: React.RefObject<HTMLDivElement>, title: string) => {
    if (headerRef?.current) {
      addQueryParams('anchor', title);
    }
  };

  const [visibleOrdersCount, setVisibleOrdersCount] = useState(MAX_PAST_ORDERS);

  const showViewMoreCta = useMemo(
    () => visibleOrdersCount < ordersCount,
    [ordersCount, visibleOrdersCount]
  );

  const pastOrdersToDisplay = useMemo(
    () => pastOrders.slice(0, visibleOrdersCount),
    [pastOrders, visibleOrdersCount]
  );

  if (pastOrders.length === 0) {
    return null;
  }

  return (
    <Stack
      ref={sectionRef}
      justifyContent="center"
      alignItems="start"
      w="100%"
      space={{ base: '16px', lg: '24px' }}
    >
      <HStack w="100%" alignItems="center" space={4}>
        <Divider flex={1} />
        <Pressable
          onPress={() => handlePress(sectionRef as React.RefObject<HTMLDivElement>, anchorName)}
        >
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            Past Orders
          </Heading>
        </Pressable>
        <Divider flex={1} />
      </HStack>
      <FlatList
        bg="sntGrey.athens"
        p={4}
        data={pastOrdersToDisplay}
        width="100%"
        keyExtractor={(item: Order) => item.id}
        renderItem={({ item, index }) => (
          <PastOrderCard
            order={item}
            mb={showViewMoreCta || index < pastOrdersToDisplay.length - 1 ? { base: 4, lg: 6 } : 0}
          />
        )}
        ListFooterComponent={
          showViewMoreCta ? (
            <Button
              alignSelf="center"
              variant="hugSecondary"
              borderWidth={{ base: '2px', lg: '3px' }}
              py={{ base: '14px', lg: 4 }}
              px={4}
              w="100%"
              h="fit-content"
              maxW={{ base: '240px', lg: '340px' }}
              onPress={() => setVisibleOrdersCount((prev) => prev + MAX_PAST_ORDERS)}
            >
              VIEW MORE
            </Button>
          ) : null
        }
      />
    </Stack>
  );
};
