import { Button, HStack, Stack, Text, VStack } from 'native-base';
import { useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { FormSubmitButton } from '../../../FormSubmitButton';
import { useAnswerSurveyAndCancelPlan } from '../hooks';
import { StopPlanSuccessModal } from './StopPlanSuccessModal';

import { DiscountExclusiveType, useCreateExclusiveDiscount } from '@/api';
import { ResponsiveCarousel, ToastType, displayToast } from '@/components/Elements';
import { TestimonialCard } from '@/components/Elements/Cards/TestimonialCard';
import { ASSETS_URL } from '@/config';
import { useAccount, useIsMobile } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';

export const StayForBenefits = ({ navigation, route }: ProtectedScreenProps<'StayForBenefits'>) => {
  const isMobile = useIsMobile();
  const windowWidth = useWindowDimensions().width;
  const account = useAccount();
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const { mutateAsync: createExclusiveDiscount } = useCreateExclusiveDiscount(
    DiscountExclusiveType.WINBACK_10_PERCENT,
    route.params.petId
  );
  const pet = account.pets.find((pet) => pet.id === route.params.petId);
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    navigation.navigate('Account');
  };

  const onPressApplyDiscount = async () => {
    if (!pet || !account) {
      return;
    }
    setIsApplyingPromo(true);

    try {
      const { discount_code, discount_id } = await createExclusiveDiscount();

      segment.trackEvent('Cancellation Flow - Selected Discount', {
        account_id: account.id,
        email: account.email,
        pet_id: pet.id,
        discount_code,
        discount_id,
        cancellation_reason: route.params.primaryAnswer,
        cancellation_reason_secondary: route.params.secondaryAnswer,
        cancellation_reason_comment: route.params.customerComment,
      });
      setIsApplyingPromo(false);
      navigation.navigate('Orders', { petId: pet.id });

      displayToast({
        message: `${discount_code} promo code has been applied to your order.`,
        type: ToastType.Success,
      });
    } catch (error) {
      setIsApplyingPromo(false);
      displayToast({
        message:
          'We are unable to process your request at this time. Please try again later or contact care@spotandtango.com for further assistance.',
        type: ToastType.Error,
      });
    } finally {
      setIsApplyingPromo(false);
    }
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.primaryAnswer,
      secondaryAnswer: route.params.secondaryAnswer,
      customerComment: route.params.customerComment,
    });
    setStopPlanSuccessModalOpen(true);
  };

  if (!pet) {
    return null;
  }

  const testimonials = [
    {
      imageUrl: '/testimonials/kona.webp',
      author: 'AUGUSTA',
      petName: 'KONA',
      reviewText:
        'Since I made the switch Kona has so much more energy and is playful like when she was a puppy. She is not itching or licking her paws as much as she used to and is just overall healthier and happier. I highly recommend Spot & Tango for your pup!',
      emphasize: 'She is not itching or licking',
    },
    {
      imageUrl: '/testimonials/mocha.webp',
      author: 'JESS',
      petName: 'MOCHA',
      reviewText:
        "Our Mocha loves the food and it completely solved her digestive/food allergy issues. We struggled so much to find a high quality food that worked for her and couldn't be happier to have finally found Spot & Tango.",
      emphasize: 'completely solved her digestive/food allergy issues.',
    },
    {
      imageUrl: '/testimonials/tula.webp',
      author: 'MELLIE',
      petName: 'TULA',
      reviewText:
        "We are so happy to have discovered Spot & Tango! It's the best and our Tula is looking good because of it. Her coat shines and she looks super healthy after eating such great quality kibble for the last month. I know she's getting the nutrients she needs to thrive with Spot & Tango!",
      emphasize: 'Her coat shines',
    },
  ];

  return (
    <>
      <Stack alignItems="center" mb={10} px={{ base: 4, lg: undefined }} space={{ base: 2, lg: 4 }}>
        <Text size="bodySmToMd" fontFamily="secondary" fontWeight="medium" textAlign="center">
          Healthy habits take time. Stay on track to unlock long-term benefits with{' '}
          <Text fontWeight="bold">10% off your next 3 orders!</Text>
        </Text>
        {isMobile ? (
          <ResponsiveCarousel
            disableLoop
            width={{ base: windowWidth, md: windowWidth }}
            height={{ base: 540, md: 575 }}
            style={{
              borderWidth: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            variant="gallery"
            itemWidth={{ base: 340, md: 400 }}
            data={testimonials}
            renderItem={({ item }) => (
              <VStack
                width="100%"
                height="100%"
                key={item.petName}
                style={{ alignItems: 'center' }}
                overflow="hidden"
                justifyContent="center"
                alignItems="center"
              >
                <TestimonialCard
                  imageUrl={ASSETS_URL + item.imageUrl}
                  starCount={5}
                  reviewText={item.reviewText}
                  author={item.author}
                  petName={item.petName}
                  emphasize={item.emphasize}
                />
              </VStack>
            )}
          />
        ) : (
          <HStack
            space={5}
            mt={10}
            flexWrap="wrap"
            alignItems="center"
            justifyItems="center"
            justifyContent="center"
          >
            {testimonials.map(({ imageUrl, author, petName, reviewText, emphasize }) => (
              <VStack key={petName} mb={5}>
                <TestimonialCard
                  imageUrl={ASSETS_URL + imageUrl}
                  starCount={5}
                  reviewText={reviewText}
                  author={author}
                  petName={petName}
                  emphasize={emphasize}
                />
              </VStack>
            ))}
          </HStack>
        )}
        <Text
          size="bodySmToMd"
          color="sntGrey.primary"
          fontWeight="medium"
          mt={{ base: 2, md: 3 }}
          textAlign="center"
        >
          Any questions? Call or text us at (718) 514-6292. Our team is here to help.
        </Text>
      </Stack>
      <Stack variant="stickyFooter" alignItems="center" px={4}>
        <FormSubmitButton
          onPress={onPressApplyDiscount}
          isDisabled={isSubmittingCancel}
          isLoading={isApplyingPromo}
          h={{ base: '52px', md: '56px' }}
          px={{ base: 4, md: undefined }}
          mb={{ base: 4, lg: 6 }}
        >
          APPLY 10% OFF NEXT 3 ORDERS
        </FormSubmitButton>
        <Button
          variant="underlineMini"
          onPress={doCancelPlan}
          isDisabled={isSubmittingCancel || isApplyingPromo}
        >
          Continue to cancellation
        </Button>
      </Stack>
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={navigateToAccount}
        onConfirm={navigateToAccount}
      />
    </>
  );
};
