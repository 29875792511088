import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Stack, Text } from 'native-base';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PetStatus } from '@/api';
import { EditableCard } from '@/components/Elements';
import { PillCarousel } from '@/components/Elements/PillCarousel';
import { useAccount, usePaymentError } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { sortOrderByScheduled } from '@/utils';
const { logger } = datadogLogs;

type ActivePlanMenuItem = {
  id: string;
  heading: string;
  description: string;
  onPress: () => void;
  disabled?: boolean;
};

export const ManageActivePlans = ({
  navigation,
  route,
}: ProtectedScreenProps<'ManageActivePlans'>) => {
  const account = useAccount();
  const paymentError = usePaymentError();
  const pets = useMemo(
    () => account?.pets.filter((pet) => pet.status === PetStatus.ACTIVE),
    [account?.pets]
  );
  const [selectedPetId, setSelectedPetId] = useState<string>();
  useEffect(() => {
    if (pets?.length) {
      setSelectedPetId(pets[0].id);
    }
  }, [pets]);
  const pet = pets.find((pet) => pet.id === selectedPetId);
  const petPlan = account.petplans.find(({ pet: petId }) => petId === selectedPetId);
  const futureOrders = account.orders.upcoming
    .filter((order) => !order.charged)
    .sort(sortOrderByScheduled);
  const nextOrder = futureOrders.find((order) =>
    order.products.some((product) => product.pet_plan?.id === petPlan?.id)
  );
  useFocusEffect(
    useCallback(() => {
      if (pets.length < 1) {
        return;
      }
      const routePetId = route.params?.petId;
      const requestedUrl = localStorage.getItem('requestedUrl');
      if (requestedUrl) {
        localStorage.removeItem('requestedUrl');
        const url = new URL(requestedUrl);
        const urlPetId = url.searchParams.get('petId');
        if (!routePetId && urlPetId && pets.find((pet) => pet.id === urlPetId)) {
          setSelectedPetId(urlPetId);
        } else {
          logger.warn('Potential Deeplink failure: Pet not found by id', { urlPetId });
          setSelectedPetId(pets[0].id);
          navigation.setParams({ petId: pets[0].id });
        }
      } else if (routePetId && pets.find((pet) => pet.id === routePetId)) {
        setSelectedPetId(routePetId);
      } else {
        logger.warn('Potential Deeplink failure: Pet not found by id', { routePetId });
        setSelectedPetId(pets[0].id);
      }
    }, [])
  );

  useEffect(() => {
    navigation.setParams({ petId: selectedPetId });
  }, [selectedPetId]);

  if (!pet) {
    return null;
  }

  const dogItems = pets.map((pet) => ({
    label: pet.name,
    name: pet.name,
    id: pet.id,
  }));

  const setSelectedItemId = (id: string | undefined) => {
    if (id) setSelectedPetId(id);
  };

  const activePlanActions: ActivePlanMenuItem[] = [
    {
      id: 'action_1',
      heading: 'Reschedule Next Order',
      description: `Need your next shipment sooner or need more time before your next shipment? Reschedule your order.`,
      onPress: () => {
        navigation.navigate('Orders', {
          orderId: nextOrder?.id,
          successRedirect: 'Account',
          scrollToTop: true,
        });
      },
      disabled: !nextOrder,
    },
    {
      id: 'action_2',
      heading: 'Adjust Portion Size',
      description: `${pet.name}'s portion size doesn't seem right? Make adjustments.`,
      onPress: () => {
        segment.trackEvent('Manage Plans - Selected Adjust Portion Size', {
          account_id: account.id,
          email: account.email,
          pet_id: pet.id,
        });
        navigation.navigate('Dogs', {
          petId: pet.id,
          componentToRedirect: 'AdjustPortionSize',
        });
      },
    },
    {
      id: 'action_3',
      heading: 'Swap Recipe',
      description: `Is ${pet.name} ready to try something new? Change recipes.`,
      onPress: () => {
        segment.trackEvent('Manage Plans - Selected Swap Recipe', {
          account_id: account.id,
          email: account.email,
          pet_id: pet.id,
        });
        navigation.navigate('Dogs', {
          petId: pet.id,
        });
      },
    },
  ];

  const onPressCancelPlan = () => {
    if (paymentError === 'expired' || paymentError === 'error') {
      navigation.navigate('StopPlanSurvey', {
        petId: pet.id,
        pauseOrCancel: 'CANCEL',
      });
    } else {
      segment.trackEvent('Initiate Change Status', {
        email: account.email,
        pet_id: pet.id,
        dog_name: pet.name,
        pet_name: pet.name,
        account_id: account.id,
      });
      navigation.navigate('ChangePlanStatus', { petId: pet.id });
    }
  };

  return (
    <Stack
      alignItems="center"
      w="100%"
      px={{ base: '16px', lg: '0px' }}
      pb={{ base: '24px', lg: '48px' }}
    >
      <Stack mb={{ base: '24px', lg: '48px' }} width="100%">
        <PillCarousel
          selectedItem={{ ...pet, label: pet.name }}
          setSelectedItemId={setSelectedItemId}
          items={dogItems}
        />
      </Stack>
      <Stack w={{ base: '100%', lg: '540px' }}>
        <Stack space={{ base: '16px', lg: '24px' }}>
          {activePlanActions.map((item: ActivePlanMenuItem) => (
            <EditableCard
              key={item.id}
              onPress={item.onPress}
              heading={item.heading}
              stackProps={{ direction: 'column', space: { base: '8px', lg: '16px ' } }}
              disabled={item?.disabled || false}
            >
              <Text size="bodyMdToLg">{item.description}</Text>
            </EditableCard>
          ))}
          <Button variant="underlineMini" onPress={onPressCancelPlan}>
            Cancel Plan
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
