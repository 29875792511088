import { datadogLogs } from '@datadog/browser-logs';
import { HStack, VStack, Text, Badge, Center, Divider, Stack } from 'native-base';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { BufferBagLineItem, BufferBagSelectionKey } from './BufferBagLineItem';

import { useUpdateBufferBagProductsToOrder } from '@/api';
import { useBufferBags } from '@/api/services/bufferBags/useBufferBags';
import { ProductType } from '@/api/types';
import { VToHStack, Avatar, displayToast, ToastType } from '@/components/Elements';
import { IS_BUFFER_BAG_LAUNCHED } from '@/config';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { calculateTotalsPerDog, prepareBufferBagProducts } from '@/utils/bufferBags';

const BufferBags = ({ navigation, route }: ProtectedScreenProps<'BufferBags'>) => {
  const account = useAccount();
  useEffect(() => {
    if (IS_BUFFER_BAG_LAUNCHED !== 'true') {
      navigation.replace('Home');
    }
  }, [navigation]);

  const [selectedBufferBags, setSelectedBufferBags] = useState<
    Record<BufferBagSelectionKey, number>
  >({});
  const selectedOrderId = route.params?.orderId;
  const editBufferBag = route.params?.editBufferBag;
  const { data: bufferBagList } = useBufferBags(selectedOrderId);
  const existingBufferBagProducts = useMemo(
    () =>
      account.orders?.upcoming
        ?.find((order) => order.id === selectedOrderId)
        ?.products.filter((product) => product.product_type === ProductType.BUFFER),
    [account.orders?.upcoming, selectedOrderId]
  );
  const { mutateAsync: updateBufferBagProductsToOrder } = useUpdateBufferBagProductsToOrder();
  const { logger } = datadogLogs;

  const initialBufferBagStateRef = useRef<Record<BufferBagSelectionKey, number>>({});

  useEffect(() => {
    if (!existingBufferBagProducts?.length) {
      return;
    }
    const initialBufferBagState: { [key: BufferBagSelectionKey]: number } = {};

    existingBufferBagProducts.forEach((product) => {
      if (product.pet_plan?.id && product.code) {
        const key: BufferBagSelectionKey = `${product.pet_plan.id}~${product.code}`;
        initialBufferBagState[key] = product.quantity;
      }
    });
    setSelectedBufferBags(initialBufferBagState);
    initialBufferBagStateRef.current = initialBufferBagState;
  }, [existingBufferBagProducts]);

  const hasBufferBagsChanged = useMemo(() => {
    return Object.keys(selectedBufferBags).some(
      (key) =>
        selectedBufferBags[key as BufferBagSelectionKey] !==
        initialBufferBagStateRef.current[key as BufferBagSelectionKey]
    );
  }, [selectedBufferBags]);

  const handleSubmit = async () => {
    try {
      const productUpdatePayload = prepareBufferBagProducts(selectedBufferBags, bufferBagList);
      if (productUpdatePayload.length > 0) {
        await updateBufferBagProductsToOrder({
          updatedProducts: productUpdatePayload,
          selectedOrderId: selectedOrderId || '',
        });
        const redirectSuccessMessage = editBufferBag
          ? 'We have updated the extra meals on your next order.'
          : 'Extra UnKibble meals have been added to your next order.';
        displayToast({
          title: 'Success!',
          message: redirectSuccessMessage,
          type: ToastType.Success,
        });
      } else {
        logger.error(
          `tried to add or remove buffer bags with no changes, orderID: ${selectedOrderId}`
        );
        displayToast({
          message: 'No Buffer Bag changes were made to your order.',
          type: ToastType.Error,
        });
      }
      navigation.navigate('Orders', {
        orderId: selectedOrderId,
      });
    } catch (error) {
      logger.error(`error adding buffer bags to order, orderID: ${selectedOrderId}`, { error });
      displayToast({
        message: `An error occurred while adding buffer bags to your next order.`,
        type: ToastType.Error,
      });
    }
  };

  return (
    <>
      <VStack space={6} w="90%" maxW={{ base: '100%', lg: '840px' }} alignSelf="center">
        <VToHStack space={1} alignItems="center" justifyContent="center">
          <Text size="bodySmToMd" alignSelf="center">
            Keep your pantry stocked with extra meals,
          </Text>
          <Text size="bodySmToMd" alignSelf="center">
            delivered with your next order.
          </Text>
        </VToHStack>
        <Stack
          borderWidth={1}
          borderColor="sntGrey.outline"
          p={{ base: 4, lg: 6 }}
          mb={24}
          space={4}
          w="100%"
        >
          {bufferBagList?.map((petBufferBag, index) => {
            const { totalDays, totalCost } = calculateTotalsPerDog(
              petBufferBag.pet_plan_id,
              bufferBagList,
              selectedBufferBags
            );
            return (
              <VStack key={petBufferBag.pet_plan_id} w="100%" space={4}>
                <HStack w="100%" space={4}>
                  <VStack h="100%" w="fit-content" justifyContent="start">
                    <Avatar
                      petGender={petBufferBag.pet_gender}
                      imageSize={{ base: '30px', lg: '60px' }}
                      petId={petBufferBag.pet_plan_id}
                    />
                  </VStack>
                  <VStack flex={1} space={4}>
                    <VStack flex={1} space={1} alignItems="space-between" justifyContent="center">
                      <HStack justifyContent="space-between" alignItems="center" flex={1}>
                        <Text size="bodyMlToTitleSm" fontWeight="700">
                          {petBufferBag.pet_name.length > 10
                            ? `${petBufferBag.pet_name.slice(0, 10)}...`
                            : petBufferBag.pet_name}
                          's Extra Meals
                        </Text>
                        <Badge
                          variant="smallInfoPill"
                          borderColor="black"
                          borderWidth={1}
                          background="white"
                        >
                          <Text size="captionToBodyMd" fontWeight="bold" textAlign="center">
                            ${totalCost.toFixed(2)}
                          </Text>
                        </Badge>
                      </HStack>
                      <Text
                        size="caption"
                        fontWeight="500"
                        w="100%"
                        textAlign={{ base: 'left', lg: 'right' }}
                      >
                        {totalDays} days of extra food
                      </Text>
                    </VStack>

                    <VStack space={{ base: 8, lg: 6 }}>
                      {petBufferBag.buffer_bag_products.map((product) => (
                        <BufferBagLineItem
                          key={`${petBufferBag.pet_plan_id}~${product.buffer_bag_product_code}`}
                          petBufferBag={petBufferBag}
                          product={product}
                          selectedBufferBags={selectedBufferBags}
                          setSelectedBufferBags={setSelectedBufferBags}
                        />
                      ))}
                    </VStack>
                  </VStack>
                </HStack>
                {index < bufferBagList.length - 1 && <Divider bg="gallery" />}
              </VStack>
            );
          })}
        </Stack>
      </VStack>
      <HStack
        position="fixed"
        bottom={0}
        bg="white"
        w="100%"
        p={6}
        borderTopWidth={1}
        borderTopColor="gray.200"
        shadow={2}
      >
        <Center w="100%">
          <FormSubmitButton
            onPress={handleSubmit}
            isDisabled={!hasBufferBagsChanged}
            w={{ base: '100%', md: '424px' }}
          >
            {editBufferBag ? 'SAVE CHANGES' : 'ADD TO NEXT ORDER'}
          </FormSubmitButton>
        </Center>
      </HStack>
    </>
  );
};

export { BufferBags };
