import { FormControl, Heading, Text } from 'native-base';
import { FieldValues, Path, useWatch } from 'react-hook-form';

import { AnimatedBlock } from '../Animation';
import { LabeledFormControl, LabeledFormControlProps } from '../LabeledFormControl';
import { Select, SelectedPills } from '../Select';

import { usePetFormOptions } from '@/api';
import { BREEDS_LIMIT, getBreedOptions } from '@/utils';

export interface LabeledBreedSelectProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledFormControlProps<TFieldValues, TName>, 'children' | 'name'> {
  petName: string;
}
export const LabeledBreedSelect = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  control,
  petName,
}: LabeledBreedSelectProps<TFieldValues, TName>) => {
  const breeds = useWatch({ control, name: 'breeds' as TName });
  const { data: petFormData } = usePetFormOptions();
  const breedOptions = getBreedOptions(petFormData?.breeds);
  const breedsOverLimit = breeds.length >= BREEDS_LIMIT;

  return (
    <LabeledFormControl
      control={control}
      name={'breeds' as TName}
      label="Breed"
      isInvalid={!breeds.length}
      stackProps={{ space: { base: '4px', md: '8px' } }}
    >
      {({ field: { onChange, value } }) => {
        const selectedBreeds = breedOptions.filter((option) => value.includes(option.value));
        const onSelectBreed = (selectedOptions: any) =>
          onChange(selectedOptions?.map((option: any) => option.value));
        const onRemoveBreed = (breed: string) =>
          onChange(
            selectedBreeds.filter((option) => option.value !== breed).map((option) => option.value)
          );

        return (
          <>
            <Heading size="bodySmToMd" mb={{ base: '4px', md: '8px' }}>
              Select all that apply
            </Heading>
            <Select
              isDisabled={breedsOverLimit}
              onChange={onSelectBreed}
              value={selectedBreeds}
              options={breedOptions}
              blurInputOnSelect
              placeholder="Select Breed(s)"
              textStyling={{
                fontFamily: 'MonumentGrotesk-Medium',
              }}
              isMulti
            />
            {!breeds.length ? (
              <FormControl.ErrorMessage>Please select a breed</FormControl.ErrorMessage>
            ) : null}
            {breedsOverLimit ? (
              <AnimatedBlock showBlock>
                <Text size="bodySmToMd">
                  {`If ${petName} is more than five breeds, choose Mixed Breed`}
                </Text>
              </AnimatedBlock>
            ) : null}
            <SelectedPills
              w="100%"
              mt="16px"
              selected={selectedBreeds}
              onPillPress={onRemoveBreed}
              justifyContent="center"
            />
          </>
        );
      }}
    </LabeledFormControl>
  );
};
