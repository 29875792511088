import {
  Button,
  HStack,
  IHeadingProps,
  IModalProps,
  IStackProps,
  ITextProps,
  Modal,
  Stack,
  Text,
} from 'native-base';
import { useState } from 'react';

import {
  CloseIconButton,
  ContentStack,
  ModalHeader,
  ModalHeaderProps,
} from '@/components/Elements';

export interface VerticalPortalPopUpProps extends IModalProps {
  heading: ModalHeaderProps['heading'];
  subHeading?: ModalHeaderProps['subHeading'];
  body?: IStackProps['children'];
  primaryCtaText?: string;
  secondaryCtaText?: string;
  onClose?: () => void;
  onPrimaryPress?: (() => Promise<void>) | (() => void);
  onSecondaryPress?: (() => Promise<void>) | (() => void);
  footerText?: string;
  headingProps?: IHeadingProps;
  subHeadingProps?: ITextProps;
  footerTextProps?: ITextProps;
}

export const VerticalPortalPopUp = ({
  heading,
  subHeading,
  body,
  primaryCtaText,
  secondaryCtaText,
  onClose,
  onPrimaryPress,
  onSecondaryPress,
  footerText,
  headingProps,
  subHeadingProps,
  footerTextProps,
  ...props
}: VerticalPortalPopUpProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingPress = async (onPress?: (() => Promise<void>) | (() => void)) => {
    if (isLoading) return;
    setIsLoading(true);
    onPress && (await onPress());
    setIsLoading(false);
  };

  return (
    <Modal w="100%" h="100%" alignSelf="center" useRNModal {...props}>
      <Modal.Content
        bg="white"
        borderWidth={{ base: '1px', md: '2px' }}
        borderColor="black"
        p={{ base: 4, md: 6 }}
        w="100%"
        maxW={{ md: '540px' }}
        maxH="100%"
        display="flex"
        alignItems="center"
      >
        <ContentStack p={0} space={{ base: '16px', lg: '24px' }}>
          <HStack w="100%">
            <CloseIconButton onPress={onClose ? onClose : undefined} />
            <ModalHeader
              heading={heading}
              subHeading={subHeading}
              headingProps={headingProps}
              subHeadingProps={subHeadingProps}
              contentStackProps={{ px: 0 }}
            />
          </HStack>
          {body ?? null}
          <Modal.Footer bgColor="white" p={0} borderTopStyle="none" w="100%">
            <Stack w="100%" space={{ base: 4, md: 6 }} alignItems="center">
              <Button
                variant="hugPrimary"
                px={{ base: 4, md: 9 }}
                w="100%"
                maxW={{ md: '373px' }}
                onPress={() => handleLoadingPress(onPrimaryPress)}
                isLoading={isLoading}
              >
                {primaryCtaText}
              </Button>
              <Button
                variant="underlineMini"
                size="bodyMd"
                onPress={() => handleLoadingPress(onSecondaryPress)}
                isDisabled={isLoading}
              >
                {secondaryCtaText}
              </Button>
              {footerText ? (
                <Text size="tinyToCaption" color="sntGrey.primary" {...footerTextProps}>
                  {footerText}
                </Text>
              ) : null}
            </Stack>
          </Modal.Footer>
        </ContentStack>
      </Modal.Content>
    </Modal>
  );
};
