import { datadogLogs } from '@datadog/browser-logs';

import { states } from '@/constants';

const { logger } = datadogLogs;

export const sanitizeState = (state?: string, sessionId?: string): string => {
  if (state === undefined) {
    return '';
  }
  let sanitizedState: string = state;
  // Remove all characters besides letters and spaces, then trim spaces from beginning
  // and end.
  sanitizedState = sanitizedState.replace(/[^a-zA-Z ]/gi, '').trim();
  if (sanitizedState.length > 2) {
    const matchingState = states.find(
      (stateNames) => stateNames.name.toLocaleLowerCase() === sanitizedState.toLocaleLowerCase()
    );
    if (matchingState) {
      sanitizedState = matchingState.short;
      logger.error(
        `Long state name detected. Abbreviating state ${state} to ${sanitizedState}. session_id=${sessionId}`
      );
    }
  }
  return sanitizedState;
};
