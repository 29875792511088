import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Stack, Flex, Text, Heading, VStack, Divider, FlatList } from 'native-base';
import { useMemo } from 'react';

import { PetsAvatarPressableList } from '../PetsAvatarPressableList';

import { PetStatus } from '@/api';
import { Image, MarketingCard } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
import { useAccount, useDogModal } from '@/hooks';
import { ProtectedStackParamList } from '@/types';

type ReactivateBlockInfo = {
  id: string;
  title: string;
  description: string;
  image: string;
};
const infoForReactivateBlock: ReactivateBlockInfo[] = [
  {
    id: '1',
    title: 'Personalized Nutrition',
    description: 'Meal plans are tailored specifically to your dog',
    image: `${ASSETS_URL}/pictos/Reactivate/Personalized-Nutrition.png`,
  },
  {
    id: '2',
    title: 'Real Food',
    description: 'High-quality, human grade ingredients',
    image: `${ASSETS_URL}/pictos/Reactivate/Read-Food.png`,
  },
  {
    id: '3',
    title: 'Health Benefits',
    description: 'Energy boosts, shiny coats, and perfect poops',
    image: `${ASSETS_URL}/pictos/Reactivate/Health-Benefits.png`,
  },
];

const HomeTabInactiveDogsView = () => {
  const account = useAccount();
  const pets = account.pets;

  const planDescription = pets.length > 1 ? 'Your plans are inactive' : 'Your plan is inactive';
  const filteredPets = useMemo(
    () => pets.filter((pet) => pet.status !== PetStatus.DECEASED_TO_NOT_LIST),
    [pets]
  );
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const { setShowModal: setShowAddDogModal, Modal: AddDogModal } = useDogModal('add');
  return (
    <VStack
      space={{ base: '16px', lg: '24px' }}
      w={{
        base: '100%',
        md: '840px',
      }}
      p={{ base: '16px', md: 0 }}
      pt={{ base: '24px', md: '48px' }}
    >
      <Heading
        size={{ base: 'bodyMl', md: 'titleSm' }}
        fontWeight="bold"
        textAlign={{
          base: 'left',
        }}
      >
        Welcome Back!
      </Heading>
      <Text fontFamily="secondary" fontWeight="medium" size="bodySmToMd">
        {planDescription}
      </Text>
      <PetsAvatarPressableList />
      <Divider bg="gallery" />
      <Heading
        size={{ base: 'bodyMl', md: 'titleSm' }}
        fontWeight="bold"
        textAlign={{
          base: 'left',
        }}
      >
        Reactivate now and you'll gain...
      </Heading>
      <FlatList
        horizontal
        width={{ base: '100%', md: 'fit-content' }}
        showsHorizontalScrollIndicator={false}
        alignSelf="center"
        contentContainerStyle={{ alignItems: 'center' }}
        data={infoForReactivateBlock}
        keyExtractor={(item) => `pet_item_${item.id}`}
        renderItem={({ item }) => (
          <Stack
            w="208px"
            alignItems="center"
            ml={{ base: 0, md: '12px' }}
            mr={{ base: 0, md: '12px' }}
          >
            <Flex
              w="120px"
              h="120px"
              overflow="hidden"
              justifyContent="center"
              alignItems="center"
              backgroundColor="white"
            >
              <Image w="100%" h="100%" source={{ uri: item.image }} resizeMode="cover" alt="Dog" />
            </Flex>
            <Heading size="bodyMd" fontWeight="bold">
              {item.title}
            </Heading>
            <Text fontFamily="secondary" fontWeight="medium" size="bodySmToMd" textAlign="center">
              {item.description}
            </Text>
          </Stack>
        )}
      />
      <Divider bg="gallery" />
      <MarketingCard
        header={null}
        content={null}
        source={`${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_08511.png`}
        ctaText="ADD A NEW DOG"
        variant="cta"
        w={{ base: '100%' }}
        justifyContent="center"
        alignItems="center"
        p={{ base: 4, md: 6 }}
        pt={{ base: 0, md: 2 }}
        ctaOnPress={() => {
          if (filteredPets.length < 8) {
            navigation.navigate('AddNewDog');
            return;
          }
          setShowAddDogModal(true);
        }}
      />
      <AddDogModal />
    </VStack>
  );
};

export default HomeTabInactiveDogsView;
