import { useMutation } from 'react-query';

import { useAuth } from '@/hooks';
import { axios } from '@/lib/axios';

interface UpdatePetParams {
  petId: string;
  accountId: string;
  name: string;
  breeds: string[];
  weight: number;
  neutered: string;
  birth_month: string | null;
  birth_year: string;
  body_type: string;
  lifestyle: string;
}

const updatePet = async ({
  petId,
  accountId,
  name,
  breeds,
  weight,
  neutered,
  birth_month,
  birth_year,
  body_type,
  lifestyle,
}: UpdatePetParams) => {
  const response = await axios.put(`pets/${petId}`, {
    account: accountId,
    name,
    breeds,
    weight,
    neutered,
    birth_month,
    birth_year,
    body_type,
    lifestyle,
  });
  return response.data;
};

export const useUpdatePet = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({
      petId,
      accountId,
      name,
      breeds,
      weight,
      neutered,
      birth_month,
      birth_year,
      body_type,
      lifestyle,
    }: UpdatePetParams) =>
      updatePet({
        petId,
        accountId,
        name,
        breeds,
        weight,
        neutered,
        birth_month,
        birth_year,
        body_type,
        lifestyle,
      }),
    {
      onSuccess: () => refetchUser(),
    }
  );
};
