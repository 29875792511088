import { Radio, Stack, Text } from 'native-base';
import { useState } from 'react';

import { PetGender } from '@/api';
import { PopUpProps, PortalPopUp } from '@/components/Elements';

interface TopperOrFullModalProps extends PopUpProps {
  isOpen: boolean;
  petName: string;
  petGender: string;
  onSave: (isMixing?: boolean) => void;
}
export const TopperOrFullModal = ({
  isOpen,
  petName,
  petGender,
  onSave,
  ...props
}: TopperOrFullModalProps) => {
  const [isMixing, setIsMixing] = useState<boolean | undefined>();
  const hisOrHer = petGender === PetGender.FEMALE ? 'her' : 'his';
  const himOrHer = petGender === PetGender.FEMALE ? 'her' : 'him';

  return (
    <PortalPopUp
      isOpen={isOpen}
      heading={`How are you feeding ${petName}?`}
      description={`If you're feeding only Spot & Tango, we recommend a larger portion size for ${petName} based on ${hisOrHer} profile. Can you let us know how you're feeding ${himOrHer}?`}
      subHeadingProps={{ size: 'bodySmToMd', fontFamily: 'primary' }}
      onConfirm={() => onSave(isMixing)}
      confirmBtnText="SAVE"
      isConfirmButtonDisabled={isMixing === undefined}
      showCloseButton={false}
      size="xl"
      w={{ base: '100%', md: '440px' }}
      contentStackProps={{ px: 0 }}
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      contentScrollable={false}
      useRNModal
      {...props}
    >
      <Stack>
        <Radio.Group
          name="selectedValue"
          accessibilityLabel="Is mixing or feeding 100% Spot & Tango"
          value={isMixing === undefined ? undefined : String(isMixing)}
          onChange={(value) => setIsMixing(value === 'true')}
        >
          <Stack w="100%" space={{ base: '16px', lg: '24px' }}>
            <Radio value="true">
              <Text size="bodyMdToLg" fontWeight="medium">
                I'm mixing with other food
              </Text>
            </Radio>
            <Radio value="false">
              <Text size="bodyMdToLg" fontWeight="medium">
                I'm feeding only Spot & Tango
              </Text>
            </Radio>
          </Stack>
        </Radio.Group>
      </Stack>
    </PortalPopUp>
  );
};
