import { BufferBag } from '@/api';

export interface PetPlanBufferBagTotals {
  totalDays: number;
  totalCost: number;
}

export interface BufferBagProduct {
  pet_id: string;
  product_id: string;
  buffer_bag_product_code: string;
  buffer_bag_price: number;
  buffer_bag_extra_days: number;
  order_id: string;
}

export const calculateTotalsPerDog = (
  petPlanId: string,
  bufferBagList: BufferBag[],
  selectedBufferBags: Record<string, number>
): PetPlanBufferBagTotals => {
  let totalDays = 0;
  let totalCost = 0;

  const bufferBagInfo = bufferBagList?.find(
    (buffer_bag_info) => buffer_bag_info.pet_plan_id === petPlanId
  );
  const numberOfDaysPerBag = bufferBagInfo?.buffer_bag_extra_days || 0;
  const products = bufferBagInfo?.buffer_bag_products;
  Object.entries(selectedBufferBags).forEach(([petPlanRecipe, numberOfBags]) => {
    const [dogPetPlanId, bufferBagProductCode] = petPlanRecipe.split('~');
    if (dogPetPlanId === petPlanId) {
      const product = products?.find(
        (prod) => prod.buffer_bag_product_code === bufferBagProductCode
      );
      if (product) {
        const pricePerBag = Number(product.buffer_bag_price) || 0;
        totalDays += numberOfBags * numberOfDaysPerBag;
        totalCost += numberOfBags * pricePerBag;
      }
    }
  });
  return { totalDays, totalCost };
};

export const generateFormattedBufferOptions = (extraDays: number, numberOfBags: number) => {
  const options = [];
  for (let i = 0; i <= numberOfBags; i++) {
    options.push({
      value: i.toString(),
      label: (i * extraDays).toString(),
    });
  }
  return options;
};

export const prepareBufferBagProducts = (
  selectedBufferBags: Record<string, number>,
  bufferBagList?: BufferBag[]
): { productCode: string; quantity: number; petPlanId: string }[] => {
  if (!bufferBagList) return [];

  const productUpdatePayload: { productCode: string; quantity: number; petPlanId: string }[] = [];

  Object.entries(selectedBufferBags).forEach(([dogFood, numberOfBags]) => {
    const [dogPetPlanId, bufferBagProductCode] = dogFood.split('~');
    const product = bufferBagList
      .find((pet) => pet.pet_plan_id === dogPetPlanId)
      ?.buffer_bag_products?.find((prod) => prod.buffer_bag_product_code === bufferBagProductCode);

    if (product) {
      productUpdatePayload.push({
        productCode: product.buffer_bag_product_code,
        quantity: numberOfBags,
        petPlanId: dogPetPlanId,
      });
    }
  });
  return productUpdatePayload;
};
