import { Stack, Radio, Text } from 'native-base';
import { useState } from 'react';

import { PetGender } from '@/api';
import { PopUpProps, PortalPopUp } from '@/components/Elements';

interface ConfirmPortionsModalProps extends PopUpProps {
  isOpen: boolean;
  petName: string;
  petGender: string;
  onIncreasePortions: () => void;
  onKeepCurrentPortions: () => void;
}

export const ConfirmPortionsModal = ({
  isOpen,
  petName,
  petGender,
  onIncreasePortions,
  onKeepCurrentPortions,
  ...props
}: ConfirmPortionsModalProps) => {
  const [isIncreasingPortion, setIsIncreasingPortion] = useState<boolean | undefined>();
  const hisOrHer = petGender === PetGender.FEMALE ? 'her' : 'his';

  return (
    <PortalPopUp
      isOpen={isOpen}
      heading={`Are you sure about ${petName}'s portions?`}
      description={`Since ${petName} is eating only Spot & Tango, ${hisOrHer} current portions might not provide enough calories to meet ${hisOrHer} daily needs. What would you like to do?`}
      subHeadingProps={{ size: 'bodySmToMd', fontFamily: 'primary' }}
      onConfirm={() => {
        if (isIncreasingPortion) onIncreasePortions();
        else onKeepCurrentPortions();
      }}
      confirmBtnText="CONFIRM"
      isConfirmButtonDisabled={isIncreasingPortion === undefined}
      showCloseButton={false}
      size="xl"
      w={{ base: '100%', md: '440px' }}
      contentStackProps={{ px: 0 }}
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      contentScrollable={false}
      useRNModal
      {...props}
    >
      <Stack>
        <Radio.Group
          name="selectedValue"
          accessibilityLabel="Increase or keep current portions"
          value={isIncreasingPortion === undefined ? undefined : String(isIncreasingPortion)}
          onChange={(value) => setIsIncreasingPortion(value === 'true')}
        >
          <Stack w="100%" space={{ base: '16px', lg: '24px' }}>
            <Radio value="true">
              <Text size="bodyMdToLg" fontWeight="medium">
                Increase portions to the recommendation
              </Text>
            </Radio>
            <Radio value="false">
              <Text size="bodyMdToLg" fontWeight="medium">
                Keep current portions
              </Text>
            </Radio>
          </Stack>
        </Radio.Group>
      </Stack>
    </PortalPopUp>
  );
};
