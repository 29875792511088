import { HStack, VStack } from 'native-base';
import { ImageResizeMode } from 'react-native';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/config';

const baseUri = `${ASSETS_URL}/pictos/Portal/Shop/`;

export const DesktopProductImages = () => {
  const suppTextStyle = {
    h: { lg: '35px', xl: '42px' },
    w: '100%',
    resizeMode: 'contain' as ImageResizeMode,
  };
  return (
    <HStack h="100%" zIndex={3}>
      <HStack
        h="100%"
        w={{ lg: '185px', xl: '250px' }}
        justifyContent="center"
        overflow="hidden"
        zIndex={1}
      >
        <Image
          source={{ uri: `${baseUri}snacks-bubble-text.png` }}
          w={{ lg: '110px', xl: '160px' }}
          h={{ lg: '50px', xl: '75px' }}
          position="absolute"
          top={{ lg: 4, xl: 2 }}
          left={{ lg: 0, xl: -2 }}
          zIndex={2}
        />
        <Image
          h="100%"
          w="100%"
          resizeMode="cover"
          source={{ uri: `${baseUri}chicken-bites-diagonal.png` }}
          zIndex={1}
        />
      </HStack>
      <HStack
        h="100%"
        w={{ lg: '235px', xl: '300px' }}
        justifyContent="center"
        alignItems="end"
        zIndex={2}
      >
        <VStack
          w={{ lg: '90px', xl: '110px' }}
          position="absolute"
          bottom={1}
          left={0}
          zIndex={2}
          alignItems="center"
        >
          <Image {...suppTextStyle} ml={4} source={{ uri: `${baseUri}supp-block-text.png` }} />
          <Image {...suppTextStyle} source={{ uri: `${baseUri}lem-block-text.png` }} />
          <Image {...suppTextStyle} source={{ uri: `${baseUri}ents-block-text.png` }} />
        </VStack>
        <Image
          h="100%"
          w="100%"
          zIndex={1}
          position="relative"
          left={{ lg: 9, xl: 12 }}
          source={{ uri: `${baseUri}hip-joint-transparent.png` }}
        />
      </HStack>
      <HStack h="100%" w={{ lg: '210px', xl: '245px' }} overflow="visible" zIndex={3}>
        <Image
          w={{ lg: '120px', xl: '180px' }}
          h={{ lg: '40px', xl: '45px' }}
          position="absolute"
          top={{ lg: '36px', xl: 10 }}
          left={{ lg: '-20px', xl: -50 }}
          source={{ uri: `${baseUri}merch-serif-text.png` }}
          zIndex={2}
        />
        <Image
          h="100%"
          w="100%"
          resizeMode="cover"
          left={{ lg: '40px', xl: 12 }}
          source={{ uri: `${baseUri}tote-diagonal.png` }}
          zIndex={1}
        />
      </HStack>
    </HStack>
  );
};
