import { useTheme, Text } from 'native-base';
import React from 'react';
import {
  default as ReactSelect,
  Props,
  StylesConfig,
  SingleValueProps,
  components,
} from 'react-select';

export interface SelectProps extends Props {
  innerRef?: any;
  alignment?: 'left' | 'center';
  textStyling?: any;
  extraStyles?: {
    valueContainer?: any;
    placeholder?: any;
    input?: any;
    dropdownIndicator?: any;
    indicatorsContainer?: any;
  } | null;
  withMenuShadow?: boolean;
}

export type SelectOption = { value: string; label: string };

export const SingleValue = (props: SingleValueProps) => {
  return (
    <components.SingleValue {...props}>
      <Text size="bodyMd" fontWeight="medium">
        {props.children}
      </Text>
    </components.SingleValue>
  );
};

export const Select = ({
  innerRef,
  alignment = 'left',
  extraStyles = null,
  withMenuShadow = false,
  ...props
}: SelectProps) => {
  const { colors, fonts } = useTheme();

  const textStyling = {
    fontFamily: fonts.primary,
    color: colors.black,
    fontSize: '16px',
    lineHeight: '20px',
    ...props?.textStyling,
  };
  const portalReactSelectStyles: StylesConfig = {
    container: (provided, state) => ({
      ...provided,
      border: `1px solid ${colors.black}`,
      padding: '1px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '&:hover': {
        padding: 0,
        border: `2px solid ${colors.black}`,
      },
      ...(state.isFocused
        ? {
            padding: 0,
            border: `2px solid ${colors.sntBlue.primary} !important`,
          }
        : {}),
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: colors.white,
      width: '100%',
      borderRadius: '0',
      overflow: 'hidden',
      boxShadow: withMenuShadow ? '0px -4px 4px 0px rgba(0, 0, 0, 0.05)' : 'none',
    }),
    control: (provided) => ({
      ...provided,
      ...textStyling,
      width: '100%',
      backgroundColor: colors.white,
      border: '0',
      // Removes default blue border when focused
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: alignment === 'center' ? 0 : '8px',
      minHeight: 'fit-content',
      height: 'fit-content',
    }),
    valueContainer: (provided) => ({
      ...provided,
      margin: '0',
      padding: '10px 16px',
      ...(alignment === 'center' ? { justifyContent: 'right', paddingRight: 0 } : {}),
      ...(extraStyles && extraStyles?.valueContainer),
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      flex: alignment === 'center' ? 1 : undefined,
      paddingRight: alignment === 'left' ? '8px' : undefined,
      paddingLeft: 0,
      ...extraStyles?.indicatorsContainer,
    }),
    clearIndicator: () => ({ display: 'none' }),
    input: (provided, state) => ({
      ...provided,
      color: state.isMulti && state.hasValue ? colors.black : colors.sntGrey.primary,
      ...extraStyles?.input,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...textStyling,
      color:
        (state.isMulti && state.hasValue) || state.isFocused
          ? colors.black
          : colors.sntGrey.primary,
      ...extraStyles?.placeholder,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      ...textStyling,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...textStyling,
      '&:hover': {
        ...textStyling,
      },
      '& svg': {
        width: '16px',
      },
      paddingLeft: 0,
      ...extraStyles?.dropdownIndicator,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ...textStyling,
      '&:hover': {
        ...textStyling,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      ...textStyling,
      fontFamily: 'MonumentGrotesk-Medium',
      padding: '12px 16px',
      textAlign: alignment === 'center' ? 'center' : undefined,
      backgroundColor: state.isSelected ? `${colors.sntBlue.primary}35` : undefined,
      '&:hover': {
        backgroundColor: state.isSelected ? undefined : `${colors.sntBlue.primary}35`,
      },
      ':active': {
        // active means while pressing on an option
        backgroundColor: `${colors.sntBlue.primary} !important`,
      },
    }),
  };
  return (
    <ReactSelect
      ref={innerRef}
      components={{ SingleValue }}
      hideSelectedOptions={false}
      styles={portalReactSelectStyles}
      menuPortalTarget={document.body}
      isClearable
      backspaceRemovesValue
      controlShouldRenderValue={!props.isMulti}
      {...props}
    />
  );
};
