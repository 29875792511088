import { useFocusEffect } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Heading, Stack, Text } from 'native-base';
import React, { useCallback, useState } from 'react';

import { AnimatedBlock, MarketingCard } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { ASSETS_URL } from '@/config';
import { LINK_TO_WEB1, LINK_TO_WEB2 } from '@/constants';
import { FunnelScreenProps } from '@/types';

export default function WelcomeScreen({ route }: FunnelScreenProps<'WelcomeScreen'>) {
  const [showContentBlock, setShowContentBlock] = useState(false);

  const handleNavigateToPortal = () => {
    const redirect_uri =
      route.params?.welcomeParams.versionOfWeb === '2.0' ? LINK_TO_WEB2 : LINK_TO_WEB1;
    Linking.openURL(redirect_uri);
  };

  useFocusEffect(
    useCallback(() => {
      setShowContentBlock(true);

      return () => {
        setShowContentBlock(false);
      };
    }, [showContentBlock])
  );
  return (
    <FunnelWrapper>
      <Stack pb={{ base: 4, lg: 6 }} maxW="840px" w="100%">
        <AnimatedBlock showBlock={showContentBlock}>
          <Heading
            size="titleSmToMd"
            fontWeight="bold"
            pb={{ base: '16px', md: '34px' }}
            textAlign="center"
          >
            Welcome Back, {route.params?.welcomeParams.first_name}!
          </Heading>
          <MarketingCard
            header="Please log in to your account"
            content={
              <Text fontFamily="secondary" fontWeight="medium" size="bodySmToMd" textAlign="center">
                You can also email
                <Text fontWeight="bold"> care@spotandtango.com </Text>
                to make changes to your plan.
              </Text>
            }
            source={`${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_23711.png`}
            ctaText="LOG IN"
            variant="cta"
            w={{ base: '100%' }}
            justifyContent="center"
            alignItems="center"
            p={{ base: 4, md: 6 }}
            imageProps={{ h: { base: '337px', md: '391px' } }}
            buttonProps={{
              variant: 'primary',
              w: 'fit-content',
              px: { base: '16px', md: '36px' },
              py: { base: '14px', md: '12px' },
              size: 'bodyMlToLg',
            }}
            ctaOnPress={handleNavigateToPortal}
          />
        </AnimatedBlock>
      </Stack>
    </FunnelWrapper>
  );
}
