import { Text, VStack, Image, HStack, Stack } from 'native-base';
import React from 'react';

import { Star } from '@/components/Icons';

export type TestimonialCardProps = {
  imageUrl: string;
  starCount?: number;
  reviewText: string;
  author?: string;
  petName?: string;
  emphasize: string;
};

export const TestimonialCard = ({
  imageUrl,
  starCount = 5,
  reviewText,
  author,
  petName,
  emphasize,
}: TestimonialCardProps) => {
  const splitTextEmphasis = () => {
    if (!emphasize || !reviewText.includes(emphasize)) return [reviewText, '', '']; // Return full text if emphasize is missing or not found

    const startIndex = reviewText.indexOf(emphasize);
    const endIndex = startIndex + emphasize.length;

    return [
      reviewText.substring(0, startIndex),
      reviewText.substring(startIndex, endIndex),
      reviewText.substring(endIndex),
    ];
  };
  const [preEmphasis, emphasized, postEmphasis] = splitTextEmphasis();

  return (
    <Stack
      bg="sntBlue.primary"
      width={{ base: '326px', md: '357px' }}
      borderTopRadius="100px"
      overflow="hidden"
    >
      <Stack w="100%" h={{ base: '248px', md: '264px' }}>
        <Image
          source={{ uri: imageUrl }}
          resizeMode="cover"
          alt="Testimonial image"
          w="100%"
          h="100%"
        />
      </Stack>
      <VStack alignItems="center" mt={6} w="100%" px={4} mb={6}>
        <HStack space={1} justifyContent="center">
          {[...Array(starCount)].map((_, index) => (
            <Star key={index} fill="black" color="black" size="24px" />
          ))}
        </HStack>
        <VStack
          alignItems="center"
          mt={4}
          minH={{ base: '144px', md: '160px' }}
          w={{ base: '294px', md: '321px' }}
        >
          <Text textAlign="center" size="bodySmToMd" fontWeight="bold" fontFamily="secondary">
            {preEmphasis}
            <Text
              fontSize={{ base: 'body.md', lg: 'body.lg' }}
              lineHeight={{ base: 'body.md', lg: 'body.lg' }}
            >
              {emphasized}
            </Text>
            {postEmphasis}
          </Text>
        </VStack>
        <VStack alignItems="center" mt={4}>
          <Text textAlign="center" size="bodySmToMd" fontWeight="bold">
            {author}
          </Text>
          <Text textAlign="center" size="tinyToBodySm" fontWeight="bold">
            {`(${petName}'S HUMAN)`}
          </Text>
        </VStack>
      </VStack>
    </Stack>
  );
};
