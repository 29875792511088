import { Divider, Text } from 'native-base';

import { PetGender } from '@/api';
import { VerticalPortalPopUp } from '@/components/Elements';
import { HOW_TO_CONTACT_SUPPORT_URL } from '@/constants';

interface DoubleCheckPortionsModalProps {
  isOpen: boolean;
  petName: string;
  petGender: string;
  onClose: () => void;
}

export const DoubleCheckPortionsModal = ({
  isOpen,
  petName,
  petGender,
  onClose,
}: DoubleCheckPortionsModalProps) => {
  const headingText = `${petName}'s Profile Has Been Updated`;
  const hisOrHer = petGender === PetGender.FEMALE ? 'her' : 'his';
  return (
    <VerticalPortalPopUp
      isOpen={isOpen}
      heading={headingText}
      headingProps={{ mx: 8 }}
      body={
        <>
          <Divider bg="gallery" />
          <Text size="bodySmToMd" fontFamily="secondary" fontWeight="medium" textAlign="center">
            If you’d like to double-check that {hisOrHer} current portions are still the best fit,
            we’re happy to help!
          </Text>
          <Divider bg="gallery" />
        </>
      }
      primaryCtaText="GOT IT!"
      secondaryCtaText="Speak with a Specialist"
      onPrimaryPress={onClose}
      onSecondaryPress={() => {
        window.open(HOW_TO_CONTACT_SUPPORT_URL, '_blank');
      }}
      onClose={onClose}
      w={{ base: '100%', lg: '440px' }}
    />
  );
};
