import { datadogLogs } from '@datadog/browser-logs';
const { logger } = datadogLogs;

const configValidation = (key: string, envConfig: string | undefined) => {
  if (envConfig === undefined) {
    const error = `[ERROR] Missing required configuration ${key}`;
    console.error(error);
    logger.error(error);
    return '';
  }
  return envConfig;
};

const configValidationWithFallback = (
  key: string,
  envConfig: string | undefined,
  fallback: string
) => {
  if (envConfig === undefined) {
    const error = `[ERROR] Config ${key} is missing. Using fallback value: ${fallback}`;
    logger.warn(error);
    return fallback;
  }
  return envConfig;
};

// All required .env configurations should be added to this file
export const API_URL = configValidation('API_URL', process.env['API_URL']);
export const STRIPE_PUBLISHABLE_KEY = configValidation(
  'STRIPE_PUBLISHABLE_KEY',
  process.env['STRIPE_PUBLISHABLE_KEY']
);
export const TRACKING_URL = configValidation('TRACKING_URL', process.env['TRACKING_URL']);
export const ACCEPTED_EMAIL_DOMAINS = configValidation(
  'ACCEPTED_EMAIL_DOMAINS',
  process.env.ACCEPTED_EMAIL_DOMAINS
);
export const HAS_DATADOG = configValidation('HAS_DATADOG', process.env['HAS_DATADOG']);
export const ENVIRONMENT = configValidation('ENVIRONMENT', process.env['ENVIRONMENT']);
export const SEGMENT_API_KEY = configValidation('SEGMENT_API_KEY', process.env['SEGMENT_API_KEY']);

export const ENABLE_PET_PHOTO_UPLOAD = configValidation(
  'ENABLE_PET_PHOTO_UPLOAD',
  process.env['ENABLE_PET_PHOTO_UPLOAD']
);

export const BEST_SELLER_RECIPE_IDS = configValidation(
  'BEST_SELLER_RECIPE_IDS',
  process.env['BEST_SELLER_RECIPE_IDS']
);
export const IS_UNDER_MAINTENANCE = configValidation(
  'IS_UNDER_MAINTENANCE',
  process.env['IS_UNDER_MAINTENANCE']
);
export const CONFIG_BANNER_COPY = configValidation(
  'CONFIG_BANNER_COPY',
  process.env['CONFIG_BANNER_COPY']
);
export const CONFIG_BANNER_EXPIRY = configValidation(
  'CONFIG_BANNER_EXPIRY',
  process.env['CONFIG_BANNER_EXPIRY']
);

export const SHOW_DAILY_ESSENTIALS = configValidation(
  'SHOW_DAILY_ESSENTIALS',
  process.env['SHOW_DAILY_ESSENTIALS']
);
export const ANONYMOUS_USER_COOKIE_TTL: number = 270 * 24 * 60 * 60 * 1000; // 9 months

export const MULTI_DOG_FULFILLMENTS_FEATURE_FLAG_NAME = configValidation(
  'MULTI_DOG_FULFILLMENTS_FEATURE_FLAG_NAME',
  process.env['MULTI_DOG_FULFILLMENTS_FEATURE_FLAG_NAME']
);

export const ASSETS_URL = configValidationWithFallback(
  'ASSETS_URL',
  process.env['ASSETS_URL'],
  'https://assets.spotandtango.com'
);

export const ENABLE_UPDATE_DOG_PROFILE = configValidation(
  'ENABLE_UPDATE_DOG_PROFILE',
  process.env['ENABLE_UPDATE_DOG_PROFILE']
);

export const IS_BUFFER_BAG_LAUNCHED = configValidation(
  'IS_BUFFER_BAG_LAUNCHED',
  process.env['IS_BUFFER_BAG_LAUNCHED']
);
