import { Button, Stack, Text } from 'native-base';
import { useState } from 'react';

import { FormSubmitButton } from '../../../FormSubmitButton';
import { RecipeSelectForm } from '../RecipeSelectForm';
import { RetentionDiscountModal } from '../RetentionDiscountModal';
import { SpeakWithSpecialistModal } from '../SpeakWithSpecialistModal';
import { useAnswerSurveyAndCancelPlan, useInterventionEligibility } from '../hooks';
import { useRecipeSelectForm } from '../useRecipeSelectForm';
import { StopPlanSuccessModal } from './StopPlanSuccessModal';
import { Intervention } from './types';

import {
  DiscountExclusiveType,
  ProductType,
  RecipeType,
  usePetPlansPrices,
  useUpdateRecipesWithExclusiveDiscount,
} from '@/api';
import { ToastType, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';

export const TryNewRecipe = ({ navigation, route }: ProtectedScreenProps<'TryNewRecipe'>) => {
  const account = useAccount();
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();
  const [speakWithSpecialistModalOpen, setSpeakWithSpecialistModalOpen] = useState(false);
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const pet = account.pets.find((pet) => pet.id === route.params.petId);
  const { mutateAsync: updateRecipesWithExclusiveDiscount, isLoading: isLoadingRecipesUpdate } =
    useUpdateRecipesWithExclusiveDiscount();
  const { interventions, isLoading: isLoadingInterventionEligiblity } = useInterventionEligibility(
    pet?.id
  );
  const { setValue, getValues, watch, reset } = useRecipeSelectForm();
  const selectedPlanRecipeIds = watch('recipeIds');
  const { data: plansPrices, isLoading: isLoadingPlansPrices } = usePetPlansPrices({
    planSize: pet?.pet_plan.plan_size,
    previewRetentionDiscount: true,
  });

  const seletedPlanRecipeBundledIds = selectedPlanRecipeIds.sort().join('-');
  const selectedPlanPrice = plansPrices?.discountedPrices[seletedPlanRecipeBundledIds];

  const petMealProduct = pet?.pet_plan.products.find(
    (product) => product.product_type === ProductType.MEAL
  );
  const recipeType = petMealProduct?.recipes[0].type as RecipeType.FRESH | RecipeType.UNKIBBLE;

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    setSpeakWithSpecialistModalOpen(false);
    navigation.navigate('Account');
  };

  const onPressCancel = () => {
    if (
      interventions.includes(Intervention.SpeakWithSpecialist) &&
      route.params.secondaryIntervention !== Intervention.TenPercentDiscount
    ) {
      setSpeakWithSpecialistModalOpen(true);
    } else if (
      interventions.includes(Intervention.TenPercentDiscount) &&
      route.params.secondaryIntervention === Intervention.TenPercentDiscount
    ) {
      setRetentionDiscountModalOpen(true);
    } else {
      doCancelPlan();
    }
  };

  const onPressSaveChanges = async () => {
    if (!pet || !account) {
      return;
    }

    const { discount_code, discount_id, previous_recipe_ids, new_recipe_ids } =
      await updateRecipesWithExclusiveDiscount({
        petPlanId: pet?.pet_plan.id,
        data: {
          discount_type: DiscountExclusiveType.WINBACK_30_PERCENT,
          recipes: selectedPlanRecipeIds,
        },
      });

    if (discount_code && discount_id && previous_recipe_ids && new_recipe_ids) {
      segment.trackEvent('Cancellation Flow - Switched Recipe', {
        account_id: account.id,
        email: account.email,
        cancellation_reason: route.params.primaryAnswer,
        cancellation_reason_secondary: route.params.secondaryAnswer,
        cancellation_reason_comment: route.params.customerComment,
        pet_id: pet.id,
        discount_id,
        discount_code,
        previous_recipe_ids,
        new_recipe_ids,
      });
    }

    navigation.navigate('Orders', { petId: pet.id });
    displayToast({
      message: `${pet.name}'s recipes have been updated and ${discount_code} promo code has been applied to your order.`,
      type: ToastType.Success,
    });
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.primaryAnswer,
      secondaryAnswer: route.params.secondaryAnswer,
      customerComment: route.params.customerComment,
    });
    setSpeakWithSpecialistModalOpen(false);
    setRetentionDiscountModalOpen(false);
    setStopPlanSuccessModalOpen(true);
  };

  if (!pet || !recipeType || isLoadingPlansPrices || isLoadingInterventionEligiblity) {
    return null;
  }

  return (
    <>
      <Stack alignItems="center" mb={10} px={{ base: 4, lg: undefined }}>
        <Text
          size="bodySmToMd"
          fontFamily="secondary"
          fontWeight="medium"
          textAlign="center"
          mb={{ base: 2, lg: 4 }}
        >
          We want mealtime to bring excitement and tail wags! But we get it — dogs, like us, like
          what they like!
        </Text>
        <Stack maxW={{ lg: recipeType === RecipeType.UNKIBBLE ? '800px' : undefined }} w="100%">
          <RecipeSelectForm
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            pet={pet}
            recipeType={recipeType}
            pricingUnit="week*"
            autoSelectRecipes={false}
            retentionPricing
            hideBanners
            navigation={navigation}
          />
        </Stack>
        <Text
          size="bodySmToMd"
          color="sntGrey.primary"
          fontWeight="medium"
          mt={{ base: 1, md: 3 }}
          textAlign="center"
        >
          * This price applies to your next order. Afterwards, your plan will be billed at regular
          price.
        </Text>
        <Text
          size="bodySmToMd"
          color="sntGrey.primary"
          fontWeight="medium"
          mt={{ base: 2, md: 3 }}
          textAlign="center"
        >
          Any questions? Call or text us at (718) 514-6292. Our team is here to help.
        </Text>
      </Stack>
      <Stack variant="stickyFooter" alignItems="center" px={4}>
        {selectedPlanPrice ? (
          <Text fontWeight="medium" size="bodyMdToLg" paddingBottom={{ base: 4, md: 6 }}>
            {`${pet.name}'s Price: $${selectedPlanPrice}/week*`}
          </Text>
        ) : null}

        <FormSubmitButton
          onPress={onPressSaveChanges}
          isLoading={isLoadingRecipesUpdate}
          w={{ base: '100%', md: '290px' }}
          h={{ base: '52px', md: '56px' }}
          px={{ base: 4, md: undefined }}
          isDisabled={selectedPlanRecipeIds.length === 0}
          mb={{ base: 4, lg: 6 }}
        >
          SAVE CHANGES
        </FormSubmitButton>
        <Button
          variant="underlineMini"
          onPress={onPressCancel}
          isDisabled={isSubmittingCancel || isLoadingRecipesUpdate}
        >
          Continue to cancellation
        </Button>
      </Stack>
      <SpeakWithSpecialistModal
        isOpen={speakWithSpecialistModalOpen}
        onClose={() => setSpeakWithSpecialistModalOpen(false)}
        onSecondaryPress={doCancelPlan}
        petId={pet.id}
        cancellationReason={route.params.primaryAnswer}
        cancellationReasonSecondary={route.params.secondaryAnswer}
        cancellationReasonComment={route.params.customerComment}
      />
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={navigateToAccount}
        onConfirm={navigateToAccount}
      />
      <RetentionDiscountModal
        isOpen={retentionDiscountModalOpen}
        onClose={() => setRetentionDiscountModalOpen(false)}
        petId={pet.id}
        onSecondaryPress={doCancelPlan}
        discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
        cancellationReason={route.params.primaryAnswer}
        cancellationReasonSecondary={route.params.secondaryAnswer}
        cancellationReasonComment={route.params.customerComment}
      />
    </>
  );
};
