import { datadogLogs } from '@datadog/browser-logs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Stack, Heading } from 'native-base';
import { useEffect } from 'react';

import { BuildPlan, RecipeType, usePlansBySize } from '@/api';
import { LoadingSpinner, ProductCard } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import { ProtectedStackParamList } from '@/types';
import { cheapestPlanByProduct } from '@/utils';

const { logger } = datadogLogs;

type FoodProductLine = RecipeType.FRESH | RecipeType.UNKIBBLE;

export const DogSelectFoodType = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'DogSelectFoodType'>) => {
  const account = useAccount();
  const { pets } = account;
  const pet = pets.find((pet) => pet.id === route.params?.petId);
  const { data: availablePlans, isSuccess: isSuccessPlansBySize } = usePlansBySize(
    pet && {
      planSize: pet?.pet_plan.plan_size,
      account_id: account.id,
    }
  );

  const setPetIdFromLocalStorage = (): void => {
    const requestedUrl = localStorage.getItem('requestedUrl');
    // Logged out flow, pop from localStorage and use as petId.
    if (!requestedUrl) {
      return;
    }

    localStorage.removeItem('requestedUrl');
    const url = new URL(requestedUrl);
    if (!route.params?.petId && url.searchParams.has('petId')) {
      const petId = url.searchParams.get('petId');
      if (petId) {
        const foundPet = pets.find((pet) => pet.id === petId);
        if (foundPet) {
          navigation.setParams({ petId });
        } else {
          logger.warn('Deeplink Failure: requestedUrl petId not found in pets', { petId });
        }
      }
    }
  };

  useEffect(() => {
    // If there isnt a pet from route params, check for deeplink from localStorage.
    if (!pet) {
      setPetIdFromLocalStorage();
      if (!route.params?.petId || !pets.find((pet) => pet.id === route.params?.petId)) {
        logger.warn(
          `Deeplink Failure: requestedUrl petId not found in pets ${route.params?.petId}`
        );
      }
    }
  }, [account, pet]);

  useEffect(() => {
    if (pet) {
      navigation.setOptions({ title: `Change ${pet.name}'s Food Type` });
    }
  }, [account, pet]);

  const isReady = pet && isSuccessPlansBySize && availablePlans;

  if (!isReady || !pet) {
    return <LoadingSpinner accessLabel="Change Food Type Page" />;
  }

  const activeRecipeOptions = Object.values(availablePlans).filter(
    (meal) => meal?.subscription?.price?.pricing_model.status !== 'DISCONTINUED'
  );
  const cheapestRecipeOptions = cheapestPlanByProduct(
    activeRecipeOptions,
    'subscription',
    'price_per_week'
  );

  const recipeOptions = [
    cheapestRecipeOptions[RecipeType.UNKIBBLE] as BuildPlan,
    cheapestRecipeOptions[RecipeType.FRESH] as BuildPlan,
  ].map(({ subscription }) => ({
    id: subscription.product.recipes[0].id,
    recipes: subscription.product.recipes,
    pricePerWeek: subscription.price.price_per_week,
    discountedPricePerWeek: subscription.price.discounted_price_per_week,
  }));

  const selectedFoodType = pet.pet_plan.recipe_data.some(
    (recipe) => recipe.type === RecipeType.FRESH
  )
    ? RecipeType.FRESH
    : RecipeType.UNKIBBLE;

  const onSelect = (foodType: FoodProductLine) => {
    navigation.navigate('DogSelectRecipe', {
      successRedirect: route.params?.successRedirect || 'Dogs',
      foodType,
      petId: pet.id,
      deeplinked: false,
    });
  };

  return (
    <Stack alignItems="center" w="100%" p="24px" paddingTop="0px">
      <Heading paddingBottom="24px" variant="subtitle">
        Two incredible choices, with nothing artificial in sight. You really can't go wrong!
      </Heading>
      <Stack direction={{ base: 'column', lg: 'row' }} space={6} justifyContent="center">
        {recipeOptions.map((option) => (
          <Stack key={option.id}>
            <ProductCard
              recipe={option.recipes[0]}
              heading={option.recipes[0].type === RecipeType.UNKIBBLE ? 'UnKibble™' : 'Fresh'}
              pricing={
                option.discountedPricePerWeek ? option.discountedPricePerWeek : option.pricePerWeek
              }
              infoPills={[
                option.recipes[0].type === RecipeType.UNKIBBLE
                  ? 'Most Popular'
                  : 'Original Recipes',
              ]}
              pricingUnit="week"
              onPress={() => onSelect?.(option.recipes[0].type as FoodProductLine)}
              selected={selectedFoodType === option.recipes[0].type}
              pricingPrefix="From"
              showInclusions={false}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
