import { useNavigation } from '@react-navigation/native';
import { Stack, Heading, Text, HStack, Badge, Divider, VStack } from 'native-base';
import React, { useCallback, useMemo } from 'react';

import { useBufferBags } from '@/api/services/bufferBags/useBufferBags';
import { OrderProduct } from '@/api/types/accountServices';
import { Avatar, LineItemPlanCardProps } from '@/components/Elements';

export interface LineItemPlanCardBufferProps {
  dogPlans: LineItemPlanCardProps['content'][];
  orderId: string;
}

const onEditBufferBagPress = (orderId: string, navigation: any) => {
  navigation.navigate('BufferBags', {
    orderId,
    editBufferBag: true,
  });
};

export const LineItemPlanCardBuffer = ({ dogPlans, orderId }: LineItemPlanCardBufferProps) => {
  const { data: bufferBagList } = useBufferBags(orderId);
  const navigation = useNavigation();

  const handleEditBufferBag = useCallback(() => {
    onEditBufferBagPress(orderId, navigation);
  }, [navigation, orderId]);

  const petsWithBufferBags = useMemo(
    () =>
      dogPlans
        .map((dogPlan) => {
          const bufferBagInfo = bufferBagList?.find(
            (buffer_bag_info) => buffer_bag_info.pet_id === dogPlan.dog.id
          );
          const numberOfDaysPerBag = bufferBagInfo?.buffer_bag_extra_days || 0;

          const totalDays = dogPlan.bufferBags.reduce(
            (accumulator: number, currentBag: { quantity: number }) => {
              return accumulator + Number(currentBag.quantity) * Number(numberOfDaysPerBag);
            },
            0
          );

          const totalValue = dogPlan.bufferBags
            .reduce((accumulator: number, currentBag: OrderProduct) => {
              return accumulator + Number(currentBag.unit_price) * Number(currentBag.quantity);
            }, 0)
            .toFixed(2);

          const recipeNames = dogPlan.bufferBags
            .filter((bag: { quantity: number }) => bag.quantity > 0)
            .map((bag: { recipes: { name: string }[] }) => bag.recipes[0].name);

          const overallRecipe = recipeNames.join(', ');

          return {
            dogPlan,
            totalDays,
            totalValue,
            overallRecipe,
          };
        })
        .filter((item) => item.totalDays > 0)
        .sort((a, b) => a.dogPlan.dog.name.localeCompare(b.dogPlan.dog.name)),
    [dogPlans, bufferBagList]
  );
  const CustomDivider = () => (
    <Divider
      bg="gallery"
      w="85%"
      alignSelf="center"
      mt={{ base: 4, lg: 6 }}
      mb={{ base: 4, lg: 6 }}
    />
  );

  return (
    <Stack space={1}>
      <HStack justifyContent="space-between">
        <Heading size="bodyMdToLg" fontWeight="bold">
          Extra Meals
        </Heading>
        <Text size="bodySmToMd" fontWeight="medium" underline onPress={handleEditBufferBag}>
          Edit
        </Text>
      </HStack>
      <Stack space={0} divider={<CustomDivider />}>
        {petsWithBufferBags.map(({ dogPlan, totalDays, totalValue, overallRecipe }) => {
          return (
            <Stack key={dogPlan.dog.id}>
              <HStack py={2} space={{ base: 2, lg: 4 }}>
                <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
                  <Avatar
                    petGender={dogPlan.dog?.gender}
                    imageSize={{ base: '56px', lg: 20 }}
                    petId={dogPlan.dog?.id}
                  />
                </Stack>

                <Stack flex={3}>
                  <HStack justifyContent="space-between" alignItems="center">
                    <Heading size="bodyMdToLg" fontWeight="bold">
                      {dogPlan.dog?.name}
                    </Heading>

                    <Badge variant="smallInfoPillSecondary">{`$${totalValue}`}</Badge>
                  </HStack>
                  <VStack space={1}>
                    <Text size="caption" fontWeight="medium">
                      {totalDays} days of extra meals
                    </Text>
                    <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
                      {overallRecipe}
                    </Text>
                  </VStack>
                </Stack>
              </HStack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
