import { Order, OrderProduct, OrderType, OrderProductType } from '@/api/types';
import { axios } from '@/lib/axios';

type PutProductPayload = {
  accountId: string;
  orderId: Order['id'];
  products: ApiOrderProduct[];
  discountCodes?: string[];
  orderType?: OrderType;
};

type ApiOrderProduct = {
  pet_plan?: string;
  product_code: string;
  quantity: number;
  type: OrderProductType;
};

export const orderProductToApiPayload = (product: OrderProduct) => {
  return {
    pet_plan: product?.pet_plan?.id,
    product_code: product?.code,
    quantity: product?.quantity,
    type: product?.type,
  };
};

export const putOrderProducts = ({
  accountId,
  products,
  discountCodes,
  orderType,
  orderId,
}: PutProductPayload) => {
  const currentPathName = window.location.pathname;
  return axios
    .put(`orders/${orderId}`, {
      account: accountId,
      order_products: products,
      discount_codes: discountCodes,
      order_type: orderType,
      order_id: orderId,
      page_path: currentPathName,
    })
    .then((response) => response.data);
};
