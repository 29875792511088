import {
  getFocusedRouteNameFromRoute,
  NavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { HStack, IconButton, Pressable, Stack, Text } from 'native-base';

import {
  ErrorBanner,
  Navigation,
  Logo,
  ReferralBtn,
  GetStartedBanner,
  ImpersonateBanner,
} from './subcomponents';

import { PetStatus } from '@/api/types';
import { Image, VToHStack } from '@/components/Elements';
import { Account } from '@/components/Icons';
import { ASSETS_URL } from '@/config';
import { WTP_URL } from '@/constants';
import {
  useAccount,
  useAuth,
  useIsMobile,
  useOrdersOnHold,
  usePaymentError,
  useShouldShowPortalHead,
} from '@/hooks';
import { ProtectedStackParamList } from '@/types';

export const PortalHead = () => {
  const account = useAccount();
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const isMobile = useIsMobile();
  const { logout } = useAuth();
  const route = useRoute();
  const currentActiveRoute = getFocusedRouteNameFromRoute(route);
  const showPortalHead = useShouldShowPortalHead();
  const impersonateToken = localStorage.getItem('impersonate_token');
  const dogCount = account?.pets?.filter((pet) => pet.status === PetStatus.ACTIVE).length;

  const onLogoutPress = async () => {
    await logout();
    return Linking.openURL('https://spotandtango.com/');
  };

  const paymentMethodError = usePaymentError();
  const ordersOnHold = useOrdersOnHold();

  return showPortalHead ? (
    <Stack
      alignItems="center"
      w="100%"
      borderBottomColor={isMobile ? 'gallery' : 'black'}
      borderBottomWidth={account ? 1 : 0}
    >
      <VToHStack
        w="100%"
        maxW="1272px"
        pt={3}
        px={{ base: 0, lg: 4 }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        flexDirection={{ lg: 'row-reverse' }}
      >
        <Logo />

        <HStack
          justifyContent={{ base: 'end', lg: 'center' }}
          alignItems="center"
          space={{ base: 2, lg: 3 }}
          pb={4}
          pr={{ base: 4, lg: 0 }}
          w={{ base: '100%', lg: 'fit-content' }}
          borderBottomWidth={isMobile ? 1 : 0}
        >
          {account && (
            <Pressable
              mr="26px"
              display={{ base: 'none', lg: 'inline-block' }}
              onPress={onLogoutPress}
            >
              <Text fontWeight="bold">LOGOUT</Text>
            </Pressable>
          )}

          <ReferralBtn isReferral />

          {account && (
            <Stack
              bg={currentActiveRoute === 'Account' ? 'sntBlue.primary75' : 'transparent'}
              borderRadius="100%"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onPress={() => navigation.navigate('Account')}
                px={{ base: 3, lg: '13px' }}
                py={{ base: '6px', lg: '11px' }}
                icon={<Account w={5} h={{ base: 8, lg: 6 }} color="white" />}
              />
            </Stack>
          )}

          {account ? null : (
            <Pressable
              onPress={() => Linking.openURL(WTP_URL)}
              ml={{ base: 0, lg: 6 }}
              display={{ base: 'none', lg: 'flex' }}
            >
              <Image
                source={{ uri: `${ASSETS_URL}/wtp-logo.png` }}
                alt="Text Logo: What the Pup by Spot & Tango"
                w="136px"
                h="48px"
              />
            </Pressable>
          )}
        </HStack>
        <Navigation isLoggedIn={!!account} />
      </VToHStack>
      {account ? null : <GetStartedBanner w="100%" />}
      {account && (
        <Stack w="100%" alignItems="center">
          {impersonateToken && <ImpersonateBanner />}
          {(paymentMethodError || !!ordersOnHold.length) && (
            <ErrorBanner paymentMethodError={paymentMethodError ?? 'error'} dogCount={dogCount} />
          )}
        </Stack>
      )}
    </Stack>
  ) : null;
};
