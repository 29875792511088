export const addQueryParams = (queryParam: string, value: string): void => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(queryParam, value);
  window.history.pushState(null, '', currentUrl.toString());
};

export const removeQueryParams = (queryParam: string): void => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete(queryParam);
  window.history.pushState(null, '', currentUrl.toString());
};

export const getQueryParams = (queryParam: string): string | null => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.searchParams.get(queryParam);
};
