export const getPupGumSize = (weight: number) => {
  const weightToSize = {
    15: 'PUPGUM-S',
    40: 'PUPGUM-M',
  };
  for (const [weightBreakpoint, recipeId] of Object.entries(weightToSize)) {
    if (weight <= Number(weightBreakpoint)) {
      return recipeId;
    }
  }
  return 'PUPGUM-L';
};
