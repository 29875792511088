import { useFocusEffect } from '@react-navigation/native';
import { Stack, Heading, Text, Divider, HStack, Badge } from 'native-base';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AddNewDogPlanFoodSection } from './AddNewDogPlanFoodSection';

import { getBuildPlans, useAddNewDogPreview } from '@/api';
import {
  displayToast,
  EditableCard,
  LoadingSpinner,
  ToastType,
  ExpandChevronButton,
} from '@/components/Elements';
import { useAddNewDogContext } from '@/context';
import { useAccount } from '@/hooks';
import { formatPrice } from '@/utils';

export const AddNewDogPlanOrderSummary = () => {
  const account = useAccount();
  const { petData, petPlan, discountCode, snacks, supplements, savePetPlan, saveDiscountCode } =
    useAddNewDogContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    data: addNewDogPreview,
    isSuccess,
    refetch,
  } = useAddNewDogPreview({
    accountId: account.id,
    discountCode: discountCode ?? '',
    mealProductCode: petPlan?.subscription.product.code ?? '',
    addons: { ...snacks, ...supplements },
  });
  const previewOrder = useMemo(
    () =>
      addNewDogPreview
        ? {
            products: addNewDogPreview.products.map((product) => {
              return {
                name: product.recipes.map((recipe) => recipe.name).join(', '),
                type: product.recipes[0].type,
                price: product.unit_price,
                quantity: product.quantity,
              };
            }),
            total_price: addNewDogPreview.total.total_price,
            total_discounts: addNewDogPreview.total.total_discounts,
            total_tax: addNewDogPreview.total.total_tax,
            discount_codes: addNewDogPreview.discount_codes,
          }
        : undefined,
    [addNewDogPreview]
  );

  useEffect(() => {
    const applyDiscountAndFetchPlans = async () => {
      if (discountCode !== '' && addNewDogPreview) {
        let discountToApply = discountCode; // need this temp variable since discountCode won't immediately receive the update from saveDiscountCode
        if (addNewDogPreview?.discount_errors?.INVALID_DISCOUNT_CODE === undefined) {
          displayToast({
            message: `${discountCode} promo code has been applied to your order.`,
            type: ToastType.Success,
          });
        } else {
          displayToast({
            message: `${discountCode} promo code is invalid. Previous discounts have been removed.`,
            type: ToastType.Error,
          });
          discountToApply = '';
          saveDiscountCode('');
        }
        const updatedPlanData = await getBuildPlans(
          {
            plan_size: petPlan?.plan_size,
            recipes: petData.recipes,
            discounts: discountToApply ? [discountToApply] : [],
            account_id: account.id,
          },
          true
        );
        savePetPlan(updatedPlanData);
      }
    };

    applyDiscountAndFetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id, addNewDogPreview, discountCode, petData.recipes, petPlan?.plan_size]);

  const mealProducts =
    previewOrder?.products.filter(
      (product) => product.type === 'UNKIBBLE' || product.type === 'FRESH'
    ) ?? [];
  const snackProducts = previewOrder?.products.filter((product) => product.type === 'SNACK') ?? [];
  const supplementProducts =
    previewOrder?.products.filter((product) => product.type === 'SUPPLEMENT') ?? [];

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch])
  );

  return (
    <EditableCard chevron={false} p={{ base: 4, lg: 6 }} onPress={() => setIsExpanded(!isExpanded)}>
      {isSuccess && previewOrder ? (
        <Stack w="100%" space={{ base: 4, lg: 6 }}>
          <HStack justifyContent="space-between" alignItems="center">
            <Heading size="bodyMlToTitleSm" fontWeight="bold">
              Order Summary
            </Heading>
            <HStack>
              <ExpandChevronButton
                isExpanded={isExpanded}
                onPress={() => setIsExpanded(!isExpanded)}
                borderWidth="0"
                p={{ base: '4.5px', lg: '6px' }}
                chevronSize={{ base: '9px', lg: '12px' }}
              />
            </HStack>
          </HStack>
          {isExpanded ? (
            <Stack space={{ base: 4, lg: 6 }}>
              <Stack space={{ base: 4, lg: 6 }}>
                <Heading size="bodyMdToLg" fontWeight="bold">
                  {petData.name}
                </Heading>
                <AddNewDogPlanFoodSection type="Meals" products={mealProducts} />
                {snackProducts?.length > 0 ? (
                  <AddNewDogPlanFoodSection type="Snacks" products={snackProducts} />
                ) : null}
                {supplementProducts?.length > 0 ? (
                  <AddNewDogPlanFoodSection type="Supplements" products={supplementProducts} />
                ) : null}
              </Stack>

              <Stack space={{ base: 2, lg: 4 }}>
                <HStack justifyContent="space-between" alignItems="center">
                  <Text size="bodyMdToLg">Discount</Text>
                  {previewOrder?.discount_codes?.length > 0 ? (
                    <Text size="bodyMdToLg">
                      <Badge variant="smallInfoPillSecondary">
                        {previewOrder.discount_codes[0]}
                      </Badge>{' '}
                      -${previewOrder.total_discounts}
                    </Text>
                  ) : (
                    <Text size="bodyMdToLg">-</Text>
                  )}
                </HStack>
                <HStack justifyContent="space-between">
                  <Text size="bodyMdToLg">Shipping</Text>
                  <Text size="bodyMdToLg">FREE</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text size="bodyMdToLg">Estimated Tax</Text>
                  <Text size="bodyMdToLg">{formatPrice(previewOrder.total_tax)}</Text>
                </HStack>
              </Stack>
              <Divider bg="gallery" />
            </Stack>
          ) : null}
          <Stack space={{ base: 2, lg: 4 }}>
            <HStack justifyContent="space-between">
              <Text size="bodyMdToLg" fontWeight={isExpanded ? 'bold' : 'medium'}>
                Total
              </Text>
              <Text size="bodyMdToLg" fontWeight={isExpanded ? 'bold' : 'medium'}>
                {formatPrice(previewOrder.total_price)}
              </Text>
            </HStack>
          </Stack>
        </Stack>
      ) : (
        <LoadingSpinner accessLabel="Add New Dog Plan Order Summary" />
      )}
    </EditableCard>
  );
};
