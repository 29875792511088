import { useBreakpointValue } from 'native-base';
import { ImageBackground, ImageBackgroundProps } from 'react-native';

interface CarouselImageProps extends ImageBackgroundProps {
  width?: string | number;
  height?: string | number;
  borderRadius?: number;
  borderBottomColor?: string;
  borderBottomWidth?: number;
  imageWidth?: { base: string | number; md: string | number };
  imageHeight?: { base: string | number; md: string | number };
}

export const CarouselImage = ({
  width = '100%',
  height = '100%',
  borderRadius = 10,
  borderBottomColor,
  borderBottomWidth,
  imageWidth,
  imageHeight,
  ...props
}: CarouselImageProps) => {
  const imageW = useBreakpointValue(imageWidth || { base: '100%', md: '100%' });
  const imageH = useBreakpointValue(imageHeight || { base: '100%', md: '100%' });
  return (
    <ImageBackground
      {...props}
      style={{
        width,
        height,
        overflow: 'hidden',
        borderRadius,
        borderBottomColor,
        borderBottomWidth,
      }}
      imageStyle={{
        borderRadius,
        resizeMode: 'contain',
        width: imageW,
        height: imageH,
      }}
    />
  );
};
