import { Button, HStack, IPressableProps, Stack, VStack, Text } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/config';

interface BannerWrapperProps extends IHStackProps {
  onButtonPress?: IPressableProps['onPress'];
}

export const BannerWrapper = ({ onButtonPress, children, ...props }: BannerWrapperProps) => {
  return (
    <HStack
      h="100%"
      w="100%"
      borderWidth={{ base: 3, lg: 4 }}
      borderColor="sntBlue.primary"
      overflow="hidden"
      justifyContent="space-between"
      alignItems={{ base: 'end', lg: 'center' }}
      pr={{ base: 4, md: 12 }}
      zIndex={1}
      {...props}
    >
      <VStack
        h="100%"
        bg="sntBlue.primary"
        space={{ base: 1, lg: 3, xl: 5 }}
        py={{ base: 2, lg: 3 }}
        pl={{ base: 4, lg: 6, xl: 10 }}
        zIndex={1}
      >
        <Text fontFamily="secondary" size="bodySmToTitleSm">
          Introducing...
        </Text>
        <VStack justifyContent="center" alignItems="center">
          <Text size="bodyMlToTitleSm" fontWeight={500} position="absolute" top={0} left={0}>
            THE
          </Text>
          <Image
            w={{ base: '100px', lg: '125px', xl: '157px' }}
            h={{ base: '58px', lg: '80px', xl: '97px' }}
            source={{ uri: `${ASSETS_URL}/logo-acronym.png` }}
          />
          <Text size="bodyMlToTitleSm" fontWeight={500} w="100%" textAlign="right">
            SHOP
          </Text>
        </VStack>
      </VStack>
      <Stack
        borderRadius="100%"
        w={{ base: '160px', lg: '225px', xl: '279px' }}
        h={{ base: '160px', lg: '225px', xl: '279px' }}
        bg="sntBlue.primary"
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        margin="auto"
        zIndex={0}
      />

      {children}

      <Button
        onPress={onButtonPress}
        variant="hugPrimary"
        position={{ base: 'absolute', lg: 'relative' }}
        right={{ base: 2, lg: 0 }}
        bottom={{ base: 2, lg: 0 }}
        _text={{ fontSize: { base: 'caption', lg: 'body.ml', xl: 'body.lg' } }}
        px={{ base: 4, lg: 6, xl: 9 }}
        zIndex={4}
      >
        SHOP NOW
      </Button>
    </HStack>
  );
};
