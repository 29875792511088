import { FontSource, useFonts as useExpoFonts } from 'expo-font';
import { useState, useEffect } from 'react';

import { ASSETS_URL } from '@/config';

type FontMap = Record<string, FontSource>;

const primaryFont: FontMap = {
  'MonumentGrotesk-Black': {
    uri: `${ASSETS_URL}/fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Black.otf`,
  },
  'MonumentGrotesk-Bold': {
    uri: `${ASSETS_URL}/fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Bold.otf`,
  },
  'MonumentGrotesk-Medium': {
    uri: `${ASSETS_URL}/fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Medium.otf`,
  },
  'MonumentGrotesk-Regular': {
    uri: `${ASSETS_URL}/fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Regular.otf`,
  },
  'MonumentGrotesk-Light': {
    uri: `${ASSETS_URL}/fonts/ABCMonumentGrotesk/ABCMonumentGrotesk-Light.otf`,
  },
};

const secondaryFont: FontMap = {
  'BradfordLL-Black': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Black.otf`,
  },
  'BradfordLL-Bold': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Bold.otf`,
  },
  'BradfordLL-Medium': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Medium.otf`,
  },
  'BradfordLL-Book': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Book.otf`,
  },
  'BradfordLL-Regular': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Regular.otf`,
  },
  'BradfordLL-Light': {
    uri: `${ASSETS_URL}/fonts/BradfordLL/BradfordLL-Light.otf`,
  },
};

const fontMap: FontMap = {
  ...primaryFont,
  ...secondaryFont,
};

export const useFonts = (): [boolean, Error | null] => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const [fonts, error] = useExpoFonts(fontMap);

  useEffect(() => {
    if (fonts) setFontsLoaded(true);
  }, [fonts]);

  return [fontsLoaded, error];
};
