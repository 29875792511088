import { IconButton } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
import { SVGNBIconProps } from '@/types';

export const CheckMark = ({ iconSize = '30px' }: { iconSize?: SVGNBIconProps['size'] }) => (
  <IconButton
    _icon={{
      size: 'xl',
    }}
    icon={
      <Image
        source={{ uri: `${ASSETS_URL}/pictos/Icons/check-illustration.png` }}
        size={iconSize}
        alt="checkmark"
      />
    }
    _hover={{ backgroundColor: 'transparent', color: 'black' }}
    p="0"
    display="flex"
    justifyContent="center"
    alignItems="center"
  />
);
