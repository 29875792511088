import { useQuery } from 'react-query';

import { BufferBag } from '@/api/types';
import { axios } from '@/lib/axios';

const getBufferBags = ({ orderId }: { orderId?: string }) => {
  return axios
    .get<BufferBag[]>('/buffer_bags', {
      params: {
        order_id: orderId,
      },
    })
    .then((res) => res.data);
};

export const useBufferBags = (orderId?: string) => {
  return useQuery(['BufferBags', orderId], {
    cacheTime: 0,
    queryFn: () => getBufferBags({ orderId }),
  });
};
