import { datadogLogs } from '@datadog/browser-logs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Center, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { FormSubmitButton } from '../FormSubmitButton';
import { RecipeSelectForm } from '../Home/ManageDogPlans/RecipeSelectForm';
import { useRecipeSelectForm } from '../Home/ManageDogPlans/useRecipeSelectForm';

import { Pet, RecipeType, useUpdatePetPlan } from '@/api';
import { displayToast, LoadingSpinner } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import { ProtectedStackParamList } from '@/types';
import { sendErrorReport } from '@/utils/analytics';

const { logger } = datadogLogs;

export const DogSelectRecipe = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'DogSelectRecipe'>) => {
  const account = useAccount();
  const { pets } = account;

  const { formState, setValue, getValues, watch, reset } = useRecipeSelectForm();
  const { isDirty } = formState;
  const selectedPlanRecipeIds = watch('recipeIds');

  const [pet, setPet] = useState<Pet>();
  const [selectedPetId, setSelectedPetId] = useState<string>();

  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();

  useEffect(() => {
    let petId: string | null = route.params?.petId || null;
    let foodType: string | null = route.params?.foodType || null;
    let recipe: string | null = route.params?.recipe || null;
    const deeplinked: boolean = route.params?.deeplinked;
    const requestedUrl = localStorage.getItem('requestedUrl');
    localStorage.removeItem('requestedUrl');
    if (requestedUrl) {
      localStorage.setItem('recipeSelectFormURL', requestedUrl);
      const url = new URL(requestedUrl);
      petId = url.searchParams.get('petId');
      foodType = url.searchParams.get('foodType');
      recipe = url.searchParams.get('recipe');
      const foundPet = pets.find((pet) => pet.id === petId);

      if (petId && foundPet && foodType && ['UNKIBBLE', 'FRESH'].includes(foodType)) {
        setSelectedPetId(petId);
        setPet(foundPet);
        // Required to handle signed out case
        const sameFoodType = foodType === foundPet?.pet_plan.recipe_data[0].type;
        if (!sameFoodType) {
          navigation.navigate('DogSelectFoodType', {
            successRedirect: route.params?.successRedirect || 'Dogs',
            petId,
          });
        } else {
          if (petId) {
            navigation.setParams({ petId });
          }
          if (foodType === RecipeType.UNKIBBLE || foodType === RecipeType.FRESH) {
            navigation.setParams({ foodType });
          }
          if (recipe) {
            navigation.setParams({ recipe });
          }
        }
      } else {
        logger.warn('Potential Deeplink failure:', { petId, foodType, recipe });
        navigation.navigate('DogSelectFoodType', {
          petId: route.params?.petId,
          successRedirect: route.params?.successRedirect || 'Dogs',
        });
      }
    }
    // lets get the requested pet from logged in case:
    else if (petId) {
      const requestedPet = pets.find((pet) => pet.id === petId);
      const sameFoodType = foodType === requestedPet?.pet_plan.recipe_data[0].type;
      // if were logged in and get a deeplink, if the food type is different, redirect to food type page, if we nav here normally should be good
      if (!sameFoodType && deeplinked) {
        navigation.navigate('DogSelectFoodType', {
          successRedirect: route.params?.successRedirect || 'Dogs',
          petId: route.params?.petId,
        });
      }
      setSelectedPetId(petId);
      navigation.setParams({ petId });
    } else {
      logger.warn('Potential Deeplink failure: Pet not found by id', { petId });
    }
  }, []);

  useEffect(() => {
    if (pet) {
      navigation.setOptions({ title: `Change ${pet.name}'s Recipes` });
    }
  }, [account, navigation, pet]);

  useEffect(() => {
    if (selectedPetId) {
      const foundPet = pets.find((pet) => pet.id === selectedPetId);
      setPet(foundPet);
      navigation.setParams({ petId: selectedPetId });
    }
  }, [selectedPetId, pets]);

  if (!pet || !route.params?.foodType) {
    return <LoadingSpinner accessLabel="Change Recipe Page" />;
  }

  const onSubmit = async () => {
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: { recipes: selectedPlanRecipeIds },
      });
      displayToast({
        message: `${pet.name}'s food type and recipes have been successfully updated.`,
      });
      navigation.navigate(route.params?.successRedirect || 'Dogs');
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <Stack alignItems="center" w="100%" px="16px" pb="24px">
      <Stack w="100%" justifyContent="space-between">
        <Stack mb={5}>
          <Center mb={2}>
            <Text size="bodySm">Choose one, or mix and match for more variety!</Text>
          </Center>
          <RecipeSelectForm
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            pet={pet}
            recipeType={route.params?.foodType}
            navigation={navigation}
          />
        </Stack>
        <Stack alignItems="center">
          <FormSubmitButton
            onPress={onSubmit}
            isLoading={isLoadingUpdatePetPlan}
            isDisabled={!isDirty || selectedPlanRecipeIds.length === 0}
          >
            Save Changes
          </FormSubmitButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
