import { Divider, HStack, Heading, Stack, Text } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import {
  formatPrice,
  getMealRecipeName,
  getWeeksOfFood,
  getReplacementOrSampleMealNameForSummary,
} from '@/utils';

export interface FoodSectionProps {
  products: OrderProduct[];
  type:
    | 'Meals'
    | 'Snacks'
    | 'One-Time Products'
    | 'Supplements'
    | 'Daily Essentials'
    | 'Buffer Bags';
}

export const FoodSection = ({ products, type }: FoodSectionProps) => {
  if (products.length === 0) {
    return null;
  }

  const getRecipeText = (orderProduct: OrderProduct) => {
    if (type === 'Meals') {
      const mealRecipeName = getMealRecipeName(orderProduct.recipes[0].type);
      const weeksOfFood = getWeeksOfFood(orderProduct.code);
      if (weeksOfFood) {
        return (
          ([ProductType.REPLACE, ProductType.SAMPLE].includes(orderProduct.product_type)
            ? `${orderProduct.quantity} x `
            : '') + `${mealRecipeName} ${weeksOfFood}`
        );
      }
      return mealRecipeName;
    }
    if ([ProductType.SCOOP, ProductType.MATERIAL].includes(orderProduct.product_type)) {
      return `${orderProduct.pet_plan ? `${orderProduct.pet_plan.pet_name}'s ` : ''}${
        orderProduct.name
      }`;
    }
    if (orderProduct.product_type === ProductType.MERCH) {
      return `${orderProduct.quantity} x ${orderProduct.name}`;
    }
    if ([ProductType.SAMPLE, ProductType.REPLACE].includes(orderProduct.product_type)) {
      return `${orderProduct.quantity} x  ${getReplacementOrSampleMealNameForSummary(
        orderProduct
      )}`;
    }
    if (
      orderProduct.product_type === ProductType.DC &&
      orderProduct.code === 'DC-WASTEBAGS-1ROLL-1T-FREE'
    ) {
      return `${orderProduct.quantity} x Waste Bags Single Roll`;
    }
    return `${orderProduct.quantity} x ${orderProduct.recipes
      .map((recipe) => recipe.name)
      .join(', ')}`;
  };

  return (
    <Stack space={{ base: 2, lg: 6 }} paddingBottom="16px">
      <Stack space={{ base: 2, lg: 4 }} w="100%" justifyContent="center" alignItems="start">
        {type !== 'One-Time Products' && (
          <HStack w="100%" justifyContent="space-between">
            <Heading size="bodyMdToLg" fontWeight="bold">
              {type}
            </Heading>
          </HStack>
        )}
        {products.map((product: OrderProduct, i) => {
          return (
            <Stack w="100%" space={{ base: 2, lg: 4 }} key={i}>
              <HStack w="100%" justifyContent="space-between">
                <Text fontWeight="medium" size="bodyMdToLg">
                  {getRecipeText(product)}
                </Text>
                {product?.price?.discounted_price &&
                Number(product.price.discounted_price) < Number(product.price.price) ? (
                  <HStack space={{ base: 2, lg: 4 }}>
                    <Text size="bodyMdToLg" textDecorationLine="line-through">
                      {formatPrice(Number(product.unit_price) * product.quantity)}
                    </Text>
                    <Text size="bodyMdToLg" fontWeight="bold">
                      {formatPrice(product.price.discounted_price)}
                    </Text>
                  </HStack>
                ) : (
                  <Text
                    size="bodyMdToLg"
                    fontWeight={type === 'One-Time Products' ? 'medium' : 'bold'}
                  >
                    {formatPrice(
                      Number(product.unit_price) * product.quantity,
                      type === 'One-Time Products' ? 'free' : 'dash'
                    )}
                  </Text>
                )}
              </HStack>
              {type === 'Meals' ? (
                <HStack w="100%" justifyContent="space-between">
                  <Text color="sntGrey.primary" size="bodySmToMd">
                    {product.recipes.map((recipe) => recipe.name).join(', ')}
                  </Text>
                </HStack>
              ) : null}
            </Stack>
          );
        })}
      </Stack>
      <Divider bg="gallery" mb={2} />
    </Stack>
  );
};
