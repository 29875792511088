import { HStack, IInputProps, Input, Text } from 'native-base';

export interface InputSideLabelProps extends IInputProps {
  label?: string;
  stackProps?: object;
}

export const InputSideLabel = ({ label, stackProps = {}, ...props }: InputSideLabelProps) => (
  <HStack space={3} w="100%" alignItems="center" justifyContent="flex-start" {...stackProps}>
    <Input flex={1} {...props} />
    <Text size="bodyMlToLg" fontWeight={700}>
      {label}
    </Text>
  </HStack>
);
