import { Pressable, Heading, Text, IPressableProps, VStack, Stack, HStack } from 'native-base';

import { BuildPlan, RecipeType } from '@/api';
import { Image } from '@/components/Elements';
import { Dog } from '@/types';
import { getRecipesBowlImageSrc } from '@/utils';

const selectedStyle = {
  bg: 'sntBlue.primary50:alpha.20',
  borderColor: 'black',
} as IPressableProps;

const notSelectedStyle = {
  borderColor: 'sntGrey.outline',
  _hover: {
    borderColor: 'black',
  },
} as IPressableProps;

interface FullTopperCardProps extends IPressableProps {
  planType: 'full' | 'topper';
  selected: boolean;
  foodType?: 'Unkibble' | 'Fresh';
  dog: Partial<Dog>;
  planData: BuildPlan;
  isTrial?: boolean;
}

export const FullTopperCard = ({
  planType,
  selected,
  foodType = 'Fresh',
  dog,
  planData,
  isTrial = true,
  onPress,
  ...props
}: FullTopperCardProps) => {
  const fullPrice = planData.trial?.price?.price_per_week
    ? (+planData.trial?.price?.price_per_week).toFixed(2)
    : null;
  const discountedPrice = planData.trial?.price?.discounted_price_per_week
    ? (+planData.trial?.price?.discounted_price_per_week).toFixed(2)
    : null;
  const recipeBowlImageSrc = getRecipesBowlImageSrc(
    (dog.recipes || []).slice(0, 1),
    RecipeType.FRESH,
    planType === 'topper'
  );

  return (
    <VStack flex={1} h="100%" w="100%" display={{ base: 'block', md: 'flex' }}>
      <Pressable
        borderRadius={0}
        justifyContent="space-between"
        maxW={{ base: '100%', md: '258px' }}
        minW={{ lg: '258px' }}
        minH={{ lg: '376px' }}
        flex={1}
        borderWidth={{ base: '2px', md: '3px' }}
        py="16px"
        {...(selected ? selectedStyle : notSelectedStyle)}
        onPress={onPress}
        {...props}
      >
        <Stack
          flexDirection={{ base: 'row', md: 'column' }}
          justifyContent="flex-start"
          alignItems="flex-start"
          w="100%"
          h={{ base: 'auto', md: '100%' }}
        >
          <HStack
            w={{ base: 'auto', md: '100%' }}
            justifyContent="center"
            alignSelf={{ base: 'center', md: 'auto' }}
            px={{ base: '16px', md: 0 }}
            py={{ base: '0px', md: '8px' }}
          >
            <Image
              size={{ base: '100px', lg: '140px' }}
              alignSelf="center"
              source={{ uri: recipeBowlImageSrc }}
              alt=""
              resizeMode="contain"
            />
          </HStack>
          <Stack
            justifyContent={{ base: 'flex-start', md: 'center' }}
            alignItems={{ base: 'flex-start' }}
            px={{ base: '8px', md: '12px' }}
            flex={1}
          >
            <Heading
              alignSelf={{ base: 'flex-start', md: 'center' }}
              fontWeight="bold"
              size="bodyMlToTitleSm"
              mb={{ base: '8px', md: '16px' }}
            >
              {`${planType === 'full' ? 'Recommended' : 'Topper'} Plan`}
            </Heading>
            <Stack flexDirection={{ base: 'column', md: 'row' }} flexWrap="wrap" flexGrow={1}>
              <Text
                color="black"
                fontFamily="secondary"
                fontWeight="medium"
                size="captionToBodyMd"
                textAlign={{ base: 'left', md: 'center' }}
              >
                {planType === 'full'
                  ? `Feed ${dog.name} our recommended serving size of ${planData.calories_per_day} calories per day, for all the health benefits of Spot & Tango`
                  : `Mix Spot & Tango with ${dog.name}’s regular food to get a boost of health benefits at a lower price`}
              </Text>
              <VStack
                alignItems={{ base: 'flex-start', md: 'center' }}
                alignSelf={{ base: 'flex-start', md: 'flex-end' }}
                flex={1}
              >
                <HStack alignItems="center" my={{ base: '8px', md: 0 }}>
                  {isTrial && (
                    <Text color="black" size="bodySmToMd" fontWeight="medium">
                      Try for{' '}
                    </Text>
                  )}
                  <Text color="black" fontWeight="bold" size="bodyMdToLg">
                    ${discountedPrice ? discountedPrice : fullPrice}
                  </Text>
                  <Text color="black" fontWeight="bold" size="bodySmToMd">
                    {' '}
                    /week
                  </Text>
                </HStack>

                {discountedPrice ? (
                  <Stack>
                    <Text
                      color="black"
                      size="tinyToCaption"
                      fontWeight="medium"
                    >{`reg $${fullPrice} /week`}</Text>
                  </Stack>
                ) : null}
              </VStack>
            </Stack>
          </Stack>
        </Stack>
      </Pressable>
    </VStack>
  );
};
