import { ITextProps } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { FieldValues, Path } from 'react-hook-form';

import { LabeledFormControl, LabeledFormControlProps } from '../LabeledFormControl';
import { OptionSelector } from '../OptionSelector';

export interface OptionType<TValue = string, TLabel = string> {
  value: TValue;
  label: TLabel;
}

export interface LabeledOptionSelectorProps<
  TOption extends OptionType,
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledFormControlProps<TFieldValues, TName>, 'children'> {
  options?: TOption[];
  textStyling?: ITextProps;
  props?: IHStackProps;
}

/**
 * Generic labelled input element for react-hook-form forms
 */
export const LabeledOptionSelector = <
  TOption extends OptionType,
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  options = [],
  control,
  name,
  label,
  textStyling = {},
  props = {},
  ...formControlProps
}: LabeledOptionSelectorProps<TOption, TFieldValues, TName>) => (
  <LabeledFormControl control={control} name={name} label={label} {...formControlProps}>
    {({ field: { onChange, value } }) => (
      <OptionSelector
        value={options.find((op) => op.value === value)}
        options={options}
        onSelect={(option) => onChange(option?.value)}
        textStyling={textStyling}
        {...props}
      />
    )}
  </LabeledFormControl>
);
