import { Divider, Heading, HStack, Stack, Text } from 'native-base';

import { PetGender } from '@/api';
import { VerticalPortalPopUp } from '@/components/Elements';
import { RightArrow } from '@/components/Icons';

interface ReviewPlanSizeModalProps {
  isOpen: boolean;
  petName: string;
  petGender: string;
  isTopper: boolean;
  planSize: { recommended: number; current: number };
  weeklyPrice: { recommended: string; current: string };
  increasingOrDecreasing?: string;
  petNextPreviewOrderDate?: string;
  onPrimaryPress: () => void;
  onSecondaryPress: () => void;
  onClose: () => void;
}

export const ReviewPlanSizeModal = ({
  isOpen,
  petName,
  petGender,
  isTopper,
  planSize,
  weeklyPrice,
  increasingOrDecreasing,
  petNextPreviewOrderDate,
  onPrimaryPress,
  onSecondaryPress,
  onClose,
}: ReviewPlanSizeModalProps) => {
  const headingText = `Review ${petName}'s New Recommended Meal Plan`;
  const subHeadingText = increasingOrDecreasing
    ? `Based on the updates you made to ${petName}'s profile, we recommend ${increasingOrDecreasing} ${
        petGender === PetGender.FEMALE ? 'her' : 'his'
      } daily portions.`
    : null;

  const PopUpBody = (
    <Stack space={{ base: '16px', lg: '24px' }}>
      <Divider bg="gallery" />
      <HStack
        mx="auto"
        width="100%"
        alignItems="center"
        justifyContent="center"
        space="8px"
        textAlign="center"
      >
        <Stack space={{ base: '8px', lg: '16px' }} flex={1}>
          <Heading fontWeight="bold" size="bodyMdToLg">
            Current
          </Heading>
          <Stack
            space={{ base: '8px', lg: '16px' }}
            p="24px"
            borderColor="sntGrey.primary"
            borderWidth="1px"
          >
            <Text fontWeight="bold" size="bodyMlToTitleSm">
              {planSize.current} Calories
            </Text>
            <Text fontWeight="bold" size="bodyMdToLg">
              ${weeklyPrice.current}/wk
            </Text>
            <Text fontWeight="medium" size="tinyToCaption" color="sntGrey.primary">
              {isTopper ? 'Mixing S&T' : 'Feeding 100% S&T'}
            </Text>
          </Stack>
        </Stack>
        <RightArrow mt={{ base: '28px', lg: '32px' }} color="black" size="24px" />
        <Stack space={{ base: '8px', lg: '16px' }} flex={1}>
          <Heading fontWeight="bold" size="bodyMdToLg">
            Recommended
          </Heading>
          <Stack
            space={{ base: '8px', lg: '16px' }}
            p="24px"
            borderColor="sntBlue.primary"
            borderWidth="2px"
          >
            <Text fontWeight="bold" size="bodyMlToTitleSm">
              {planSize.recommended} Calories
            </Text>
            <Text fontWeight="bold" size="bodyMdToLg">
              ${weeklyPrice.recommended}/wk
            </Text>
            <Text fontWeight="medium" size="tinyToCaption" color="sntGrey.primary">
              {isTopper ? 'Mixing S&T' : 'Feeding 100% S&T'}
            </Text>
          </Stack>
        </Stack>
      </HStack>

      {petNextPreviewOrderDate ? (
        <Text color="sntGrey.primary" textAlign="center" size="bodySmToMd" fontWeight="medium">
          {`Meal plan changes will be effective starting with ${petName}'s next order on ${petNextPreviewOrderDate}.`}
        </Text>
      ) : null}
      <Divider bg="gallery" />
    </Stack>
  );

  return (
    <VerticalPortalPopUp
      isOpen={isOpen}
      heading={headingText}
      headingProps={{ mx: 8 }}
      subHeading={subHeadingText}
      subHeadingProps={{ fontFamily: 'primary', fontWeight: 'medium' }}
      body={PopUpBody}
      primaryCtaText="CONFIRM UPDATED PORTIONS"
      secondaryCtaText="Keep Current Portions"
      onPrimaryPress={onPrimaryPress}
      onSecondaryPress={onSecondaryPress}
      onClose={onClose}
    />
  );
};
