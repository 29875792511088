import { HStack, Box, Text, Stack, ITextProps } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import React, { useState } from 'react';

interface Option {
  value: string;
  label: string;
}

export interface OptionSelectorProps {
  options: Option[];
  onSelect: (selectedOption: Option) => void;
  value?: Option;
  label?: string;
  labelStyling?: ITextProps;
  textStyling?: ITextProps;
  props?: IHStackProps;
}

export const OptionSelector = ({
  options,
  onSelect,
  value,
  label,
  labelStyling,
  textStyling,
  props,
}: OptionSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(value ?? null);
  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <Stack space="8px" width="100%">
      {label ? (
        <Text textAlign="left" size="bodyMdToLg" {...labelStyling}>
          {label}
        </Text>
      ) : null}
      <HStack justifyContent="space-between" alignItems="center" borderWidth="2px" width="100%">
        {options.map((option) => (
          <Box
            key={option.value}
            onClick={() => handleOptionClick(option)}
            p="12px"
            margin="2px"
            flex={1}
            backgroundColor={selectedOption?.value === option.value ? 'sntBlue.primary' : 'white'}
            {...props}
          >
            <Text textAlign="center" fontWeight="medium" size="bodyMdToLg" {...textStyling}>
              {option.label}
            </Text>
          </Box>
        ))}
      </HStack>
    </Stack>
  );
};

export default OptionSelector;
