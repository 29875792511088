import { HStack, Heading, IImageProps, Text, Stack, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { Image } from '@/components/Elements';
import { Star } from '@/components/Icons';
import { ASSETS_URL } from '@/config';

export const ReviewSection = () => {
  const reviews = [
    {
      name: 'Sherry & George',
      text: "George has struggled with his weight for years. We started feeding him Spot & Tango, and he lost 10lbs in a month. We're customers for life!",
      imageSource: { uri: `${ASSETS_URL}/pictos/Reviews/Fresh/sherry-george.png` },
    },
    {
      name: 'Nathalie & Wiley',
      text: "The food smells amazing, and I love seeing fresh fruits and veggies in Wiley's bowl. She's got a shiny coat and a happy tummy, and that makes us happy!",
      imageSource: { uri: `${ASSETS_URL}/pictos/Reviews/Fresh/nathalie-wiley.png` },
    },
    {
      name: 'Mark & Bella',
      text: 'My dogs (who are very picky) went crazy for this food, and the customer service is second to none. Awesome company selling a great product.',
      imageSource: { uri: `${ASSETS_URL}/pictos/Reviews/Fresh/mark-bella.png` },
    },
  ];
  return (
    <VStack
      space="50px"
      w="100%"
      bg="sntBlue.primary"
      alignItems="center"
      pt="82px"
      pb={{ base: '100px', lg: '57px' }}
      px="16px"
    >
      <Heading size="titleSmToLg" fontWeight="bold">
        Happy Dogs – Happy Owners
      </Heading>
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        alignItems="center"
        w="100%"
        maxW="1168px"
      >
        {reviews.map((review, i) => {
          const mb = i < reviews.length - 1 ? { base: '85px', lg: 0 } : 0;
          return (
            <ReviewAvatar
              key={i}
              name={review.name}
              text={review.text}
              imageSource={review.imageSource}
              mb={mb}
            />
          );
        })}
      </Stack>
    </VStack>
  );
};

interface ReviewAvatarProps extends IVStackProps {
  name: string;
  text: string;
  imageSource: IImageProps['source'];
}

const ReviewAvatar = ({ name, text, imageSource, ...props }: ReviewAvatarProps) => (
  <VStack
    alignItems="center"
    justifyContent="flex-start"
    space="24px"
    {...props}
    w="100%"
    maxW="290px"
  >
    <VStack alignItems="center" justifyContent="flex-start" space="16px">
      {/* TODO Refactor Avatar component */}
      <VStack w="200px" h="200px" borderRadius="100%" overflow="hidden">
        <Image source={imageSource} alt={text} w="200px" h="200px" resizeMode="cover" />
      </VStack>
      <Heading size="titleMd" fontWeight={500}>
        {name}
      </Heading>
      <HStack space="8px">
        {[...Array(5)].map((_, i) => (
          <Star key={i} fill="black" color="black" size="18px" />
        ))}
      </HStack>
      <Text size="bodyMl" textAlign="center" fontFamily="secondary">
        {text}
      </Text>
    </VStack>
  </VStack>
);
