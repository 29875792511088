import { HStack, Text, Image, Stack, VStack } from 'native-base';
import React from 'react';

import { BufferBag, BufferBagProduct } from '@/api/types';
import { Select } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
import { useIsMobile } from '@/hooks';
import { generateFormattedBufferOptions } from '@/utils/bufferBags';

export type BufferBagSelectionKey = `${string}~${string}`; // pet_plan_id~recipe_id format

interface BufferBagLineItemProps {
  petBufferBag: BufferBag;
  product: BufferBagProduct;
  selectedBufferBags: { [key: BufferBagSelectionKey]: number };
  setSelectedBufferBags: (selectedBufferBags: { [key: BufferBagSelectionKey]: number }) => void;
}

export const BufferBagLineItem = ({
  petBufferBag,
  product,
  selectedBufferBags,
  setSelectedBufferBags,
}: BufferBagLineItemProps) => {
  const isMobile = useIsMobile();
  const key =
    `${petBufferBag.pet_plan_id}~${product.buffer_bag_product_code}` as BufferBagSelectionKey;
  return (
    <HStack key={key} space={4}>
      {!isMobile && (
        <VStack>
          <Image
            source={{ uri: `${ASSETS_URL}/pictos/Recipe/${product.recipe_id}.png` }}
            alt={product.recipe_name}
            size="xs"
          />
        </VStack>
      )}
      <VStack space={{ base: 2, lg: 4 }}>
        <Text size="bodyMdToLg" fontWeight="700">
          {product.recipe_name}
        </Text>
        <HStack space={3} alignItems="center" flex={1}>
          <Stack w="fit-content" maxW="100px">
            <Select
              menuPlacement="auto"
              withMenuShadow
              isSearchable={false}
              isClearable={false}
              extraStyles={{
                valueContainer: {
                  paddingLeft: '20px',
                  paddingRight: 0,
                },
                input: {
                  margin: 0,
                },
                dropdownIndicator: {
                  padding: '0px',
                },
                indicatorsContainer: {
                  padding: '10px 20px 10px 0px',
                },
              }}
              onChange={(option: any) => {
                setSelectedBufferBags({
                  ...selectedBufferBags,
                  [key]: Number(option.value),
                });
              }}
              placeholder={`${
                selectedBufferBags[key]
                  ? selectedBufferBags[key] * petBufferBag.buffer_bag_extra_days
                  : 0
              }`}
              options={generateFormattedBufferOptions(
                petBufferBag.buffer_bag_extra_days,
                petBufferBag.buffer_bag_number_of_bags_allowed
              )}
            />
          </Stack>
          <Text size="bodySmtoMd" fontWeight="700">
            extra days of food
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
