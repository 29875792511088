import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Badge, Heading, HStack, IStackProps, Stack } from 'native-base';

import { PlanLineItem } from './PlanLineItem';

import { PetPlan, Pet, RecipeType } from '@/api/types';
import { ProtectedStackParamList } from '@/types';
import {
  capitalize,
  formatPrice,
  getPetPlanMeals,
  getMealRecipeName,
  getRecipeImageSrc,
  getRecipesProductImageSrc,
} from '@/utils';

interface MealsProps extends IStackProps {
  pet: Pet;
  petPlan: PetPlan;
  disabled?: boolean;
  successRedirect?: string;
}

export const Meals = ({ pet, petPlan, disabled, successRedirect, ...props }: MealsProps) => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const mealProducts = getPetPlanMeals(petPlan);

  const mealProductsPrice =
    mealProducts.length > 0
      ? mealProducts.reduce(
          (accumulator, currentProduct) => accumulator + Number(currentProduct.price_per_week),
          0
        )
      : null;

  return (
    <Stack
      justifyContent="center"
      alignItems="start"
      space={{ base: '16px', lg: '24px' }}
      w="100%"
      {...props}
    >
      <HStack w="100%" justifyContent="space-between">
        <Heading
          size="bodyMdToLg"
          fontWeight="bold"
          color={disabled ? 'sntGrey.primary' : undefined}
        >
          Meals
        </Heading>
        <Badge
          variant="smallInfoPillSecondary"
          borderColor={disabled ? 'sntGrey.primary' : undefined}
          _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
        >
          {mealProductsPrice ? `${formatPrice(mealProductsPrice)}/wk` : null}
        </Badge>
      </HStack>
      <Stack w="100%" space={{ base: '16px', lg: '24px' }}>
        <PlanLineItem
          heading="Portion Size"
          description={`${petPlan.calories_per_day} cal/day`}
          imgSrc={getRecipeImageSrc(petPlan.recipe_data, 'PortionSize')}
          secondaryDescription={petPlan.is_topper ? 'Mixing S&T' : 'Feeding 100% S&T'}
          pressableProps={{
            _hover: { borderWidth: '0px' },
            onPress: () => {
              navigation.navigate('AdjustPortionSize', {
                petId: pet.id,
                successRedirect: successRedirect || 'Dogs',
              });
            },
          }}
          disabled={disabled}
        />
        <PlanLineItem
          heading="Food Type"
          description={getMealRecipeName(petPlan.recipe_data[0].type)}
          imgSrc={getRecipeImageSrc(petPlan.recipe_data, 'FoodType')}
          pressableProps={{
            _hover: { borderWidth: '0px' },
            onPress: () =>
              navigation.navigate('DogSelectFoodType', {
                petId: pet.id,
                successRedirect: successRedirect || 'Dogs',
              }),
          }}
          disabled={disabled}
        />
        <PlanLineItem
          heading="Recipe"
          description={petPlan.recipe_data.map(({ name }) => capitalize(name)).join(', ')}
          imgSrc={getRecipesProductImageSrc(petPlan.recipe_data, false)}
          pressableProps={{
            _hover: { borderWidth: '0px' },
            onPress: () =>
              navigation.navigate('ChangeRecipe', {
                petId: pet.id,
                successRedirect: successRedirect || 'Dogs',
                foodType: petPlan.recipe_data[0].type as RecipeType.FRESH | RecipeType.UNKIBBLE,
              }),
          }}
          divider={false}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};
