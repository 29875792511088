import { datadogLogs } from '@datadog/browser-logs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Button, Heading, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { StopPlanSuccessModal } from './ChangePlanStatus/StopPlanSuccessModal';
import { Intervention } from './ChangePlanStatus/types';
import { RecipeSelectForm } from './RecipeSelectForm';
import { RetentionDiscountModal } from './RetentionDiscountModal';
import { useAnswerSurveyAndCancelPlan, useInterventionEligibility } from './hooks';
import { useRecipeSelectForm } from './useRecipeSelectForm';

import { Pet, DiscountExclusiveType, RecipeType, useUpdatePetPlan } from '@/api';
import { usePetPlansPrices } from '@/api/services/petplans/usePetPlanPrices';
import { LoadingSpinner, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import { sendErrorReport } from '@/utils/analytics';
const { logger } = datadogLogs;

export const ChangeRecipe = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'ChangeRecipe'>) => {
  const account = useAccount();
  const { pets } = account;

  const isCancellationIntervention = route.params?.type === 'CANCELLATION';

  const { formState, setValue, getValues, watch, reset } = useRecipeSelectForm();
  const { isDirty } = formState;
  const selectedPlanRecipeIds = watch('recipeIds');

  const [pet, setSelectedPet] = useState<Pet>();
  const [selectedPetId, setSelectedPetId] = useState<string>();

  const { interventions } = useInterventionEligibility(pet?.id);
  const { data: plansPrices } = usePetPlansPrices({
    planSize: pet?.pet_plan.plan_size,
    account_id: account.id,
  });
  const currentRecipes = pet?.pet_plan.recipes;
  const seletedPlanRecipeBundledIds = selectedPlanRecipeIds.sort().join('-');
  const selectedPlanPrice = plansPrices?.prices[seletedPlanRecipeBundledIds];
  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();

  useEffect(() => {
    let petId: string | null = route.params?.petId || null;
    let foodType: string | null = route.params?.foodType || null;
    let recipe: string | null = route.params?.recipe || null;
    const requestedUrl = localStorage.getItem('requestedUrl');
    localStorage.removeItem('requestedUrl');

    if (requestedUrl) {
      // intentionally not removing from localStorage because RecipeSelectForm needs to access as well for PDPs.
      localStorage.setItem('recipeSelectFormURL', requestedUrl);
      // localStorage.removeItem('requestedUrl');
      const url = new URL(requestedUrl);
      petId = url.searchParams.get('petId');
      foodType = url.searchParams.get('foodType');
      recipe = url.searchParams.get('recipe');

      if (!route.params?.petId && petId && pets.find((pet) => pet.id === petId)) {
        setSelectedPetId(petId);
        // Required to handle signed out case
        navigation.setParams({ petId });

        const sameFoodType = route.params?.foodType === pet?.pet_plan.recipe_data[0].type;
        if (!sameFoodType) {
          navigation.navigate('DogSelectFoodType', {
            petId: route.params?.petId,
            successRedirect: route.params?.successRedirect || 'Dogs',
          });
        } else {
          if (petId) {
            navigation.setParams({ petId });
          }
          if (foodType === RecipeType.UNKIBBLE || foodType === RecipeType.FRESH) {
            navigation.setParams({ foodType });
          }
          if (recipe) {
            navigation.setParams({ recipe });
          }
        }
      } else {
        logger.warn('Potential Deeplink failure: Pet not found by id', { petId });
        navigation.navigate('DogSelectFoodType', {
          petId: route.params?.petId,
          successRedirect: route.params?.successRedirect || 'Dogs',
        });
      }
    } else if (petId && pets.find((pet) => pet.id === petId)) {
      setSelectedPetId(petId);
      navigation.setParams({ petId });
    } else {
      logger.warn('Potential Deeplink failure: Pet not found by id', { petId });
    }
  }, []);

  useEffect(() => {
    if (isCancellationIntervention) {
      navigation.setOptions({
        title:
          route?.params.foodType === RecipeType.FRESH
            ? 'Try Spot & Tango Fresh'
            : 'Try UnKibble for the Perfect Crunch',
      });
    } else {
      if (pet) {
        navigation.setOptions({ title: `Change ${pet.name}'s Recipes` });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, navigation, pet]);

  useEffect(() => {
    if (selectedPetId) {
      const foundPet = pets.find((pet) => pet.id === selectedPetId);
      setSelectedPet(foundPet);
      navigation.setParams({ petId: selectedPetId });
    }
  }, [pets, selectedPetId]);

  const onPressCancel = async () => {
    if (interventions.includes(Intervention.TenPercentDiscount)) {
      setRetentionDiscountModalOpen(true);
    } else {
      doCancelPlan();
    }
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route?.params?.primaryAnswer || '',
      secondaryAnswer: route?.params?.secondaryAnswer || '',
      customerComment: route?.params?.customerComment || '',
    });

    setRetentionDiscountModalOpen(false);
    setStopPlanSuccessModalOpen(true);
  };

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    setRetentionDiscountModalOpen(false);
    navigation.navigate('Account');
  };

  if (!pet) {
    return <LoadingSpinner accessLabel="Change Recipe Page" />;
  }

  const onSubmit = async () => {
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: { recipes: selectedPlanRecipeIds },
      });
      if (isCancellationIntervention) {
        segment.trackEvent('Cancellation Flow - Switched Recipe', {
          account_id: account.id,
          email: account.email,
          cancellation_reason: route.params.primaryAnswer,
          cancellation_reason_secondary: route.params.secondaryAnswer,
          cancellation_reason_comment: route.params.customerComment,
          pet_id: pet.id,
          previous_recipe_ids: currentRecipes,
          new_recipe_ids: selectedPlanRecipeIds,
        });
      }
      displayToast({ message: `${pet.name}'s recipes have been updated.` });
      navigation.navigate(
        route.params?.successRedirect || 'Account',
        route.params?.successRedirectParams || {}
      );
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <>
      <Stack alignItems="center" w="100%" px="16px" pb="0px">
        <Heading paddingBottom="24px" variant="subtitle">
          {!isCancellationIntervention
            ? 'Choose one, or mix and match for more variety!'
            : route.params.foodType === 'FRESH'
            ? 'These easy-to-chew meals are the ideal option for pups with sensitive teeth'
            : '100% real ingredients: The same fresh quality you trust, in shelf-stable, bite sized pieces.'}
        </Heading>
        <Stack w="100%" justifyContent="space-between" alignItems="center">
          <Stack mb={5} w="100%" flex={1}>
            <RecipeSelectForm
              isCancellationIntervention
              getValues={getValues}
              pet={pet}
              recipeType={route.params?.foodType}
              reset={reset}
              setValue={setValue}
              navigation={navigation}
            />
          </Stack>
          {isCancellationIntervention && (
            <Stack mb={5}>
              <Text
                size="bodySmToMd"
                color="sntGrey.primary"
                fontWeight="medium"
                textAlign="center"
              >
                Any questions? Call or text us at (718) 514-6292. Our team is here to help.
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack variant="stickyFooter" alignItems="center" px="16px">
        {selectedPlanPrice ? (
          <Text fontWeight="medium" size="bodyMdToLg" paddingBottom="24px">
            {`${pet.name}'s Price: $${selectedPlanPrice}/week`}
          </Text>
        ) : null}

        <FormSubmitButton
          onPress={onSubmit}
          isLoading={isLoadingUpdatePetPlan}
          isDisabled={!isDirty || selectedPlanRecipeIds.length === 0}
        >
          Save Changes
        </FormSubmitButton>
        {isCancellationIntervention ? (
          <Button
            variant="underlineMini"
            onPress={onPressCancel}
            mt={{ base: 4, md: 6 }}
            isLoading={isSubmittingCancel}
          >
            Continue to cancellation
          </Button>
        ) : null}
      </Stack>
      <RetentionDiscountModal
        isOpen={retentionDiscountModalOpen}
        onClose={() => setRetentionDiscountModalOpen(false)}
        petId={pet.id}
        onSecondaryPress={doCancelPlan}
        discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
        cancellationReason={route?.params?.primaryAnswer}
        cancellationReasonSecondary={route?.params?.secondaryAnswer}
        cancellationReasonComment={route?.params?.customerComment}
      />
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={navigateToAccount}
        onConfirm={navigateToAccount}
      />
    </>
  );
};
