import axios from 'axios';
import { Button, Heading, ScrollView, Stack, Text, VStack } from 'native-base';
import { useState } from 'react';

import { AdjustPortionSizeModal, SuggestionMode } from '../../AdjustPortionSizeModal';
import { RetentionDiscountModal } from '../../RetentionDiscountModal';
import {
  useAnswerSurveyAndCancelPlan,
  useInterventionEligibility,
  usePetsNextOrder,
} from '../../hooks';
import { StopPlanSuccessModal } from '../StopPlanSuccessModal';
import { Intervention } from '../types';
import { ConfirmRushDeliveryModal } from './ConfirmRushDeliveryModal';
import { PetProductSection } from './PetProductsSection';

import { DiscountExclusiveType, useUpdatePlanDates } from '@/api';
import { displayToast, Image, ToastType } from '@/components/Elements';
import { OrderSummary } from '@/components/Portal/Orders';
import { PaymentErrorMessage } from '@/components/Portal/Orders/RescheduleDelivery';
import { ASSETS_URL } from '@/config';
import { useAccount } from '@/hooks';
import { AXIOS_TOAST_NAME } from '@/lib/axios';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { defaultErrorHandler, sendErrorReport, toIsoDate } from '@/utils';

export const RushADelivery = ({ navigation, route }: ProtectedScreenProps<'RushADelivery'>) => {
  const account = useAccount();
  const [confirmRushModalOpen, setConfirmRushModalOpen] = useState(false);
  const [adjustPortionSizeModalOpen, setAdjustPortionSizeModalOpen] = useState(false);
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);

  const { pet, petsNextOrder, nextOrderDate, productsByPet, petPlanIdsInNextOrder } =
    usePetsNextOrder(route.params.petId);
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();
  const { mutateAsync: updatePlanDates, isLoading: isLoadingUpdatePlanDate } = useUpdatePlanDates();
  const { isLoading: isLoadingInterventionEligiblity, interventions } = useInterventionEligibility(
    pet?.id
  );

  const showOnlyStopPlanSuccessModal = () => {
    setConfirmRushModalOpen(false);
    setRetentionDiscountModalOpen(false);
    setAdjustPortionSizeModalOpen(false);

    setStopPlanSuccessModalOpen(true);
  };

  const closeAllModals = () => {
    setStopPlanSuccessModalOpen(false);
    setConfirmRushModalOpen(false);
    setRetentionDiscountModalOpen(false);
    setAdjustPortionSizeModalOpen(false);
  };

  const navigateTo = (screen: 'Orders' | 'Dogs' | 'Account' = 'Account') => {
    closeAllModals();
    navigation.navigate(screen as any);
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.primaryAnswer,
      secondaryAnswer: route.params.secondaryAnswer,
      customerComment: route.params.customerComment,
    });

    showOnlyStopPlanSuccessModal();
  };

  const doRushDelivery = async () => {
    if (!pet || !petsNextOrder || !petPlanIdsInNextOrder || !account) return;
    const petIds = petPlanIdsInNextOrder.map(
      (petPlanId) => account.pets?.find((pet) => pet.pet_plan.id === petPlanId)?.id
    );

    try {
      await updatePlanDates({
        petPlanIds: petPlanIdsInNextOrder,
        date: toIsoDate(new Date()),
      });

      const previousOrderDate = nextOrderDate ? toIsoDate(new Date(nextOrderDate)) : undefined;
      segment.trackEvent('Cancellation Flow - Charge Order Now', {
        account_id: account.id,
        email: account.email,
        cancellation_reason: route.params?.primaryAnswer,
        cancellation_reason_secondary: route.params?.secondaryAnswer,
        cancellation_reason_comment: route.params?.customerComment,
        order_id: petsNextOrder.id,
        previous_order_date: previousOrderDate,
        pet_id: petIds,
      });

      navigateTo('Orders');
      displayToast({
        type: ToastType.Success,
        message:
          "We'll get started on your order and send you tracking information as soon as it's officially on its way",
      });
    } catch (error) {
      closeAllModals();

      if (axios.isAxiosError(error)) {
        const msg_start = error.response?.data.split(':')[0];
        if (msg_start === 'Payment Failed') {
          displayToast({
            message:
              "Your payment method was unsuccessful. Please add a new form of payment so we can start preparing your pup's order.",
            styledMessage: <PaymentErrorMessage />,
            onPress: () => navigation.navigate('PaymentMethodList'),
            title: 'Payment Error',
            type: ToastType.Error,
            duration: 6000,
            id: AXIOS_TOAST_NAME,
          });
          return;
        }
      }
      defaultErrorHandler();
      sendErrorReport(error);
    }
  };

  const onPressCancel = async () => {
    if (interventions.includes(Intervention.AdjustPortionSizeUp)) {
      setAdjustPortionSizeModalOpen(true);
      return;
    } else if (interventions.includes(Intervention.TenPercentDiscount)) {
      setRetentionDiscountModalOpen(true);
      return;
    }

    await doCancelPlan();
  };

  return (
    <Stack alignItems="center" w="100%" h="100%">
      <ScrollView style={{ width: '100%' }}>
        <Stack alignItems="center" w="100%" h="100%" px={4} space={{ base: 6, md: 12 }} flex={1}>
          <Text fontFamily="secondary" size="bodySmToMd" textAlign="center" maxW={{ md: '1000px' }}>
            Low on food? Expedite your next order and get what you need fast.
          </Text>
          <Stack
            borderWidth={{ base: '1px', md: '2px' }}
            borderColor="black"
            w="100%"
            h="fit-content"
            p={4}
            maxW={{ md: '550px' }}
            space={4}
            marginBottom={{ base: '142px', md: '160px' }}
          >
            <Stack
              w="100%"
              position="relative"
              alignSelf="center"
              justifyContent="center"
              borderWidth={{ base: '1px', md: '2px' }}
              borderColor="black"
            >
              <Image
                source={{
                  uri: `${ASSETS_URL}/pictos/CancellationIntervention/dog_in_unkibble_box.png`,
                }}
                w="492px"
                h={{ base: '290px', md: '390px' }}
                alt="Dog sitting in UnKibble box"
                resizeMode="cover"
              />
            </Stack>
            <VStack space={4}>
              <Heading size="bodyMlToTitleSm" fontWeight="bold">
                In Your Box
              </Heading>
              {productsByPet &&
                Object.keys(productsByPet).map((petId, i) => (
                  <PetProductSection petId={petId} orderProducts={productsByPet[petId]} key={i} />
                ))}
              <OrderSummary order={petsNextOrder} hasDropdown />
            </VStack>
          </Stack>
        </Stack>
      </ScrollView>
      <Stack
        justifyContent="center"
        alignItems="center"
        space={6}
        w="100%"
        bg="white"
        style={{
          shadowColor: 'rgba(0,0,0,0.15)',
          shadowOffset: { width: -4, height: -4 },
          shadowRadius: 4,
        }}
        px={4}
        py={6}
        position="fixed"
        bottom={0}
      >
        <Button
          variant="hugPrimary"
          w="100%"
          maxW={{ md: '290px' }}
          onPress={() => setConfirmRushModalOpen(true)}
          isLoading={isLoadingUpdatePlanDate || isSubmittingCancel}
          isDisabled={isLoadingInterventionEligiblity}
        >
          ORDER NOW
        </Button>
        <Button
          variant="underlineMini"
          onPress={onPressCancel}
          isDisabled={
            isSubmittingCancel || isLoadingInterventionEligiblity || isLoadingUpdatePlanDate
          }
        >
          Continue to cancellation
        </Button>
      </Stack>
      {petsNextOrder && (
        <ConfirmRushDeliveryModal
          orderTotal={petsNextOrder?.total_price}
          isOpen={confirmRushModalOpen}
          onClose={() => setConfirmRushModalOpen(false)}
          onConfirm={doRushDelivery}
          isBackButtonDisabled={isLoadingUpdatePlanDate || isSubmittingCancel}
          isConfirmButtonDisabled={isLoadingInterventionEligiblity}
          isConfirmButtonLoading={isLoadingUpdatePlanDate || isSubmittingCancel}
        />
      )}
      <RetentionDiscountModal
        isOpen={retentionDiscountModalOpen}
        onClose={() => setRetentionDiscountModalOpen(false)}
        petId={pet?.id}
        onSecondaryPress={doCancelPlan}
        discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
        cancellationReason={route?.params?.primaryAnswer}
        cancellationReasonSecondary={route?.params?.secondaryAnswer}
        cancellationReasonComment={route?.params?.customerComment}
      />
      {pet ? (
        <AdjustPortionSizeModal
          isOpen={adjustPortionSizeModalOpen}
          petId={pet.id}
          petName={pet.name}
          mode={SuggestionMode.INCREASE}
          onClose={() => {
            setAdjustPortionSizeModalOpen(false);
            if (interventions.includes(Intervention.TenPercentDiscount)) {
              setRetentionDiscountModalOpen(true);
            } else {
              doCancelPlan();
            }
          }}
          onPressX={() => setAdjustPortionSizeModalOpen(false)}
          cancellationReason={route?.params?.primaryAnswer}
          cancellationReasonSecondary={route?.params?.secondaryAnswer}
          cancellationReasonComment={route?.params?.customerComment}
        />
      ) : null}

      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={() => navigateTo('Account')}
        onConfirm={() => navigateTo('Account')}
      />
    </Stack>
  );
};
