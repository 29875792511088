export interface SurveyOption {
  hideCommentField?: boolean;
  isBelowFold?: boolean;
  isCommentOptional?: boolean;
  isOtherOption?: boolean;
  label: string;
  interventionType?: string;
  interventionSubtype?: string;
  passedAway?: boolean;
  subsurvey?: SurveyOption[];
}

export const SURVEY_OPTIONS: SurveyOption[] = [
  {
    label: `It's too expensive`,
    interventionType: 'its-too-expensive',
    subsurvey: [
      { label: `I can't afford it within my budget` },
      { label: `It didn't meet my expectations for the price` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `My dog doesn't like it`,
    interventionType: 'dog-dislikes-it',
    subsurvey: [
      { label: `My dog picks out or eats around certain ingredients` },
      { label: `My dog is not eating the food (e.g., sniffs and walks away) ` },
      { label: `My dog eats the food, but just doesn't love it` },
      { label: `My dog has trouble chewing the food (e.g., issues with size or texture)` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `My dog had a negative reaction (e.g., digestive, stool, vomiting, or allergy issues)`,
    interventionType: 'dog-has-negative-reaction',
    subsurvey: [
      { label: `My dog is vomiting` },
      { label: `My dog is having stool or digestive issues` },
      { label: `My dog had an allergic reaction` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I haven't noticed any benefit`,
    interventionType: 'no-benefit',
    subsurvey: [
      { label: `I expected increased energy` },
      { label: `I expected clearer skin or a shinier coat` },
      { label: `I expected better digestion and stools` },
      { label: `I expected less itching and scratching` },
      { label: `I expected my dog to lose weight` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `The recommended calories aren't right for my dog`,
    interventionType: 'issue-with-recommended-calories',
    subsurvey: [
      {
        label: `I think my dog needs more calories per day`,
        interventionSubtype: 'need-more-calories',
      },
      {
        label: `I think my dog needs fewer calories per day`,
        interventionSubtype: 'need-less-calories',
      },
      { label: `Other`, isOtherOption: true, interventionSubtype: 'control-calorie-intake' },
    ],
  },
  {
    label: `I don't want a subscription`,
    interventionType: 'dont-want-subscription',
    subsurvey: [
      { label: `I prefer to order one-off, but I'll re-order when I'm ready` },
      {
        label: `I dislike subscriptions in general, so I'll buy another product that I can purchase one-off`,
      },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `My dog is no longer with me`,
    subsurvey: [
      { label: `My dog passed away`, hideCommentField: true, passedAway: true },
      { label: `My dog no longer lives with me`, hideCommentField: true },
    ],
  },
  {
    label: `I'm unhappy with the quality of the food`,
    interventionType: 'unhappy-with-texture-quality',
    subsurvey: [
      { label: `There's not enough meat`, interventionSubtype: 'unhappy-with-quality' },
      {
        label: `I'm concerned about ingredients in or not in the recipes`,
        interventionSubtype: 'unhappy-with-quality',
      },
      { label: `It's too soft or crumbly`, interventionSubtype: 'food-too-soft' },
      { label: `It's too hard`, interventionSubtype: 'food-too-hard' },
      { label: `It has a strong or unpleasant smell`, interventionSubtype: 'unhappy-with-quality' },
      { label: `It looks different than advertised`, interventionSubtype: 'unhappy-with-quality' },
      { label: `The quality varies order to order`, interventionSubtype: 'unhappy-with-quality' },
      { label: `Other`, isOtherOption: true, interventionSubtype: 'unhappy-with-quality' },
    ],
  },
  {
    label: `I had issues with my deliveries`,
    interventionType: 'issue-with-delivery',
    subsurvey: [
      { label: `Order(s) took too long to arrive` },
      { label: `Items in my box were missing or incorrect` },
      { label: `Box(es) arrived damaged` },
      { label: `Food arrived unuseable (e.g., spoiled, opened, etc.)` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I'm running out of food or have too much food leftover between orders`,
    interventionType: 'too-much-too-little-food',
    subsurvey: [
      { label: `I'm running out of food`, interventionSubtype: 'too-little-food' },
      { label: `I have too much food`, interventionSubtype: 'too-much-food' },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `Feeding this food is too difficult or inconvenient`,
    interventionType: 'feeding-is-difficult',
    subsurvey: [
      { label: `Portioning the food correctly is difficult` },
      { label: `Serving the food is messy` },
      { label: `The packaging is hard to open` },
      { label: `It's difficult to store or takes up too much space` },
      { label: `Being home soon enough after deliveries is inconvenient` },
      { label: `It's too hard to take on-the-go or travel with` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I had issues with the website`,
    interventionType: 'issue-with-website',
    isBelowFold: true,
    subsurvey: [
      { label: `I had trouble accessing my account` },
      { label: `I found it confusing to manage my account` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I had issues with customer service`,
    interventionType: 'issue-with-customer-service',
    isBelowFold: true,
    subsurvey: [
      { label: `I had trouble finding a way to contact you` },
      { label: `I had trouble finding a way to speak with someone on the phone` },
      { label: `I didn't receive a response from your team` },
      { label: `The individual(s) I spoke to didn't seem knowledgeable` },
      {
        label: `The individual(s) I spoke to didn't make me feel heard (e.g., rude, unwilling to help, etc.)`,
      },
      { label: `I didn't agree with a company policy that was explained to me` },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I need food specific to my dog's medical issues or lifestage`,
    interventionType: 'dog-has-medical-issue',
    isBelowFold: true,
    subsurvey: [
      { label: `Weight Loss / Management`, interventionSubtype: 'control-calorie-intake' },
      { label: `Urinary Support` },
      { label: `Thyroid Support` },
      { label: `Skin Support`, interventionSubtype: 'dog-needs-special-food' },
      { label: `Liver Support` },
      { label: `Kidney Support` },
      { label: `Joint Support` },
      { label: `Allergy Support`, interventionSubtype: 'dog-needs-special-food' },
      { label: `Digestive Support`, interventionSubtype: 'dog-needs-special-food' },
      { label: `Diabetes Support` },
      { label: `Pancreatitis Support / Low Fat` },
      {
        label: `Gestation/Lactation Lifestage (pregnancy and nursing)`,
        interventionSubtype: 'control-calorie-intake',
      },
      { label: `Growth Lifestage (puppies)`, interventionSubtype: 'control-calorie-intake' },
      { label: `Maintenance Lifestage (adults)`, interventionSubtype: 'control-calorie-intake' },
      { label: `Other`, isOtherOption: true },
    ],
  },
  {
    label: `I have a different issue not listed above`,
    interventionType: 'unlisted-issue',
    isBelowFold: true,
  },
];
