import { Heading, Stack, Text, VStack } from 'native-base';

import { Promotion, RecipeType } from '@/api';

interface ExtrasProps {
  recipeType: RecipeType;
  promotions?: Promotion[];
  hasMultipleRecipes?: boolean;
}

export const Extras = ({ recipeType, promotions, hasMultipleRecipes }: ExtrasProps) => {
  return (
    <VStack py={4} space={2}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Heading size="bodyMdToLg" fontWeight="bold">
          Extras
        </Heading>
      </Stack>
      {promotions &&
        promotions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((promotion, index) => (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              key={promotion.id}
            >
              <Text
                key={index}
                size="bodyMdToLg"
                maxW={{ base: '200px', md: '100%' }}
                h="auto"
                fontWeight="medium"
              >
                {promotion.name === 'Snack Beef Trainers One-Time Free'
                  ? '🎁 Gift: Beef Trainers'
                  : `🎁 Gift: ${promotion.name} ${
                      promotion.quantity > 1 ? ` x ${promotion.quantity}` : ''
                    }`}
              </Text>
              <Text mx={1} size="bodyMdToLg" fontWeight="medium">
                FREE
              </Text>
            </Stack>
          ))}
      {recipeType === RecipeType.UNKIBBLE && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Text size="bodyMdToLg" maxW={{ base: '200px', md: '100%' }} h="auto" fontWeight="medium">
            Customized {hasMultipleRecipes ? 'Scoops' : 'Scoop'}
          </Text>

          <Text mx={1} size="bodyMdToLg" fontWeight="medium">
            FREE
          </Text>
        </Stack>
      )}
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Text size="bodyMdToLg" maxW={{ base: '200px', md: '100%' }} h="auto" fontWeight="medium">
          Personalized Feeding Guide
        </Text>

        <Text mx={1} size="bodyMdToLg" fontWeight="medium">
          FREE
        </Text>
      </Stack>
    </VStack>
  );
};
