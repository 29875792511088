import { FormControl, Stack } from 'native-base';
import { IInputProps } from 'native-base/lib/typescript/components/primitives/Input/types';
import { FieldValues, Path } from 'react-hook-form';

import { InputSideLabel } from '../Input';
import { LabeledFormControl, LabeledFormControlProps } from '../LabeledFormControl';

export interface LabeledInputProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledFormControlProps<TFieldValues, TName>, 'children'> {
  error?: string;
  // convenience Input props
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  inputProps?: Partial<IInputProps>;
}

/**
 * Generic labelled input element for react-hook-form forms
 */
export const LabeledInputSideLabel = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  control,
  error,
  name,
  label,
  inputLabel,
  placeholder,
  required,
  inputProps = {},
  ...formControlProps
}: LabeledInputProps<TFieldValues, TName>) => {
  const emptyFieldError =
    'must be a `number` type, but the final value was: `NaN` (cast from the value `""`).';

  return (
    <LabeledFormControl
      control={control}
      name={name}
      label={label}
      isInvalid={!!error}
      {...formControlProps}
    >
      {({ field: { onChange, onBlur, value } }) => (
        <Stack space="8px" w="100%">
          <InputSideLabel
            variant={error ? 'error' : 'base'}
            label={inputLabel}
            stackProps={{
              width: { base: '60%', lg: '55%' },
              space: { base: 2, md: 3 },
            }}
            size="bodyMd"
            mx={0}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            value={value}
            placeholder={placeholder}
            _invalid={{
              borderColor: 'error.default',
            }}
            {...inputProps}
          />

          {error && (
            <FormControl.ErrorMessage>
              {error.includes(emptyFieldError) ? `Please enter ${name}` : error}
            </FormControl.ErrorMessage>
          )}
        </Stack>
      )}
    </LabeledFormControl>
  );
};
