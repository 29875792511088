import { datadogLogs } from '@datadog/browser-logs';
import { useIsFocused } from '@react-navigation/native';
import {
  Heading,
  Button,
  VStack,
  HStack,
  Stack,
  useBreakpointValue,
  IStackProps,
  Box,
} from 'native-base';
import React, { useEffect, useState } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { useRecipes, RecipeFull, RecipeType } from '@/api';
import { displayToast, PDPModal, RecipeCard, ToastType, VToHStack } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogSnacks = ({ navigation }: AddNewDogScreenProps<'Snacks'>) => {
  const account = useAccount();
  const { petData, snacks, discountCode, saveAddOnRecipes } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.SNACKS);

  const [selectedSnacks, setSelectedSnacks] = useState<string[]>([]);
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [detailSnack, setDetailSnack] = useState<RecipeFull>();

  const stickyFooterStyle: IStackProps['style'] = useBreakpointValue({
    '2xl': { boxShadow: 'none' },
  });

  const onSubmit = (snacks: string[]) => {
    try {
      saveAddOnRecipes(snacks, RecipeType.SNACK);
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's snacks`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's snacks, account_id=${account.id}`, { error });
    }
  };

  const { data: allSnacks, isLoading: isLoadingSnacks } = useRecipes({
    recipeType: 'snacks',
    accountId: account.id,
    discount: discountCode ?? '',
  });

  const updateSnacks = (id: string) => {
    if (selectedSnacks.some((selected) => selected === id)) {
      // snack deselected
      setSelectedSnacks(selectedSnacks.filter((selected) => selected !== id));
    } else {
      // snack selected
      setSelectedSnacks(selectedSnacks.concat([id]));
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && snacks) {
      setSelectedSnacks(Object.keys(snacks));
    }
  }, [snacks, isFocused]);

  if (!(allSnacks && petData)) {
    return null;
  }

  if (!isFocused || !petData || !Object.keys(petData).length) {
    return null;
  }

  const maxStackWidth = (): IStackProps['maxW'] => {
    if (allSnacks.length === 4) {
      return { lg: '840px', '2xl': '100%' };
    }
    if (allSnacks.length > 4) {
      return { lg: '1045px', '3xl': '100%' };
    }
    return { '3xl': '100%' };
  };
  return (
    <>
      <AddNewDogWrapper
        containerProps={{
          space: { base: 6, md: 12 },
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxW: { '2xl': '100%' },
        }}
      >
        <VStack space="16px" justifyContent="center" alignItems="center">
          <Heading size="titleSmToMd" fontWeight="bold" textAlign="center">
            Want to add Snacks to {petData.name}'s Plan?
          </Heading>
          <Heading
            size="bodySmToMd"
            fontFamily="secondary"
            textAlign="center"
            maxW={{ base: '100%', md: '960px' }}
          >
            Single ingredient treats for your pup — give them the best between meals, too.
          </Heading>
        </VStack>
        <Stack w="100%" alignItems="center" pb={{ base: '160px', md: '200px' }}>
          <VToHStack
            flexWrap="wrap"
            w="100%"
            maxW={maxStackWidth()}
            justifyContent="center"
            alignItems={{ base: 'center', lg: 'flex-start' }}
          >
            {allSnacks?.map((snack) => (
              <Box key={snack.id} alignItems="center" w={{ base: '100%', md: 'auto', lg: 'auto' }}>
                <RecipeCard
                  w={{ base: '100%', md: '350px', lg: '324px' }}
                  h={{ base: '142px', md: '175px', lg: 'inherit' }}
                  minH={{ base: '142px', md: '175px', lg: '378px' }}
                  justifyContent="center"
                  alignItems="center"
                  recipe={snack}
                  pricing={
                    snack.discounted_price !== null
                      ? Number(snack.discounted_price)
                      : Number(snack.price)
                  }
                  strikethroughPrice={
                    snack.discounted_price !== null
                      ? Number(snack.default_product?.price.price)
                      : null
                  }
                  selected={selectedSnacks.some((selected) => selected === snack.id)}
                  onPress={() => updateSnacks(snack.id)}
                  pricingUnit="bag"
                  underlineButton={{
                    text: 'View Details',
                    onPress: () => {
                      setShowPDPModal(true);
                      setDetailSnack(snack);
                    },
                  }}
                />
              </Box>
            ))}
          </VToHStack>
        </Stack>
      </AddNewDogWrapper>

      {detailSnack && (
        <PDPModal
          recipe={detailSnack}
          buttonText="Select Snack"
          prices={[
            detailSnack.discounted_price !== null
              ? detailSnack.discounted_price
              : detailSnack.price,
          ]}
          pricingUnits={['bag']}
          updateState={() => {
            if (!selectedSnacks.some((selectedId: string) => selectedId === detailSnack.id)) {
              setSelectedSnacks(selectedSnacks.concat([detailSnack.id]));
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      )}

      <Stack
        justifyContent="center"
        alignItems="center"
        space={{ base: 4, lg: 6 }}
        w="100%"
        variant="stickyFooter"
        style={stickyFooterStyle}
        position="fixed"
        px={4}
      >
        <HStack w={{ base: '100%', lg: '336px' }}>
          <Button
            h={{ base: '52px', lg: '56px' }}
            isDisabled={isLoadingSnacks}
            size="bodyMdToLg"
            w="100%"
            onPress={() => onSubmit(selectedSnacks)}
          >
            CONTINUE
          </Button>
        </HStack>
      </Stack>
    </>
  );
};
