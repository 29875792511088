import { datadogLogs } from '@datadog/browser-logs';
import { VStack, Heading, View, Stack, Text, FormControl, Input } from 'native-base';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { PetStatus } from '@/api';
import { Button, ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { DogNameResolver } from '@/screens';

const { logger } = datadogLogs;
interface DogNameFormData {
  dogName: string;
}

export const AddNewDogName = ({ navigation }: AddNewDogScreenProps<'DogName'>) => {
  const account = useAccount();
  const { pets } = account;
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.DOG_NAME);
  const { savePetData } = useAddNewDogContext();

  const defaultValues = {
    dogName: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<DogNameFormData>({ defaultValues, mode: 'onChange', resolver: DogNameResolver });
  const dogName = watch('dogName');

  const onSubmit: SubmitHandler<DogNameFormData> = async ({ dogName }) => {
    const unavailablePetNames = pets
      .filter((pet) => ![PetStatus.DECEASED, PetStatus.DECEASED_TO_NOT_LIST].includes(pet.status))
      .map((pet) => pet.name.toLocaleLowerCase().trim());

    if (unavailablePetNames.includes(dogName.toLocaleLowerCase().trim())) {
      setError('dogName', {
        type: 'string',
        message: 'You already have an existing pet with this name. Please use another name.',
      });
      return;
    }

    try {
      savePetData({ name: dogName });
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's name`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's name, account_id=${account.id}`, { error });
    }
  };
  return (
    <AddNewDogWrapper containerProps={{ space: '64px' }}>
      <VStack
        w="100%"
        space="48px"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        alignContent="center"
      >
        <Heading size="titleSmToMd" fontWeight="bold">
          What is your dog's name?
        </Heading>

        <Stack
          justifyContent="center"
          alignItems="center"
          w={{ base: '100%', md: '472px' }}
          space="24px"
        >
          <FormControl isInvalid={!!errors?.dogName}>
            <VStack alignItems="center">
              <Controller
                control={control}
                name="dogName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Input
                    variant={errors?.dogName ? 'error' : 'base'}
                    ref={ref}
                    w="100%"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    placeholder="Dog's name"
                    onKeyPress={(event) => {
                      if (event.nativeEvent.key === 'Enter') {
                        event.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    testID="dog-name-input"
                  />
                )}
              />
              {errors?.dogName ? (
                <View w="100%">
                  <Text size="bodySmToMd" mt="4px" color="error.default">
                    {errors?.dogName?.message}
                  </Text>
                </View>
              ) : null}
            </VStack>
          </FormControl>
        </Stack>
      </VStack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        onPress={handleSubmit(onSubmit)}
        isLoading={isSubmitting}
        isDisabled={!dogName}
        testID="dog-name-submit"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
      >
        CONTINUE
      </Button>
    </AddNewDogWrapper>
  );
};
