import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { Stack, Heading, View, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { Image } from '@/components/Elements';
import { LockAlertOutline } from '@/components/Icons';
import { ASSETS_URL } from '@/config';
import { useStripeCardElementOptionsBase } from '@/stripe';
const cards = `${ASSETS_URL}/creditCardIcons/`;

interface CheckoutFormBillingProps {
  isLoading?: boolean;
  stripeErrorMessage?: string;
}

export const CheckoutFormBilling = ({
  isLoading,
  stripeErrorMessage,
}: CheckoutFormBillingProps) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setError(event.error ? event.error.message : '');
  };

  useEffect(() => {
    if (stripeErrorMessage) {
      setError(stripeErrorMessage);
    }
  }, [stripeErrorMessage]);

  const cardLogos = ['visa', 'mastercard', 'amex', 'discover'];

  const optionsBase = useStripeCardElementOptionsBase();
  const options: StripeCardElementOptions = {
    ...optionsBase,
    disabled: isLoading,
  };

  return (
    <Stack h="fit-content" py={4}>
      <Heading size="bodyMlToTitleSm" mb={1} fontWeight="bold">
        Payment Information
      </Heading>

      <Stack flexDirection="row" alignItems="center">
        <LockAlertOutline size={3} mr={{ base: 1, lg: 2 }} />
        <Text
          size="bodySmToMd"
          fontFamily="secondary"
          fontWeight="medium"
          color="sntGrey.primary"
          w="fit-content"
        >
          All transactions are secure and encrypted through Stripe
        </Text>
      </Stack>

      <View justifyContent="center" w="100%" mt={8}>
        <Stack flexDirection="row" justifyContent="flex-start" mb={4}>
          {cardLogos.map((card, i, arr) => (
            <Image
              key={card}
              source={{ uri: `${cards}${card}.svg` }}
              alt={card}
              w="34px"
              h="24px"
              ml={i > 0 ? 1 : 0}
              mr={i < arr.length ? 1 : 0}
            />
          ))}
        </Stack>
        <Stack
          flexDirection="column"
          alignSelf="center"
          w="100%"
          py={4}
          px={4}
          mb={2}
          borderWidth="1px"
          borderColor="black"
        >
          <CardElement options={options} onChange={handleChange} />
        </Stack>
        {error ? (
          <Stack>
            <Text color="error.default" size="bodySmToMd">
              {error}
            </Text>
          </Stack>
        ) : null}
      </View>
    </Stack>
  );
};
