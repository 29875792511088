import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import {
  Heading,
  Button,
  VStack,
  HStack,
  FormControl,
  Pressable,
  Stack,
  Link,
  Text,
} from 'native-base';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Platform } from 'react-native';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { Select, ToastType, displayToast } from '@/components/Elements';
import {
  BirthdayMonthPlaceholder,
  BirthdayYearPlaceholder,
} from '@/components/Elements/Placeholders';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useEnterPressEffect, useIsMobile, useNextAddNewDogStep } from '@/hooks';
import { BirthdayResolver } from '@/screens';
import { getBirthdayMonths, getBirthdayYears } from '@/utils';

type BirthdayFormData = {
  birth_month?: string;
  birth_year: string;
};
const { logger } = datadogLogs;

export const AddNewDogBirthday = ({ navigation }: AddNewDogScreenProps<'Birthday'>) => {
  const account = useAccount();
  const { petData, savePetData } = useAddNewDogContext();
  const [birthdayError, setBirthdayError] = useState<string | null>(null);
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.BIRTHDAY);

  const isMobile = useIsMobile();

  const years = getBirthdayYears();
  const months = getBirthdayMonths();

  const onSubmit = (data: BirthdayFormData) => {
    if (!data.birth_year) {
      setBirthdayError('Birth year is required');
      return;
    }
    try {
      if (data.birth_month) {
        savePetData({ birth_month: data.birth_month });
      }
      savePetData({ birth_year: data.birth_year });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's birthday`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's birthday, account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    setFocus,
    reset,
    trigger,
  } = useForm<BirthdayFormData>({
    resolver: BirthdayResolver,
    defaultValues: { birth_year: petData?.birth_year, birth_month: petData?.birth_month },
    mode: 'onChange',
  });

  useEnterPressEffect(() => {
    if (!birthYear) {
      setFocus('birth_year');
    } else {
      handleSubmit(onSubmit)();
    }
  });

  const birthYear = watch('birth_year');
  const birthMonth = watch('birth_month');

  useFocusEffect(
    useCallback(() => {
      if (petData?.birth_year) setValue('birth_year', petData.birth_year);
      if (petData?.birth_month) setValue('birth_month', petData.birth_month);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [petData.birth_year, petData.birth_month])
  );

  return (
    <AddNewDogWrapper containerProps={{ space: 12 }}>
      <VStack alignItems="center" justifyContent={{ base: 'center', md: 'flex-start' }} w="100%">
        <Heading size="titleSmToMd" fontWeight="bold" mb={{ base: '36px', md: '48px' }}>
          When is {petData?.name}'s birthday?
        </Heading>
        <VStack w="100%" alignItems="center">
          {Platform.OS === 'web' ? (
            <HStack
              justifyContent="center"
              alignItems="flex-start"
              space={{ base: '15px', md: '22px' }}
              w={{ base: '100%', md: '472px' }}
            >
              <FormControl isInvalid={'birth_month' in errors} flex={1}>
                <Controller
                  control={control}
                  name="birth_month"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <VStack>
                        <Select
                          innerRef={ref}
                          options={months}
                          onChange={(newValue: any) => {
                            onChange(newValue?.value ? Number(newValue.value) : undefined);
                          }}
                          onBlur={onBlur}
                          name="month"
                          placeholder={<BirthdayMonthPlaceholder />}
                          value={
                            value === undefined
                              ? null // must explicitly set to null to clear react-select
                              : months.find((month) => month.value === value.toString())
                          }
                          blurInputOnSelect
                          autoFocus={!isMobile}
                        />
                        {errors?.birth_month?.message ? (
                          <FormControl.ErrorMessage>
                            {errors?.birth_month?.message}
                          </FormControl.ErrorMessage>
                        ) : null}
                      </VStack>
                    );
                  }}
                />
              </FormControl>
              <FormControl isRequired isInvalid={'birth_year' in errors} flex={1}>
                <Controller
                  control={control}
                  name="birth_year"
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <VStack>
                        <Select
                          options={years}
                          onChange={(newValue: any) => {
                            if (newValue?.value) {
                              setBirthdayError(null);
                            }
                            onChange(newValue?.value ? Number(newValue.value) : undefined);
                            trigger(); // validate month and year together
                          }}
                          onBlur={onBlur}
                          name="year"
                          placeholder={<BirthdayYearPlaceholder />}
                          value={
                            value === undefined
                              ? null // must explicitly set to null to clear react-select
                              : years.find((year) => year.value === value.toString())
                          }
                          blurInputOnSelect
                        />
                        {errors?.birth_year?.message ? (
                          <FormControl.ErrorMessage>
                            {errors?.birth_year?.message}
                          </FormControl.ErrorMessage>
                        ) : null}
                        {birthdayError ? (
                          <FormControl.ErrorMessage>{birthdayError}</FormControl.ErrorMessage>
                        ) : null}
                      </VStack>
                    );
                  }}
                />
              </FormControl>
            </HStack>
          ) : null}
        </VStack>

        {birthMonth ? (
          <Stack w={{ base: '100%', md: '472px' }} mt={{ base: '16px', md: '24px' }}>
            <Pressable
              alignSelf="flex-start"
              onPress={() => {
                reset();
              }}
            >
              <Link textDecoration="underline">
                <Text fontWeight="bold" fontSize="body.md">
                  Clear fields
                </Text>
              </Link>
            </Pressable>
          </Stack>
        ) : null}
      </VStack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
        onPress={handleSubmit(onSubmit)}
        isDisabled={!birthYear || Object.keys(errors).length !== 0}
        isLoading={isSubmitting}
      >
        CONTINUE
      </Button>
    </AddNewDogWrapper>
  );
};
