import { Card } from '@stripe/stripe-js';
import { Box } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
const images = `${ASSETS_URL}/pictos/PaymentTypes/`;

type CardBrandName = Card['brand'];

interface CardBrandLogoProps {
  brand?: CardBrandName;
}

const UnknownCard = () => <Box />;

const URI_BY_BRAND: { [k: string]: string } = {
  mastercard: `Mastercard.svg`,
  visa: `Visa.svg`,
  discover: `Discover.svg`,
  'american express': `Amex.svg`,
  amex: `Amex.svg`,
};

const PADDING_BY_BRAND: { [k: string]: string } = {
  mastercard: '5px',
  visa: '4px',
  'american express': '0px',
  amex: '0px',
};

const CardImage = ({ brand }: { brand: CardBrandName }) => (
  <Image
    resizeMode="contain"
    w="100%"
    h="100%"
    source={{ uri: `${images}${URI_BY_BRAND[brand]}` }}
    alt={brand}
  />
);

export const CardBrandLogo = ({ brand }: CardBrandLogoProps) => {
  const brandCode = brand?.toLowerCase();
  const padding = brandCode ? PADDING_BY_BRAND[brandCode] : '5px';

  return (
    <Box
      w="40px"
      h="30px"
      borderWidth="1px"
      borderRadius="4px"
      borderColor="sntGrey.outline"
      padding={padding}
    >
      {brandCode ? <CardImage brand={brandCode} /> : <UnknownCard />}
    </Box>
  );
};
