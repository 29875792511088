import { useForm, UseFormProps } from 'react-hook-form';

import { UpdateDogFormValues } from './types';

import { UpdateDogFormResolver } from '@/screens';

const defaultValues: Partial<UpdateDogFormValues> = {
  name: undefined,
  neutered: undefined,
  breeds: [],
  birth_year: undefined,
  birth_month: undefined,
  weight: undefined,
  body_type: undefined,
  lifestyle: undefined,
};

export const useUpdateDogForm = (props: UseFormProps<UpdateDogFormValues> = {}) => {
  return useForm<UpdateDogFormValues>({
    resolver: UpdateDogFormResolver, // Update this
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
    ...props,
  });
};
