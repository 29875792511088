import { HStack, IPressableProps, Stack, Text } from 'native-base';
import { Pressable } from 'react-native';

interface AddNewDogButtonProps {
  onPress: IPressableProps['onPress'];
}

export const AddNewDogButton = ({ onPress }: AddNewDogButtonProps) => {
  return (
    <Stack
      backgroundColor="sntBlue.info"
      pt={{ lg: '0px' }}
      marginTop="-1px"
      borderTopWidth="1px"
      w="100%"
    >
      <Pressable onPress={onPress}>
        <HStack justifyContent="center" alignItems="center">
          <Text
            fontSize="16px"
            fontWeight="bold"
            paddingX="8px"
            paddingY="16px"
            textAlign="center"
            textDecorationLine="underline"
          >
            Add New Dog
          </Text>
        </HStack>
      </Pressable>
    </Stack>
  );
};
