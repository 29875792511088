import {
  Box,
  Button,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  IconButton,
  IModalProps,
  Modal,
  ScrollView,
  Spacer,
  Text,
  VStack,
} from 'native-base';
import { useRef } from 'react';

import { Pricing } from './Pricing';

import { ProductType } from '@/api';
import { MerchandiseProduct } from '@/api/types/accountServices';
import { ResponsiveCarousel } from '@/components/Elements';
import { CarouselImage } from '@/components/Elements/Image';
import { ASSETS_URL } from '@/config';
import { useBodyScrollLock } from '@/hooks';
import { capitalize } from '@/utils';

export interface ProductPDPModalProps extends IModalProps {
  product: MerchandiseProduct;
  isTrial?: boolean;
  ctaOnPress?: (arg: any) => void;
  buttonText?: string;
  showCTA?: boolean;
}

function getPDPHeroImages(productType: ProductType, productCode: string) {
  return Array(4)
    .fill('')
    .map((_, i) => `${ASSETS_URL}/pictos/PDP/${capitalize(productType)}/${productCode}/${i}.png`);
}

export const ProductPDPModal = ({
  product,
  isTrial = false,
  ctaOnPress,
  buttonText,
  showCTA = false,
  ...props
}: ProductPDPModalProps) => {
  const { code, name, type, description, price, discounted_price } = product;

  const initialRef = useRef(null);

  const lockRef = useBodyScrollLock({ enabled: props.isOpen });

  const heroImages = getPDPHeroImages(type, code);

  return (
    <Modal size="full" initialFocusRef={initialRef} useRNModal focusable {...props}>
      <Modal.Content
        bg="white"
        px={2}
        my={{ base: '5vh', lg: 4 }}
        h="fit-content"
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={{ base: '95vw', lg: '820px' }}
        borderWidth="2px"
      >
        <ScrollView ref={lockRef}>
          <IconButton
            position="sticky"
            top={4}
            right={8}
            zIndex={1000}
            alignSelf="flex-end"
            p="0px"
            onPress={() => props.onClose?.()}
            icon={
              <CloseIcon
                alt="Close product detail page"
                color="black"
                size={{ base: '11px', md: '14px' }}
              />
            }
          />
          <VStack
            justifyContent="start"
            alignItems="center"
            bgColor="white"
            w={{ base: '95vw', lg: '820px' }}
            p={{ base: 4, lg: 6 }}
            h="fit-content"
            space={{ base: 4, lg: 6 }}
          >
            <ResponsiveCarousel
              data={heroImages}
              width={{ base: 264, md: 460 }}
              itemWidth={{ base: 264, md: 460 }}
              height={{ base: 264, md: 460 }}
              renderItem={({ item }) => {
                return (
                  <VStack
                    width={{ base: 264, md: 460 }}
                    height={{ base: 264, md: 460 }}
                    overflow="hidden"
                  >
                    <CarouselImage
                      source={{ uri: item }}
                      width="100%"
                      height="100%"
                      resizeMode="cover"
                      borderRadius={0}
                      accessibilityLabel={name + ' detail image'}
                    />
                  </VStack>
                );
              }}
            />
            <VStack alignItems="center" space={{ base: 1, lg: 2 }}>
              <Heading size={{ base: 'bodyLg', lg: 'titleSm' }} fontWeight="bold">
                {name}
              </Heading>
              <Pricing
                itemType={product.type}
                pricing={Number(price)}
                pricingUnit={null}
                isTrial={isTrial}
                discountedPrice={discounted_price ? Number(product.discounted_price) : null}
              />
            </VStack>
            <VStack space={{ base: 2, lg: 6 }} w="100%" px={{ base: '0px', lg: '48px' }}>
              <Divider bg="gallery" />
              <Box>
                <Heading size="bodySmToMd" fontWeight="bold" textAlign="left" ref={initialRef}>
                  Overview
                </Heading>
                <Spacer size={{ base: 2, lg: 4 }} />
                <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
                  {`${description}\n\nPlease note: all merch items are final sale.`}
                </Text>
              </Box>
            </VStack>
          </VStack>
          {showCTA ? (
            <HStack
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              w="100%"
              position="sticky"
              px={4}
              pt={6}
              pb={{ base: 4, lg: 6 }}
              bottom={0}
              h="fit-content"
              bg="white"
              style={{
                shadowColor: 'rgba(0,0,0,0.15)',
                shadowOffset: { width: -4, height: -4 },
                shadowRadius: 4,
              }}
            >
              <Button
                w={{ base: '311px', lg: '290px' }}
                borderWidth={{ base: '2px', lg: '3px' }}
                onPress={ctaOnPress}
                variant="primary"
              >
                {buttonText}
              </Button>
            </HStack>
          ) : null}
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};
