import { Stack, Heading, Divider, IStackProps, HStack, Badge, Text, VStack } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image, Stepper } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
import { capitalize, getRecipesProductImageSrc, isOrderProductFree, round } from '@/utils';

interface LineItemPlanCardExtraProps extends IStackProps {
  product: OrderProduct;
  disabled?: boolean;
  isLoading: boolean;
  showDivider?: boolean;
  showStepper?: boolean;
  handlePressStepper?: (...args: any[]) => void;
}

export const LineItemPlanCardExtra = ({
  product,
  disabled,
  isLoading,
  showDivider,
  showStepper,
  handlePressStepper,
  ...props
}: LineItemPlanCardExtraProps) => {
  const productIsFree = isOrderProductFree(product);
  const isMerch = product.product_type === ProductType.MERCH;
  const imageUri = isMerch
    ? `${ASSETS_URL}/pictos/PDP/${capitalize(product.product_type)}/${product.code}/0.png`
    : getRecipesProductImageSrc(product.recipes, false, product.image_uri);

  const getProductName = (op: OrderProduct) => {
    if ([ProductType.SCOOP, ProductType.MATERIAL].includes(op.product_type)) {
      const shortenedName = op.name.replace(' - UnKibble', '').replace(' - Fresh', '');
      return `${op.pet_plan ? `${op.pet_plan.pet_name}'s ` : ''}${shortenedName}`;
    }
    return op.name;
  };

  return (
    <VStack>
      <HStack space={{ base: 2, lg: 4 }} alignItems="center" {...props}>
        <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
          <Image
            source={{ uri: imageUri }}
            w="100%"
            h="100%"
            alt={product.name}
            resizeMode="contain"
            opacity={disabled ? 50 : undefined}
          />
        </Stack>
        <Stack flex={3} space={2} justifyContent="start" alignItems="start">
          <HStack w="100%" alignItems="center" justifyContent="space-between">
            <Heading
              size="bodyMdToLg"
              fontWeight="bold"
              color={disabled ? 'sntGrey.primary' : undefined}
            >
              {getProductName(product)}
            </Heading>
            {!productIsFree ? (
              <Badge
                variant="smallInfoPillSecondary"
                _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
                borderColor={disabled ? 'sntGrey.primary' : undefined}
              >
                {`$${round(product.quantity * Number(product.unit_price), 2)}`}
              </Badge>
            ) : null}
          </HStack>
          {isMerch ? (
            showStepper && !productIsFree ? (
              <Stepper
                alignSelf="flex-start"
                maxW={{ base: '45%', lg: '33%' }}
                controlledQuantity={product.quantity}
                onPressDec={() => handlePressStepper?.(product, 'decrease')}
                onPressInc={() => handlePressStepper?.(product, 'increase')}
                isDisabled={disabled || isLoading}
                disabledPlus={isLoading}
                disabledMinus={isLoading}
              />
            ) : (
              <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
                {product.quantity} item
                {product.quantity !== 1 && 's'}
              </Text>
            )
          ) : null}
        </Stack>
      </HStack>
      {showDivider ? (
        <Divider bgColor="gallery" w="85%" mt={{ base: 4, lg: 6 }} alignSelf="center" />
      ) : null}
    </VStack>
  );
};
