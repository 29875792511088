import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import { Control, FormState, UseFormTrigger } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

import { UpdateDogFormValues } from './types';

import { BodyTypeCode, LifestyleCode } from '@/api';
import { AnimatedBlock, LabeledInput, LabeledSelect } from '@/components/Elements';
import { LabeledBreedSelect } from '@/components/Elements/LabeledBreedSelect';
import { LabeledInputSideLabel } from '@/components/Elements/LabeledInputSideLabel';
import { LabeledOptionSelector } from '@/components/Elements/LabeledOptionSelector';
import { getBirthdayMonths, getBirthdayYears } from '@/utils';

interface UpdateDogFormProps {
  petName: string;
  control: Control<UpdateDogFormValues>;
  formState: FormState<UpdateDogFormValues>;
  trigger: UseFormTrigger<UpdateDogFormValues>;
}

export const UpdateDogForm = ({ petName, control, formState, trigger }: UpdateDogFormProps) => {
  const neuteredOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const years = getBirthdayYears();
  const months = getBirthdayMonths();
  const bodyTypeOptions = [
    { value: BodyTypeCode.ALittleSkinny, label: 'Skinny' },
    { value: BodyTypeCode.JustRight, label: 'Just Right' },
    { value: BodyTypeCode.ALittleChubby, label: 'Chubby' },
  ];
  const lifestyleOptions = [
    { value: LifestyleCode.Lazy, label: 'Lazy' },
    { value: LifestyleCode.Active, label: 'Active' },
    { value: LifestyleCode.VeryActive, label: 'Very Active' },
  ];

  const { errors, dirtyFields } = formState;

  const onKeyPressWeightInput = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // only allow numbers to be entered
    const nativeEvent = event.nativeEvent;
    // if the key press is a number, allow it
    if (/[0-9]/.test(nativeEvent.key)) {
      return;
    }
    // if the key press is a backspace, allow it
    if (nativeEvent.key === 'Backspace') {
      return;
    }

    // otherwise, prevent the key press
    (nativeEvent as any).preventDefault();
  };

  return (
    <VStack space={{ base: '16px', lg: '24px' }}>
      {/* pet name */}
      <LabeledInput
        name="name"
        control={control}
        label="Name"
        placeholder="Dog's name"
        error={errors.name?.message}
        inputProps={{ maxLength: 12 }}
      />
      <Divider bg="gallery" />
      {/* is neutered */}
      <LabeledOptionSelector
        name="neutered"
        control={control}
        label={`Is ${petName} neutered?`}
        options={neuteredOptions}
      />
      <Divider bg="gallery" />
      {/* breed select */}
      <LabeledBreedSelect control={control} petName={petName} />
      <Divider bg="gallery" />
      {/* birthday */}
      <HStack alignItems="start" space={{ base: '16px', lg: '24px' }}>
        <LabeledSelect
          flex={1}
          name="birth_month"
          control={control}
          options={months}
          label="Month"
          placeholder="Month (optional)"
          error={errors.birth_month?.message}
          textStyling={{
            fontFamily: 'MonumentGrotesk-Medium',
          }}
          selectProps={{ blurInputOnSelect: true }}
        />
        <LabeledSelect
          flex={1}
          name="birth_year"
          control={control}
          options={years}
          label="Year"
          placeholder="Year"
          error={errors.birth_year?.message}
          textStyling={{
            fontFamily: 'MonumentGrotesk-Medium',
          }}
          selectProps={{ blurInputOnSelect: true, onBlur: () => trigger() }}
        />
      </HStack>
      <Divider bg="gallery" />
      {/* weight */}
      <LabeledInputSideLabel
        name="weight"
        control={control}
        label="Weight"
        inputLabel="pounds"
        placeholder="Enter weight"
        error={errors.weight?.message}
        inputProps={{
          keyboardType: 'numeric',
          maxLength: 3,
          onKeyPress: onKeyPressWeightInput,
        }}
      />
      <Divider bg="gallery" />
      {/* body type */}
      <Stack space={{ base: '4px', lg: '8px' }}>
        <LabeledOptionSelector
          name="body_type"
          control={control}
          label="Body Type"
          options={bodyTypeOptions}
        />
        {'weight' in dirtyFields && !('weight' in errors) ? (
          <AnimatedBlock showBlock>
            <Text size="bodySmToMd">{`Weight changes can affect body type! Take a moment to make sure ${petName}’s body type still looks right.`}</Text>
          </AnimatedBlock>
        ) : null}
      </Stack>

      <Divider bg="gallery" />
      {/* lifestyle */}
      <LabeledOptionSelector
        name="lifestyle"
        control={control}
        label="Lifestyle"
        options={lifestyleOptions}
      />
    </VStack>
  );
};
