import axios from 'axios';
import {
  Stack,
  Flex,
  Divider,
  FormControl,
  InfoOutlineIcon,
  Switch,
  Tooltip,
  ScrollView,
  Button,
} from 'native-base';
import { useEffect, useRef } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';

import { FormSubmitButton } from '../../FormSubmitButton';
import { AddressForm, useAddressForm } from './AddressForm';
import { AddressFormValues } from './AddressForm/types';
import { useConfirmSetPrimaryModal } from './ConfirmSetPrimaryModal';
import { useRemoveAddressModal } from './RemoveAddressModal';

import { useRemoveAddress, useUpdateAddress } from '@/api/services';
import { displayToast } from '@/components/Elements';
import { useAccount, useValidateAddressQuery } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { sendErrorReport, defaultErrorHandler, invalidAddressErrorHandler } from '@/utils';

export const EditAddress = ({
  navigation,
  route: {
    params: { addressId, successRedirect, successRedirectParams = {} },
  },
}: ProtectedScreenProps<'EditAddress'>) => {
  const account = useAccount();
  const hasLoggedChange = useRef(false);

  const address = account.addresses.find((addr) => addr.id === addressId);
  const { mutateAsync: updateAddress, isLoading: isUpdateLoading } = useUpdateAddress({
    noErrorToast: true,
  });
  const { mutateAsync: removeAddress, isLoading: isRemoveLoading } = useRemoveAddress();

  const { handleSubmit, formState, control, trigger, setValue, watch, setError, resetField } =
    useAddressForm({
      defaultValues: address || {},
      mode: 'onChange',
    });

  useEffect(() => {
    if (hasLoggedChange.current) return;

    const { isDirty, dirtyFields } = formState;

    const relevantFields = ['address1', 'address2', 'city', 'zip'] as const;
    const hasRelevantFieldChanged =
      isDirty && relevantFields.some((field) => !!dirtyFields[field]) && !dirtyFields.state;

    if (hasRelevantFieldChanged) {
      hasLoggedChange.current = true;
      resetField('state', { defaultValue: '' });
      trigger();
    }
  }, [formState, setValue]);

  const {
    validateAddress: { mutateAsync: validateAddress },
  } = useValidateAddressQuery();

  useEffect(() => {
    navigation.setOptions({ title: address?.address1 });
    trigger();
  }, [trigger, navigation, address?.address1]);

  const navigate = () => {
    if (successRedirect) {
      navigation.navigate(successRedirect, successRedirectParams);
    } else {
      navigation.navigate('AddressList');
    }
  };

  const onSubmit: SubmitHandler<AddressFormValues> = async (data) => {
    try {
      const validated = await validateAddress(data);
      validated.data['is_primary'] = data['is_primary'];
      validated.data['address2'] = data['address2'];

      await updateAddress({ data: validated.data, addressId });
      navigate();
      displayToast({
        message: 'Your shipping address has been updated.',
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const resData = e.response?.data;
        const errors = resData?.errors;
        if (Array.isArray(errors)) {
          // handle cases like mismatching state + zipcode
          const fullAddressErrors = errors.filter((error) => error?.name === 'full_address');
          if (fullAddressErrors.length > 0) {
            setError('address1', {
              type: 'string',
              message: 'There was a problem with the address you submitted.',
            });
          }
          for (const error of errors) {
            if (error.name === 'zip_code') {
              setError('zip', { type: 'string', message: error.message });
            } else {
              setError(error.name, { type: 'string', message: error.message });
            }
          }

          if (errors.length >= 1) {
            invalidAddressErrorHandler();
          }
        }
      } else {
        defaultErrorHandler();
      }
    }
  };

  const { Modal: ConfirmSetPrimaryModal, setShowModal: setShowConfirmSetPrimaryModal } =
    useConfirmSetPrimaryModal();

  const onSaveClick = () => {
    if (!address?.is_primary && watch('is_primary')) {
      setShowConfirmSetPrimaryModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onPressConfirmSetPrimary = () => {
    setShowConfirmSetPrimaryModal(false);
    handleSubmit(onSubmit)();
  };

  const { Modal: RemoveAddressModal, setShowModal: setShowRemoveModal } = useRemoveAddressModal();
  const onRemoveConfirm = async () => {
    try {
      setShowRemoveModal(false);
      await removeAddress(addressId, {
        onSuccess: () => {
          navigate();
          displayToast({
            message: `${address?.address1} has been removed from your shipping addresses.`,
          });
        },
      });
    } catch (error) {
      sendErrorReport(error);
    }
  };

  if (!address) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      alignSelf="center"
      h="100%"
      w={{ base: '100%', md: '540px' }}
      px={{ base: '16px', md: '0px' }}
      pb="24px"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        space={{ base: '16px', lg: '24px' }}
        w="100%"
        h="100%"
      >
        <Stack space={{ base: '16px', lg: '24px' }}>
          <AddressForm control={control} formState={formState} />
          {!address.is_primary ? (
            <Stack space={{ base: '16px', lg: '24px' }}>
              <Divider />
              <FormControl>
                <Controller
                  control={control}
                  name="is_primary"
                  render={({ field }) => {
                    return (
                      <Stack direction="column" alignItems="start" w="100%">
                        <Stack direction="row" w="100%" justifyContent="space-between">
                          <FormControl.Label
                            htmlFor="is_primary"
                            variant="portal"
                            _text={{
                              fontSize: { base: 'body.md', lg: 'body.lg' },
                              lineHeight: { base: 'body.md', lg: 'body.lg' },
                            }}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            m="0px"
                          >
                            Set as primary{' '}
                            <Tooltip
                              label="By setting a new address as primary, all future orders will be shipped to your new primary address."
                              placement="top"
                              openDelay={200}
                              maxW="75vw"
                              ml="8px"
                            >
                              <InfoOutlineIcon />
                            </Tooltip>
                          </FormControl.Label>
                          <Switch
                            {...field}
                            onToggle={() => setValue('is_primary', !field.value)}
                          />
                        </Stack>
                      </Stack>
                    );
                  }}
                />
              </FormControl>
              <Divider />
              <Stack>
                <Button
                  variant="underlineMini"
                  onPress={() => setShowRemoveModal(true)}
                  _text={{
                    fontSize: { base: 'body.sm', lg: 'body.md' },
                    lineHeight: { base: 'body.sm', lg: 'body.md' },
                  }}
                >
                  Remove address
                </Button>
                <ScrollView>
                  <RemoveAddressModal
                    onConfirm={onRemoveConfirm}
                    onClose={() => setShowRemoveModal(false)}
                    isBackButtonDisabled={isRemoveLoading}
                    isConfirmButtonDisabled={isRemoveLoading}
                  />
                </ScrollView>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
        <Flex
          w={{ base: '100%', lg: '381px' }}
          direction="column"
          justify="center"
          align="center"
          alignSelf="Center"
        >
          <FormSubmitButton
            w="100%"
            onPress={onSaveClick}
            isDisabled={!formState.isValid}
            isLoading={isUpdateLoading || isRemoveLoading}
          >
            Save Changes
          </FormSubmitButton>
        </Flex>
      </Stack>
      <ConfirmSetPrimaryModal
        onClose={() => setShowConfirmSetPrimaryModal(false)}
        onConfirm={() => onPressConfirmSetPrimary()}
        isBackButtonDisabled={isUpdateLoading}
        isConfirmButtonDisabled={isUpdateLoading}
      />
    </Stack>
  );
};
