import { useMutation } from 'react-query';

import { orderProductToApiPayload, putOrderProducts } from './putOrderProducts';

import { OrderProductType } from '@/api/types';
import { useAccount } from '@/hooks';
import { sortOrderByChargedOrScheduled } from '@/utils';

export const useUpdateBufferBagProductsToOrder = () => {
  const account = useAccount();
  const previewOrders = account?.orders?.upcoming?.sort(sortOrderByChargedOrScheduled);

  return useMutation(
    ({
      updatedProducts,
      selectedOrderId,
    }: {
      updatedProducts: {
        productCode: string;
        quantity: number;
        petPlanId?: string;
      }[];
      selectedOrderId: string;
    }) => {
      const orderToUpdate = previewOrders.find((order) => order.id === selectedOrderId);
      const existingDiscountCodes = orderToUpdate?.discounts?.[0]?.discount.code || '';
      const prevProducts = orderToUpdate?.products || [];
      const productCodesToUpdate = updatedProducts.map((update) => update.productCode);

      const productsPayload = prevProducts
        .filter((product) => !productCodesToUpdate.includes(product.code))
        .map((product) => orderProductToApiPayload(product));

      updatedProducts.forEach((product) => {
        if (product.quantity > 0) {
          productsPayload.push({
            pet_plan: product.petPlanId,
            product_code: product.productCode,
            quantity: product.quantity,
            type: OrderProductType.ONETIME,
          });
        }
      });

      return putOrderProducts({
        accountId: account?.id,
        orderId: selectedOrderId,
        orderType: orderToUpdate?.order_type,
        products: productsPayload,
        discountCodes: existingDiscountCodes ? [existingDiscountCodes] : undefined,
      });
    }
  );
};
