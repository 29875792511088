import { datadogLogs } from '@datadog/browser-logs';
import { createNativeStackNavigator, NativeStackHeaderProps } from '@react-navigation/native-stack';
import { useMemo } from 'react';

import { OrdersTab, RescheduleDelivery, DogsTab, OrderDetailPage, ShopTab } from '../';
import { AddNewDogQuestionnaire, DogSelectFoodType, DogSelectRecipe } from '../DogsAndPlans';
import { UpdateDogProfile } from '../DogsAndPlans/UpdateDogProfile';
import { BufferBags } from '../Orders';
import { AccountTab } from './AccountTab';
import { AddCreditCard, EditCreditCard, PaymentMethodList } from './Billing';
import { ChangePassword } from './ChangePassword';
import { FormScreenHeader, CustomHeaderOptions } from './FormScreenHeader';
import { HomeTab } from './HomeTab';
import {
  AdjustPortionSize,
  ChangePlanStatus,
  ManageActivePlans,
  ManageInactivePlans,
  StopPlanSubSurvey,
  StopPlanSurvey,
  ReactivatePlan,
  ReactivatePlanRescheduleDelivery,
  SwapToTopper,
  SnoozePlan,
  TryNewRecipe,
  StayForBenefits,
} from './ManageDogPlans';
import { RushADelivery } from './ManageDogPlans/ChangePlanStatus/RushADelivery';
import { ChangeRecipe } from './ManageDogPlans/ChangeRecipe';
import { NotificationPreferences } from './Notifications';
import { PersonalInformation } from './PersonalInformation';
import { AddNewAddress, AddressList, EditAddress } from './Shipping';

import { useAuth } from '@/hooks';
import { linkingOptions } from '@/linkingOptions';
import { ProtectedStackParamList } from '@/types';
const { logger } = datadogLogs;

export const AccountStack = () => {
  const Stack = createNativeStackNavigator<ProtectedStackParamList>();

  const { refetchUser } = useAuth();
  const requestedUrl = localStorage.getItem('requestedUrl');
  let page: keyof ProtectedStackParamList = 'Home';
  if (requestedUrl) {
    const pages = requestedUrl.split('/');
    const portalIndex = pages.indexOf('PortalScreens');
    if (portalIndex !== -1 && portalIndex + 1 < pages.length) {
      page = pages[portalIndex + 1].split('?')[0] as keyof ProtectedStackParamList;
    } else {
      page = page.split('?')[0] as keyof ProtectedStackParamList;
    }
    const protectedScreens = linkingOptions.config.screens.Portal.screens.ProtectedScreens.screens;
    if (!(page in protectedScreens)) {
      page = 'Home';
      localStorage.removeItem('requestedUrl');
      logger.warn('Deeplink requestedUrl not found in ProtectedScreens', { requestedUrl });
    }
  }

  const defaultRoute = useMemo(() => page ?? 'Home', [page]);
  return (
    <Stack.Navigator initialRouteName={defaultRoute}>
      <Stack.Screen
        name="Home"
        options={{ headerShown: false }}
        component={HomeTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        name="Orders"
        options={{ headerShown: false }}
        component={OrdersTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        options={({ route }) =>
          ({
            header: FormScreenHeader,
            title: route.params?.editBufferBag ? 'Edit Extra Meals' : 'Add Extra Meals',
            titleProps: { size: 'titleSmToMd' },
          } as CustomHeaderOptions)
        }
        name="BufferBags"
        component={BufferBags}
      />
      <Stack.Screen
        name="Dogs"
        options={{ headerShown: false }}
        component={DogsTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        name="AddNewDog"
        options={{ headerShown: false }}
        component={AddNewDogQuestionnaire}
      />
      <Stack.Screen name="Shop" options={{ headerShown: false }} component={ShopTab} />
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen name="DogSelectFoodType" component={DogSelectFoodType} />
        <Stack.Screen name="DogSelectRecipe" component={DogSelectRecipe} />
      </Stack.Group>
      <Stack.Screen
        name="Account"
        options={{ headerShown: false }}
        component={AccountTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Personal Information',
        }}
        name="PersonalInformation"
        component={PersonalInformation}
      />
      {/* Shipping Address screens */}
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen
          name="AddressList"
          component={AddressList}
          options={{ title: 'Change Shipping Address' }}
        />
        <Stack.Screen
          name="AddNewAddress"
          component={AddNewAddress}
          options={{ title: 'Add New Address' }}
        />
        <Stack.Screen name="EditAddress" component={EditAddress} />
      </Stack.Group>
      {/* Billing screens */}
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen
          name="PaymentMethodList"
          component={PaymentMethodList}
          options={{ title: 'Change Payment Method' }}
        />
        <Stack.Screen
          name="AddCreditCard"
          component={AddCreditCard}
          options={{ title: 'Credit Card' }}
        />
        <Stack.Screen
          name="EditCreditCard"
          component={EditCreditCard}
          options={{ title: 'Credit Card' }}
        />
      </Stack.Group>

      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Manage or Cancel Plans',
        }}
        name="ManageActivePlans"
        component={ManageActivePlans}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Review and Confirm',
        }}
        name="ReactivatePlan"
        component={ReactivatePlan}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="ReactivatePlanRescheduleDelivery"
        component={ReactivatePlanRescheduleDelivery}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Manage Inactive Plans',
        }}
        name="ManageInactivePlans"
        component={ManageInactivePlans}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="AdjustPortionSize"
        component={AdjustPortionSize}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="ChangeRecipe"
        component={ChangeRecipe}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: '',
        }}
        name="ChangePlanStatus"
        component={ChangePlanStatus}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="StopPlanSurvey"
        component={StopPlanSurvey}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: `Let's Get Specific`,
        }}
        name="StopPlanSubSurvey"
        component={StopPlanSubSurvey}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 12 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Let Us Help With Cost',
        }}
        name="SwapToTopper"
        component={SwapToTopper}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Take a Break',
        }}
        name="SnoozePlan"
        component={SnoozePlan}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Rush a Delivery',
        }}
        name="RushADelivery"
        component={RushADelivery}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Try a New Recipe — 30% OFF!',
        }}
        name="TryNewRecipe"
        component={TryNewRecipe}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Stick with Spot & Tango!',
        }}
        name="StayForBenefits"
        component={StayForBenefits}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Notification Preferences',
        }}
        name="NotificationPreferences"
        component={NotificationPreferences}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Change Password',
        }}
        name="ChangePassword"
        component={ChangePassword}
      />
      <Stack.Screen
        name="RescheduleDelivery"
        component={RescheduleDelivery}
        options={{
          header: FormScreenHeader,
          title: 'Reschedule',
        }}
      />
      <Stack.Screen
        name="OrderDetailPage"
        component={OrderDetailPage}
        options={{
          header: FormScreenHeader,
        }}
      />
      <Stack.Screen
        name="UpdateDogProfile"
        component={UpdateDogProfile}
        options={{
          header: FormScreenHeader,
        }}
      />
    </Stack.Navigator>
  );
};
