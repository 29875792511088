import { useMutation } from 'react-query';

import { orderProductToApiPayload, putOrderProducts } from './putOrderProducts';

import { OrderProductType } from '@/api/types';
import { useAccount } from '@/hooks';
import { sortOrderByChargedOrScheduled } from '@/utils';

export const useUpdateOrderProducts = () => {
  const account = useAccount();
  const previewOrders = account?.orders?.upcoming?.sort(sortOrderByChargedOrScheduled);
  const nextPreviewOrder = previewOrders[0];

  return useMutation(
    ({
      productCode,
      mode,
      petPlanId,
    }: {
      productCode: string;
      mode: 'increase' | 'decrease';
      petPlanId?: string;
    }) => {
      const orderToUpdate = petPlanId
        ? previewOrders.find((order) =>
            order.products.some((op) => op.pet_plan?.id === petPlanId && op.code === productCode)
          ) || nextPreviewOrder
        : nextPreviewOrder;

      const existingDiscountCodes = orderToUpdate?.discounts?.[0]?.discount.code || '';
      const prevProducts = orderToUpdate.products;
      let newQuantity = 0;

      const alreadyAddedProduct = prevProducts.find((product) => {
        const haveSameCode = product.code === productCode;
        if (petPlanId) return product.pet_plan?.id === petPlanId && haveSameCode;
        return haveSameCode;
      });

      const petPlanIdToUpdate = alreadyAddedProduct
        ? alreadyAddedProduct.pet_plan?.id
        : prevProducts[0].pet_plan?.id;

      if (alreadyAddedProduct) {
        newQuantity =
          mode === 'increase'
            ? alreadyAddedProduct?.quantity + 1
            : alreadyAddedProduct?.quantity - 1;
      } else {
        newQuantity = 1;
      }

      const productsPayload = prevProducts
        .filter((product) => product.code !== productCode)
        .map((product) => orderProductToApiPayload(product));
      if (newQuantity > 0) {
        productsPayload.push({
          pet_plan: petPlanIdToUpdate,
          product_code: productCode,
          quantity: newQuantity,
          type: OrderProductType.ONETIME,
        });
      }

      return putOrderProducts({
        accountId: account?.id,
        orderId: orderToUpdate?.id,
        orderType: orderToUpdate?.order_type,
        products: productsPayload,
        discountCodes: existingDiscountCodes ? [existingDiscountCodes] : undefined,
      });
    }
  );
};
