import { FormControl, IFormControlProps, IStackProps, VStack } from 'native-base';
import { Control, Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';

export interface LabeledFormControlProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends IFormControlProps {
  children: ControllerProps<TFieldValues, TName>['render'];
  control?: Control<TFieldValues, TName>;
  name: TName;
  label?: string;
  stackProps?: IStackProps;
}

/**
 * Wraps a react-hook-form controlled element and includes a label
 */
export const LabeledFormControl = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  children,
  control,
  name,
  label,
  stackProps = {},
  ...formControlProps
}: LabeledFormControlProps<TFieldValues, TName>) => (
  <FormControl {...formControlProps}>
    <Controller<TFieldValues, TName>
      control={control}
      name={name}
      render={(renderArgs) => {
        return (
          <VStack justifyContent="center" alignItems="start" w="100%" space="8px" {...stackProps}>
            <FormControl.Label
              htmlFor={name}
              variant="portal"
              m={0}
              _text={{
                fontSize: { base: 'body.md', lg: 'body.lg' },
                lineHeight: { base: 'body.md', lg: 'body.lg' },
              }}
            >
              {label}
            </FormControl.Label>

            {children(renderArgs)}
          </VStack>
        );
      }}
    />
  </FormControl>
);
