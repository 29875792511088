import { Text, Stack } from 'native-base';

import { Image, VerticalPortalPopUp, VerticalPortalPopUpProps } from '@/components/Elements';
import { ASSETS_URL } from '@/config';
import { HOW_TO_CONTACT_SUPPORT_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';

const SpecialistContent = () => {
  const boldStyle = { fontFamily: 'primary', fontWeight: 'bold' };
  return (
    <Stack w="100%" px={{ base: 0, md: 6 }} pt={{ base: 0, md: 8 }} pb={{ base: 0, md: 4 }}>
      <Text
        color="black"
        size="bodySmToMd"
        textAlign="center"
        fontFamily="secondary"
        px={{ base: 0, md: 6 }}
      >
        <Text {...boldStyle}>2/3 CUSTOMERS</Text> who spoke with one of our Specialists found a{' '}
        <Text {...boldStyle}>LONG TERM SOLUTION</Text> for their dog.
      </Text>
      <Stack mt={4} w="100%" position="relative" alignSelf="center" justifyContent="center">
        <Image
          source={{
            uri: `${ASSETS_URL}/pictos/CancellationIntervention/speak_with_specialist.png`,
          }}
          w="451px"
          h={{ base: '271px', md: '389px' }}
          resizeMode="contain"
        />
      </Stack>
    </Stack>
  );
};

interface SpeakWithSpecialistModalProps extends Omit<VerticalPortalPopUpProps, 'heading'> {
  petId: string;
  cancellationReason?: string;
  cancellationReasonSecondary?: string;
  cancellationReasonComment?: string;
}

export const SpeakWithSpecialistModal = ({
  petId,
  cancellationReason,
  cancellationReasonSecondary,
  cancellationReasonComment,
  ...props
}: SpeakWithSpecialistModalProps) => {
  const account = useAccount();
  const handlePrimaryPress = () => {
    segment.trackEvent('Cancellation Flow - Selected Contact CX', {
      account_id: account.id,
      email: account.email,
      pet_id: petId,
      cancellation_reason: cancellationReason,
      cancellation_reason_secondary: cancellationReasonSecondary,
      cancellation_reason_comment: cancellationReasonComment,
    });
    window.open(HOW_TO_CONTACT_SUPPORT_URL, '_self');
  };
  return (
    <VerticalPortalPopUp
      heading="We're Here to Help"
      body={<SpecialistContent />}
      primaryCtaText="SPEAK WITH A SPECIALIST"
      onPrimaryPress={handlePrimaryPress}
      secondaryCtaText="Confirm cancellation"
      {...props}
    />
  );
};
