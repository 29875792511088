import {
  CloseIcon,
  Divider,
  HStack,
  Heading,
  IconButton,
  Modal,
  Stack,
  Box,
  Text,
  Button,
} from 'native-base';
import { useEffect, useState } from 'react';

import { Step, StepProps } from './Step';

import { markViewedPortalTutorial } from '@/api';
import { ASSETS_URL } from '@/config';
import { useIsMobile } from '@/hooks';

type OnBoardingModalProps = {
  onClose: () => void;
};

const urlToImageInStep = (type: string, name = '') => {
  return `${ASSETS_URL}/pictos/OnboardingModal/${type}_${name || 'placeholder'}.png`;
};

const steps: StepProps[] = [
  {
    subtitle: "We've redesigned the experience to make it even easier to manage your pup's plan!",
    description: (
      <Text textAlign="center" size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        From the new <Text fontWeight="bold">Home</Text> tab, you can now see an overview of your
        current and upcoming orders.
      </Text>
    ),
    image: {
      desktop: urlToImageInStep('desktop', 'home'),
      mobile: urlToImageInStep('mobile', 'home'),
    },
  },
  {
    subtitle: 'Need to reschedule your next order?',
    description: (
      <Text textAlign="center" size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        Navigate to the <Text fontWeight="bold">Orders</Text> tab and select “Reschedule” to update
        your next order date.
      </Text>
    ),
    image: {
      desktop: urlToImageInStep('desktop', 'orders'),
      mobile: urlToImageInStep('mobile', 'orders'),
    },
  },
  {
    subtitle: "Want to make changes to your pup's plan?",
    description: (
      <Text textAlign="center" size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        Navigate to the <Text fontWeight="bold">Dogs</Text> tab to change portion sizes + recipes,
        view your dog's feeding guidelines, and more.
      </Text>
    ),
    image: {
      desktop: urlToImageInStep('desktop', 'dogs'),
      mobile: urlToImageInStep('mobile', 'dogs'),
    },
  },
  {
    subtitle: "Upgrade your dog's plan with extras",
    description: (
      <Text textAlign="center" size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        Scroll down on the <Text fontWeight="bold">Dogs</Text> tab to add tasty Snacks and
        Supplements to your dog's plan.
      </Text>
    ),
    image: {
      desktop: urlToImageInStep('desktop', 'dogs_second'),
      mobile: urlToImageInStep('mobile', 'dogs_second'),
    },
  },
  {
    subtitle: 'Manage your account',
    description: (
      <Text textAlign="center" size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        To manage your shipping and billing information, login details, and more, visit the{' '}
        <Text fontWeight="bold">Account</Text> tab.
      </Text>
    ),
    image: {
      desktop: urlToImageInStep('desktop', 'account'),
      mobile: urlToImageInStep('mobile', 'account'),
    },
  },
];

export const OnboardingModal = ({ onClose }: OnBoardingModalProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    markViewedPortalTutorial();
  }, []);

  const isMobile = useIsMobile();
  const defaultTitle = (
    <Heading textAlign="center" size="bodyMlToTitleSm" fontWeight="bold">
      Welcome to the new Spot & Tango Customer Portal!
    </Heading>
  );
  const mobileTitle = (
    <Stack>
      <Heading textAlign="center" size="bodyMlToTitleSm" fontWeight="bold">
        Welcome to the new
      </Heading>
      <Heading textAlign="center" size="bodyMlToTitleSm" fontWeight="bold">
        Spot & Tango Customer Portal!
      </Heading>
    </Stack>
  );

  const stepsLength = steps.length;

  const goToNextStep = () => {
    if (currentStepIndex + 1 === stepsLength) {
      onClose();
    } else {
      setCurrentStepIndex((prev) => (prev + 1 < stepsLength ? prev + 1 : prev));
    }
  };

  const title = isMobile ? mobileTitle : defaultTitle;
  return (
    <Modal isOpen size="xl" useRNModal>
      <Modal.Content maxW="800px" maxH="690px" p="24px">
        <HStack justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'}>
          <Box />
          {title}
          <IconButton size="24px" icon={<CloseIcon color="black" />} onPress={onClose} />
        </HStack>
        <Divider bg="gallery" my={{ base: '16px', md: '24px' }} />
        <Step
          subtitle={steps[currentStepIndex].subtitle}
          description={steps[currentStepIndex].description}
          image={steps[currentStepIndex].image}
        />
        <Stack alignItems="center">
          <Button variant="hugPrimary" onPress={goToNextStep} size="bodyMlToLg">
            {currentStepIndex + 1 === stepsLength
              ? "LET'S GO"
              : `NEXT (${currentStepIndex + 1}/${stepsLength})`}
          </Button>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};
