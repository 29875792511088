import { datadogLogs } from '@datadog/browser-logs';
import { useIsFocused } from '@react-navigation/native';
import { VStack, Heading, Stack, Box, HStack } from 'native-base';
import { useState, useEffect } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { RecipeFull, RecipeType, useRecipes } from '@/api';
import { RecipeCard, PDPModal, Button, displayToast, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogSupplements = ({ navigation }: AddNewDogScreenProps<'Supplements'>) => {
  const account = useAccount();
  const { petData, supplements, discountCode, saveAddOnRecipes } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.SUPPLEMENTS);

  const [selectedSupplements, setSelectedSupplements] = useState<string[]>([]);
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [detailSupplement, setDetailSupplement] = useState<RecipeFull>();

  const onSubmit = (supplements: string[]) => {
    try {
      saveAddOnRecipes(supplements, RecipeType.SUPPLEMENT);
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's supplements`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's supplements, account_id=${account.id}`, { error });
    }
  };

  const { data: allSupplements, isLoading: isLoadingSupplements } = useRecipes({
    recipeType: 'supplements',
    accountId: account.id,
    discount: discountCode ?? '',
  });

  const updateSupplements = (id: string) => {
    if (selectedSupplements.some((selected) => selected === id)) {
      // supplement deselected
      setSelectedSupplements(selectedSupplements.filter((selected) => selected !== id));
    } else {
      // supplement selected
      setSelectedSupplements(selectedSupplements.concat([id]));
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && supplements) {
      setSelectedSupplements(Object.keys(supplements));
    }
  }, [isFocused, supplements]);

  if (isLoadingSupplements || !(petData && allSupplements && allSupplements.length > 0)) {
    return null;
  }
  return (
    <>
      <AddNewDogWrapper
        containerProps={{
          space: { base: 6, md: 12 },
          justifyContent: 'flex-start',
          pb: { base: '800px', lg: '790px' },
        }}
      >
        <VStack space="16px" justifyContent="center" alignItems="center">
          <Heading size="titleSmToMd" fontWeight="bold" textAlign="center">
            Want to add Supplements to {petData.name}'s Plan?
          </Heading>
          <Heading size="bodySmToMd" fontFamily="secondary" textAlign="center">
            Meet our line of tasty, easy-to-feed Supplements. They're packed with key active
            ingredients backed by clinical studies and are free of artificial additives and top
            allergens.
          </Heading>
        </VStack>
        <Stack
          flexDirection={{ base: 'column', lg: 'row' }}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          w="100%"
          pb={{ base: '160px', md: '200px' }}
        >
          {allSupplements.map((supplement, i) => (
            <Box
              key={i + 'container' + supplement.id}
              alignItems="center"
              w={{ base: '100%', md: 'auto', lg: 'auto' }}
            >
              <RecipeCard
                w={{ base: '100%', md: '350px', lg: '324px' }}
                h={{ base: '142px', md: '175px', lg: 'inherit' }}
                minH={{ base: '142px', md: '175px', lg: '378px' }}
                recipe={supplement}
                key={i + supplement.id}
                pricing={
                  supplement.discounted_price !== null
                    ? Number(supplement.discounted_price)
                    : Number(supplement.price)
                }
                strikethroughPrice={
                  supplement.discounted_price !== null
                    ? Number(supplement.default_product?.price.price)
                    : null
                }
                selected={selectedSupplements.some((selected) => selected === supplement.id)}
                onPress={() => updateSupplements(supplement.id)}
                pricingUnit="pack"
                underlineButton={{
                  text: 'View Details',
                  onPress: () => {
                    setShowPDPModal(true);
                    setDetailSupplement(supplement);
                  },
                }}
              />
            </Box>
          ))}
        </Stack>
      </AddNewDogWrapper>
      {detailSupplement && (
        <PDPModal
          recipe={detailSupplement}
          buttonText="Select Supplement"
          prices={[
            detailSupplement.discounted_price !== null
              ? detailSupplement.discounted_price
              : detailSupplement.price,
          ]}
          pricingUnits={['pack']}
          updateState={() => {
            if (
              !selectedSupplements.some((selectedId: string) => selectedId === detailSupplement.id)
            ) {
              setSelectedSupplements(selectedSupplements.concat([detailSupplement.id]));
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      )}
      <Stack
        justifyContent="center"
        alignItems="center"
        space={{ base: 4, lg: 6 }}
        w="100%"
        variant="stickyFooter"
        position="fixed"
        px={4}
      >
        <HStack w={{ base: '100%', lg: '336px' }}>
          <Button
            h={{ base: '52px', lg: '56px' }}
            isDisabled={isLoadingSupplements}
            size="bodyMdToLg"
            w="100%"
            onPress={() => {
              onSubmit(selectedSupplements);
            }}
          >
            CONTINUE
          </Button>
        </HStack>
      </Stack>
    </>
  );
};
