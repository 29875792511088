import { HStack, IImageProps, Stack, Text, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { Image } from '@/components/Elements';
import { Star } from '@/components/Icons';
import { ASSETS_URL } from '@/config';

type reviewContents = { name: string; text: string; imageSource: IImageProps['source'] };

const reviews = [
  {
    name: 'MaryAnn, NY',
    text: '“Spot & Tango is the third fresh food service I’ve tried. The only fresh food company I’ve seen that offers a traditional & dry choice. This is a keeper for my little one!”',
    imageSource: { uri: `${ASSETS_URL}/pictos/Avatars/unkibble-review-maryann.png` },
  },
  {
    name: 'Holly, CA',
    text: '“I tried three other fresh dog foods before UnKibble, and my dogs would just sniff and walk away. Now they eat it all! The smell is appetizing, even for me! (Smells like human snacks.)”',
    imageSource: { uri: `${ASSETS_URL}/pictos/Avatars/unkibble-review-holly.png` },
  },
];

export const ReviewSection = () => {
  return (
    <Stack
      flexDirection={{ base: 'column', lg: 'row' }}
      justifyContent="center"
      w="100%"
      px="16px"
      pt={{ base: '30px', lg: '112px' }}
      pb="50px"
    >
      {reviews.map((review, i) => (
        <ReviewCard
          review={review}
          key={i}
          mr={i < reviews.length - 1 ? { base: 0, lg: '24px' } : 0}
          mb={i < reviews.length - 1 ? { base: '16px', lg: 0 } : 0}
        />
      ))}
    </Stack>
  );
};

const ReviewCard = ({
  review: { name, text, imageSource },
  ...props
}: { review: reviewContents } & IVStackProps) => (
  <VStack
    w={{ base: '100%', lg: '500px' }}
    py={{ base: '16px', lg: '30px' }}
    px={{ base: '20px', lg: '30px' }}
    space={{ base: '14px', lg: '20px' }}
    borderStyle="solid"
    borderColor="black"
    borderWidth={{ base: '2px', lg: '3px' }}
    {...props}
  >
    <HStack alignItems="center" justifyContent="space-between">
      <HStack space={{ base: '16px', lg: '24px' }} justifyContent="flex-start" alignItems="center">
        <Image
          source={imageSource}
          resizeMode="cover"
          size={{ base: '47px', lg: '65px' }}
          alt="reviewer"
        />
        <Text size="bodyMlToTitleSm" fontWeight="medium">
          {name}
        </Text>
      </HStack>
      <HStack>
        {[...Array(5)].map((_, i) => (
          <Star key={i} color="#FFC701" size={{ base: '20px', lg: '25px' }} />
        ))}
      </HStack>
    </HStack>
    <Text color="black" size="bodySmToMl" fontFamily="secondary">
      {text}
    </Text>
  </VStack>
);
